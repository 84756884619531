import React, { lazy, Suspense } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "./textEditor.css";
import { useState } from "react";
const Editor = lazy(() =>
  import("react-draft-wysiwyg").then((module) => ({ default: module.Editor }))
);
const { ContentState, convertToRaw, EditorState } = lazy(() =>
  import("draft-js")
);

const RichTextViewer = ({
  value,
  setFieldValue,
  readOnly = false,
  placeholder = "",
}) => {
  const prepareDraft = (value) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
  };

  return (
    <Suspense fallback={<div></div>}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="custom-wrapper"
        editorClassName="custom-viewer"
        toolbar={{
          options: [],
        }}
        placeholder={placeholder}
      ></Editor>
    </Suspense>
  );
};

export default RichTextViewer;
