import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { APP_NAME } from "constants/name";
import { colors } from "constants/themeConstants";
import LoadingError from "pages/Error/LoadingError";
import NewEvents from "pages/Events/New";
import Services from "pages/Services";
import ServiceDetail from "pages/Services/serviceDetail";
import ServiceRegister from "pages/Services/serviceRegistration";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
const Home = lazy(() => import("pages/home/Home"));
const Search = lazy(() => import("pages/home/Search"));
const Events = lazy(() => import("pages/Events"));
const LiveEvents = lazy(() => import("pages/Events/Live"));
const Mpesa = lazy(() => import("pages/Payment"));
const Help = lazy(() => import("pages/Help"));
const RemoveData = lazy(() => import("pages/RemoveData/index"));

const Logout = lazy(() => import("pages/home/Logout"));
const CompanyRegister = lazy(() => import("pages/companies/CompanyRegister"));
const VendorRegister = lazy(() => import("pages/vendors/VendorRegister"));
const EventDetail = lazy(() => import("pages/EventDetail"));
const PaymentRedirect = lazy(() => import("pages/PaymentRedirect"));

const OrganizersHome = lazy(() => import("pages/OrganizersHome"));
const HomePage = lazy(() => import("pages/Homepage"));
const OrgDetail = lazy(() => import("pages/OrgDetail/orgDetail"));
const VendorDetail = lazy(() => import("pages/vendors/vendorDetail"));
const Categories = lazy(() => import("pages/Categories"));
const CategoryEvents = lazy(() => import("pages/Homepage/CategoryEvents"));
const AboutUs = lazy(() => import("pages/AboutUs/subscription"));
const Locations = lazy(() => import("pages/home/Locations"));
const RSVP = lazy(() => import("pages/RSVP"));
const Subscription = lazy(() => import("pages/AboutUs/subscription"));
const FAQ = lazy(() => import("pages/FAQ"));
const OwlEventsMobile = lazy(() => import("pages/AboutUs/OwlEventsMobile"));
const Login = lazy(() => import("pages/auth/Login"));
const Register = lazy(() => import("pages/auth/Register"));
const CreateEvents = lazy(() => import("pages/CreateEvents"));
const CreateEventsPage7 = lazy(() =>
  import("pages/CreateEvents/CreateEventsPage7")
);
const CreateEventsPage5 = lazy(() =>
  import("pages/CreateEvents/CreateEventsPage5")
);
const RefundPolicy = lazy(() => import("pages/Help/RefundPolicy"));
const PrivacyPolicy = lazy(() => import("pages/Help/PrivacyPolicy"));
const CommunityGuidelines = lazy(() =>
  import("pages/Help/CommunityGuidelines")
);
const TermsAndConditions = lazy(() => import("pages/Help/TermsAndConditions"));
const TermsAndConditionsNew = lazy(() =>
  import("pages/HelpNewPage/TermsAndConditionsNew")
);
const RefundPolicyNew = lazy(() => import("pages/HelpNewPage/RefundPolicyNew"));
const Support = lazy(() => import("pages/HelpNewPage/Support"));

const PrivacyPolicyNew = lazy(() =>
  import("pages/HelpNewPage/PrivacyPolicyNew")
);
const CommunityGuidelinesNew = lazy(() =>
  import("pages/HelpNewPage/CommunityGuidelinesNew")
);
const PageNotFound = lazy(() => import("pages/Error/PageNotFound"));
const Tickets = lazy(() => import("pages/Tickets"));

const containerStyle = {
  position: "fixed",
  zIndex: 10000,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const LoadingPage = () => {
  return (
    <Box style={containerStyle}>
      <Box
        sx={{
          display: "flex",
          fontFamily: "Campton",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <CircularProgress />

        <Box sx={{ ml: 3 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              fontFamily: "Campton",
              letterSpacing: "1px",
              color: colors.background.tertiary,
            }}
          >
            {APP_NAME}
          </Typography>
          <Typography
            fontFamily="Campton"
            variant="p"
            sx={{ mt: 1, color: colors.background.tertiary }}
          >
            Hold on, We are preparing your content.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const routes = [
  {
    path: "/",
    exact: true,
    element: <Home />,
    errorElement: <LoadingError />,
  },
  {
    path: "/search",
    exact: true,
    element: <Search />,
  },
  {
    path: "/events",
    exact: true,
    element: <Events />,
  },
  {
    path: "/live",
    exact: true,
    element: <LiveEvents />,
  },
  {
    path: "/mpesa",
    exact: true,
    element: <Mpesa />,
  },
  {
    path: "/new",
    exact: true,
    element: <NewEvents />,
  },
  {
    path: "/categories",
    exact: true,
    element: <Categories />,
  },
  {
    path: "/rsvp",
    exact: true,
    element: <RSVP />,
  },
  {
    path: "/locations",
    exact: true,
    element: <Locations />,
  },
  {
    path: "/logout",
    exact: true,
    element: <Logout />,
  },
  {
    path: "/companies/register",
    exact: true,
    element: <CompanyRegister />,
  },
  {
    path: "/vendors/register",
    exact: true,
    element: <VendorRegister />,
  },
  {
    path: "/services/register",
    exact: true,
    element: <ServiceRegister />,
  },
  {
    path: "/events/detail",
    exact: true,
    element: <EventDetail />,
  },
  {
    path: "/remove-user-data",
    exact: true,
    element: <RemoveData />,
  },
  {
    path: "/payment-redirect",
    exact: true,
    element: <PaymentRedirect />,
  },
  {
    path: "/organizer",
    exact: true,
    element: <OrganizersHome />,
  },
  {
    path: "/Subscription Fee",
    exact: true,
    element: <Subscription />,
  },
  {
    path: "/FAQ",
    exact: true,
    element: <FAQ />,
  },
  {
    path: "/OwlEventsMobile",
    exact: true,
    element: <OwlEventsMobile />,
  },
  {
    path: "/help",
    exact: true,
    element: <Help />,
  },
  {
    path: "/createEvents",
    exact: true,
    element: <CreateEvents />,
  },
  {
    path: "/createEventsPage7",
    exact: true,
    element: <CreateEventsPage7 />,
  },
  {
    path: "/createEventsPage5",
    exact: true,
    element: <CreateEventsPage5 />,
  },
  {
    path: "/RefundPolicy",
    exact: true,
    elememt: <RefundPolicy />,
  },
  {
    path: "/PrivacyPolicy",
    exact: true,
    elememt: <PrivacyPolicy />,
  },
  {
    path: "/CommunityGuidelines",
    exact: true,
    elememt: <CommunityGuidelines />,
  },
  {
    path: "/TermsAndConditions",
    exact: true,
    elememt: <TermsAndConditions />,
  },
  {
    path: "/Support",
    exact: true,
    elememt: <Support />,
  },
  {
    path: "/Privacy Policy",
    exact: true,
    elememt: <PrivacyPolicyNew />,
  },
  {
    path: "/Refund Policy",
    exact: true,
    elememt: <RefundPolicyNew />,
  },
  {
    path: "/Community Guidelines",
    exact: true,
    elememt: <CommunityGuidelinesNew />,
  },
  {
    path: "/Terms And Conditions",
    exact: true,
    elememt: <TermsAndConditionsNew />,
  },
  {
    path: "/tickets",
    exact: true,
    element: <Tickets />,
  },
];

const ProjectRoutes = () => {
  return (
    <>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route
            exact
            path="/"
            element={<HomePage />}
            errorElement={<LoadingError />}
          />
          <Route exact path="/search" element={<Search />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/live" element={<LiveEvents />} />
          <Route exact path="/mpesa" element={<Mpesa />} />
          <Route exact path="/new" element={<NewEvents />} />

          <Route exact path="/categories" element={<Categories />} />
          <Route exact path="/remove-user-data" element={<RemoveData />} />

          <Route exact path="/rsvp" element={<RSVP />} />
          <Route exact path="/locations" element={<Locations />} />
          <Route
            exact
            path="/companies/register"
            element={<CompanyRegister />}
          />
          <Route exact path="/vendors/register" element={<VendorRegister />} />
          <Route
            exact
            path="/services/register"
            element={<ServiceRegister />}
          />

          <Route exact path="/organizers/detail" element={<OrgDetail />} />
          <Route exact path="/vendors/detail" element={<VendorDetail />} />

          <Route exact path="/payment-redirect" element={<PaymentRedirect />} />
          <Route exact path="/organizer" element={<OrganizersHome />} />
          <Route exact path="/Subscription Fee" element={<Subscription />} />
          <Route exact path="/FAQ" element={<FAQ />} />
          <Route exact path="/Ticketing App" element={<OwlEventsMobile />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/events/detail" element={<EventDetail />} />
          <Route exact path="/services/detail" element={<ServiceDetail />} />

          <Route exact path="/help" element={<Help />} />

          <Route exact path="/payment-redirect" element={<PaymentRedirect />} />
          <Route exact path="/organizer" element={<OrganizersHome />} />
          <Route exact path="/Subscription Fee" element={<Subscription />} />
          <Route exact path="/FAQ" element={<FAQ />} />
          <Route exact path="/Ticketing App" element={<OwlEventsMobile />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/RefundPolicy" element={<RefundPolicy />} />
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/Support" element={<Support />} />

          <Route
            exact
            path="/CommunityGuidelines"
            element={<CommunityGuidelines />}
          />
          <Route
            exact
            path="/TermsAndConditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/Refund Policy" element={<RefundPolicyNew />} />
          <Route exact path="/Privacy Policy" element={<PrivacyPolicyNew />} />
          <Route
            exact
            path="/Terms And Conditions"
            element={<TermsAndConditionsNew />}
          />
          <Route
            exact
            path="/Community Guidelines"
            element={<CommunityGuidelinesNew />}
          />
          <Route exact path="/createEvents" element={<CreateEvents />} />
          <Route
            exact
            path="/createEventsPage7"
            element={<CreateEventsPage7 />}
          />
          <Route
            exact
            path="/createEventsPage5"
            element={<CreateEventsPage5 />}
          />
          <Route exact path="/tickets" element={<Tickets />} />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};
export default ProjectRoutes;
