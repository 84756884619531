//import { ErrorOutline } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { colors } from "constants/themeConstants";
//import { FallbackProps } from "react-error-boundary";
import sorryOwl1 from "../../assets/images/sorryOwl1.jpg";
import sorryOwl2 from "../../assets/images/sorryOwl2.jpg";
import { useEffect, /*useState*/ } from "react";
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
export default function ErrorPage(props) {
  // const [html, setHTML] = useState({ __html: "" });

  const { error, resetErrorBoundary } = props;
  const errCode = error?.originalStatus ? error.originalStatus : 203;
  var errorMessage =
  "Something went wrong. Please try again. ";
  if ((errCode >= 400) & (errCode < 500)) {
    errorMessage =
    "Sorry, OwlEvents isn't working right now";
  } else if (errCode >= 500) {
    errorMessage = "Sorry, Internal Server Error";  }

  useEffect(() => {
    // if (error?.data) {
    //   setHTML({ __html: error?.data });
    // }
  }, []);

  // const ErrorMap = {
  //   401: "Unauthorized access. Please login to access this resource.",
  //   403: "Access forbidden. You don't have permission to access this resource.",
  //   404: "Page not found. The requested page could not be found.",
  //   501: "Whoops! Something went wrong on our end. Our team of highly trained hamsters are investigating. Please try again later",
  //   502: "Bad gateway. The server received an invalid response from an upstream server.",
  //   503: "Service unavailable. The server is currently unable to handle the request.",
  // };
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='text-center'>
        {/* <img
          alt="img"
          className='mx-[auto] md:mt-5 md:mb-5'
          // onClick={() => navigate("/")}
          width={"120rem"}
          src={errCode > 500 ? sorryOwl2 : sorryOwl1}
          // src={img}
          // style={{ marginLeft: "40px", marginBottom: 0 }}
          sx={{ display: { xs: "none", md: "flex" } }}
        /> */}
          <SubtitlesOffIcon sx={{  width:"100px", color:"#DEA449",height:"100px"}}/>
        {/* {!html ? ( */}
        <Typography
          mx={"25rem"}
          my={"1rem"}
          fontFamily={"Campton"}
          fontSize={"2rem"}
          fontWeight={900}
          color="#DEA449"
          
        >
          oops!
        </Typography>
          <Typography
            mx={"25rem"}
            my={"1rem"}
            fontFamily={"Campton"}
            fontSize={"1.5rem"}
            fontWeight={400}
            color={colors.background.tertiary}
          >
            {errorMessage}
          </Typography>
        {/* // ) : (
        //   <div
        //     style={{
        //       fontFamily: "Comfortaa",
        //       fontSize: "1.5rem",
        //       fontWeight: 400,
        //       color: colors.background.tertiary,
        //       marginTop: "1rem",
        //       marginBottom: "1rem",
        //       marginLeft: "25rem",
        //       marginRight: "25rem",
        //     }}
        //     dangerouslySetInnerHTML={html}
        //   />
        // )} */}

        <Button
          variant='text'
          style={{
            color: colors.background.secondary,
            padding: 10,
            margin: "0.5rem",
            fontSize: "1.2rem",
            fontFamily: "Campton",
            backgroundColor: colors.background.primary,
            textTransform: "none",
          }}
          onClick={resetErrorBoundary}
        >
          Refresh
        </Button>
      </div>
    </div>
  );
}
