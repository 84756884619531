import { Box, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
//import { Swiper, SwiperSlide } from "swiper/react";
//import { Navigation, Pagination } from "swiper";
import { useGetCategoriesQuery } from "features/category/categorySlice";
import { colors } from "constants/themeConstants";
import { LanguageContext } from "hooks/useLanguage";
import { localization } from "constants/localalization";
import ReactGA from "react-ga4";
// const categories = ["All","Entertainment", "Music", "Hiking", "Night Life", "Foods"];
const LoadingCard = () => {
  return <Skeleton variant="rounded" width="60px" height={"30px"} />;
};
const EventsCategoriesHeader = ({
  setCurrentCat,
  setCatChange,
  category,
  catChange,
  currentCat,
  setfilterValues,
  isEventsList,
}) => {
  const { language } = useContext(LanguageContext);

  const { data, isLoading } = useGetCategoriesQuery();
  const categories = data?.data;
  const loadingCards = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  function checkCurrentCategory(id) {
    if (currentCat?.some((e) => e.id === id)) {
      return true;
    } else return false;
  }
  const [catIsSet, setCatisSet] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (
      (currentCat?.length === 0 ||
        (category && category[0] === "none") ||
        (category && category[0] === null)) &&
      !catIsSet
    ) {
      // console.log('right')

      setCurrentCat([{ name: "All", id: 0 }]);
    } else if (category && category[0] !== "none" && !catIsSet) {
      // console.log('wrong')

      setCurrentCat(category);
    }
  }, [catChange, category]);
  const EventCategoryClickForGA = (item) => {
    const clickedCategory = item?.name[language];
    ReactGA.event({
      category: "Events by category",
      action: `Accessed Category`,
      label: `Category Name: ${clickedCategory}`,
    });
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "end",
        justifyItems: "end",
      }}
    >
      <Grid justifyContent="space-between" justifyItems="end" container>
        {isLoading ? (
          loadingCards?.map((item) => (
            <Grid mt={1.5} item xs={"auto"} key={item}>
              <LoadingCard />
            </Grid>
          ))
        ) : (
          <>
            <Grid mt={1.5} item xs={"auto"}>
              {" "}
              <Typography
                variant="p"
                fontSize={"12px"}
                fontWeight={400}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.background.secondary,
                  },
                  px: 2,
                  py: 1,
                  // mx: 2,
                  mb: 5,
                  mt: 2,
                  border: checkCurrentCategory(0)
                    ? `1px solid ${colors.background.outline}`
                    : `0.5px solid ${colors.background.outline}`,
                  color: checkCurrentCategory(0)
                    ? colors.background.secondary
                    : colors.background.tertiary,
                  borderRadius: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentCat([{ name: "All", id: 0 }]);
                  setCatisSet(true);
                }}
              >
                {localization.Labels.all[language]}
              </Typography>
            </Grid>
            {categories?.map((item) => (
              <Grid
                mt={1.5}
                item
                xs={"auto"}
                key={item.id}
                onClick={() => EventCategoryClickForGA(item)}
              >
                <Typography
                  variant="p"
                  fontSize={"12px"}
                  fontWeight={400}
                  sx={{
                    "&:hover": {
                      backgroundColor: colors.background.secondary,
                    },
                    px: 2,
                    py: 1,
                    mb: 5,

                    mt: 2,
                    border: checkCurrentCategory(item.id)
                      ? `1px solid ${colors.background.outline}`
                      : `0.5px solid ${colors.background.outline}`,
                    color: checkCurrentCategory(item.id)
                      ? colors.background.secondary
                      : colors.background.tertiary,
                    //  background: currentCat.id===item.id?theme.palette.background.dark : theme.palette.background.dark,
                    borderRadius: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    isEventsList && setfilterValues("");
                    setCatisSet(true);
                    setCatChange(item.id + (Math.random() * 10).toString());
                    var cur = [];

                    if (currentCat?.length > 0 && currentCat[0].id != 0) {
                      cur = currentCat;
                    }

                    const objWithIdIndex = cur.findIndex(
                      (obj) => item?.id === obj?.id
                    );
                    if (objWithIdIndex > -1) {
                      console.log("found", objWithIdIndex);
                      cur.splice(objWithIdIndex, 1);
                      if (cur?.length == 0) {
                        console.log("found2", objWithIdIndex);
                        setCurrentCat([{ name: "All", id: 0 }]);
                        console.log("found3", objWithIdIndex);
                      } else {
                        setCurrentCat(cur);
                      }
                    } else {
                      console.log("not");
                      cur.push({ name: item?.name[language], id: item?.id });
                      setCurrentCat(cur);
                    }
                  }}
                >
                  {item.name[language]}
                </Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default EventsCategoriesHeader;
