export const parseFilterObj = (filters) => {

  let filtered = "";
  if (filters) {
    filters?.forEach((key,index) => {
      const keys = Object.keys(filters[index]);
      keys.forEach((key) => {
        if (filters[index][key] !== "") filtered += `&${key}=${filters[index][key]}`;
      });
        });
   
  }

  return filtered;
};
