import { usePagination } from "./usePagination";
import { /*useEffect, useMemo,*/ useState } from "react";
//import useLocalStorage from "./useLocalStorage";
//import useDebounce from "./useDebounce";
//import { parseFilterObj } from "helpers/filtering";

const useGetDataRtk = (useQuery, pagination = null, id) => {
  // pagination
  const [queryPagination, setQueryPagination] = usePagination({
    pagination: pagination || { pageIndex: 0, pageSize: 12 },
  });
  const [eventId, setEventId] = useState(null);
  // fetch data
  const {
    data: rawData,
    isLoading,
    refetch,
  } = useQuery({
    pagination: queryPagination,
    id: eventId==null?'':eventId
  
  });
  const data = rawData ? rawData?.data : [];
  const rowCount = rawData ? rawData.page_metadata.length : null;
  return {
    isLoading,
    pagination: queryPagination,
    setEventId,
    setPagination: setQueryPagination,
    rowCount,
    data,

    refetch,
  };
};

const useGetDataPagination = (id = null) => {
  //   if (isRtk) {
  return useGetDataRtk;
  //   }
};

export default useGetDataPagination;
