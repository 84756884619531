import { /*useTheme,*/ Box, Skeleton, Grid } from "@mui/material";
import React from "react";
import { StyledEventSquareCardWrapper } from "./Styles";

const SquareLoadingEventCard = ({ event }) => {
  return (
    <StyledEventSquareCardWrapper>
      <Grid width={'100%'} container>
        <Grid  item xs={12}>
          <Skeleton variant="rounded" width="100%" height={"170px"} />
          
        </Grid>
       
       
              <Grid  xs={4} item>
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="90%" />
              </Grid>
              <Grid  xs={4} item>
                <Skeleton variant="text" width="90%" height={"50px"} />
              </Grid>
              <Grid xs={4} item>
                <Skeleton variant="text" width="90%" height={"50px"} />
              </Grid>
      
   
          <Box sx={{ mt: 0.5, px: "5px" }}>
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" height={"140px"} width="90%" />
          </Box>
        
      </Grid>
    </StyledEventSquareCardWrapper>
  );
};

export default SquareLoadingEventCard;
