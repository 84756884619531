import { Modal, Paper } from "@mui/material";
import { styled } from "@mui/system";
//import ModalWrapper from "components/ModalWrapper";
import React from "react";

import { colors } from "constants/themeConstants";
//import Checkout from "components/Checkout";
import CheckoutService from ".";

const StyledModalItemsContainer = styled(Paper)(({ theme }) => ({
  position: "absolute",
  background: colors.background.primary,
  // width: "50%",
  height:'80%',
  // padding: "3rem",
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  borderRadius:'30px',
  maxHeight: "90vh",
  // maxWidth: "60vw",
}));

const CheckoutModal = ({ modalData, setModalData }) => {
  const { open, id, price, name, eventId, tickets,data } = modalData;
  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={() => setModalData({ open: false, id, price, name })}
    >
      <StyledModalItemsContainer className="w-[80%] md:w-[50%]">
        <CheckoutService
        eventId={eventId}
          id={id}
          data={data}
          tickets={tickets}
          price={price}
          name={name}
          setModalData={setModalData}
        />
      </StyledModalItemsContainer>
    </Modal>
  );
};

export default CheckoutModal;
