export const localization = {
  OwlEventsMobile: {
    Findlocal: {
      text_en: "Find Local Events and things to do near you",
      text_ar:
        "ابحث عن الأحداث المحلية والأشياء التي يمكنك القيام بها بالقرب منك",
      text_am: "በአጠገብዎ የሚደረጉ የአካባቢ ክስተቶችን እና ነገሮችን ያግኙ",
      text_fr:
        "Trouvez des événements locaux et des choses à faire près de chez vous",
      text_sw: "Pata Matukio ya Ndani na mambo ya kufanya karibu na wewe",
    },
    findlocalsub: {
      text_en:
        "Also access tickets on your phone. Download free today on iOS and Android.",
      text_ar:
        "ايضًا قم بالوصول إلى التذاكر على هاتفك. قم بالتحميل مجانًا اليوم على نظامي التشغيل iOS و Android.",
      text_am:
        "ስለመተግበሪያ የቲኬቶችን ያለእንዲሁም የሞባይል የሆኑትን በኦዲስ እና አንዲራይድ በነጻ ያለውን መስመር በነፃ ይደርስ",
      text_fr:
        "Accédez également aux billets sur votre téléphone. Téléchargez gratuitement aujourd'hui sur iOS et Android.",
      text_sw:
        "Pia pata tiketi kwenye simu yako. Pakua bure leo kwenye iOS na Android.",
    },
    Howtogetstarted: {
      text_en: "How to get started on OwlEvents.........",
      text_ar: "كيف تبدأ في استخدام OwlEvents",
      text_am: "እርስዎን ለ OwlEvents ለመጀመሪያ ማሰብ እንዴት እንደሚለው ምንድን ነው",
      text_fr: "Comment commencer sur OwlEvents",
      text_sw: "Jinsi ya kuanza kwenye OwlEvents",
    },
    ifyoureavendor: {
      text_en:
        "If you are a vendor looking to participte or planning to start your first event/ event business.",
      text_ar:
        "إذا كنت بائعًا تبحث عن المشاركة أو تخطط لبدء أول حدث / عمل حدث لك.",
      text_am:
        "እዚህ የንግድ ተግባራት የሆኑት ማንኛውም አገልግሎትን ይሰርቀዋል ወይም መጀመሪያ ክስተት/ክስተት ንግድን ለመጀመሪያ ድረስ እንዴት እንደሚለው ምንድን ነው",
      text_fr:
        "Si vous êtes un vendeur cherchant à participer ou prévoyant de démarrer votre premier événement / entreprise événementielle.",
      text_sw:
        "Ikiwa wewe ni muuzaji anayetaka kushiriki au anayepanga kuanza tukio la kwanza / biashara ya tukio.",
    },
    thefollowing: {
      text_en:
        "The following videos will guide you on how to use Owlevent’s Platform to the fullest.",
      text_ar:
        "سوف تقوم الفيديوهات التالية بإرشادك حول كيفية استخدام منصة Owlevent بأكملها.",
      text_am:
        "የቀጣይ የሆነው ቪዲዎች ለመመልከት ይመልከቱ የሚለውን ስፕራት፣ Owlevent’s Platform እንዴት እንደሚገኝ ምንድን ነው",
      text_fr:
        "Les vidéos suivantes vous guideront sur la meilleure utilisation de la plateforme Owlevent.",
      text_sw:
        "Video zifuatazo zitakuelekeza jinsi ya kutumia Jukwaa la Owlevent kikamilifu.",
    },
    PlayStore: {
      text_en: "Available on Google Play.",
      text_ar: "متاح على جوجل بلاي.",
      text_am: "ከጎግል ፕሌይ ያግኙት።",
      text_fr: "Disponible sur Google Play.",
      text_sw: "Inapatikana kwenye Duka la Google Play.",
    },
    AppStore: {
      text_en: "Available on the App Store.",
      text_ar: "متاح على متجر التطبيقات.",
      text_am: "ከApp Store ያግኙት።",
      text_fr: "Disponible sur l'App Store.",
      text_sw: "Inapatikana kwenye Duka la App.",
    },
    Duration: {
      text_en: "Seconds",
      text_ar: "ثواني",
      text_am: "ሰከንዶች",
      text_fr: "Seconds",
      text_sw: "Sekunde",
    },
  },

  Menu: {
    live: {
      text_en: "Live",
      text_ar: "مباشر",
      text_am: "ቀጥታ",
      text_fr: "En direct",
      text_sw: "Ishi",
    },
    services: {
      text_en: "Services",
      text_ar: "خدمات",
      text_am: "አገልግሎቶች",
      text_fr: "prestations de service",
      text_sw: "huduma",
    },
    subFee: {
      text_en: "Pricing",
      text_ar: "تسعير",
      text_am: "የዋጋ አሰጣጥ",
      text_fr: "Tarification",
      text_sw: "Bei",
  },
    ticketing: {
      text_en: "Ticketing App",
      text_ar: "تطبيق الحجز",
      text_am: "የቲኬት መቁረጫ መተግበሪያ",
      text_fr: "Application de billetterie",
      text_sw: "Programu ya Tiket",
    },
    help: {
      text_en: "Help",
      text_ar: "مساعدة",
      text_am: "መርዳት",
      text_fr: "Aider",
      text_sw: "Msaada",
    },
    gtp: {
      text_en: "Go to Portal",
      text_ar: "انتقل إلى البوابة",
      text_am: "ወደ ፖርታል ይሂዱ",
      text_fr: "Aller au portail",
      text_sw: "Nenda kwa Portal",
    },
    locationEvent: {
      text_en: "Events Based on Location",
      text_ar: "فعاليات تعتمد على الموقع",
      text_am: "በቦታው ላይ የተመሰረቱ ክስተቶች",
      text_fr: "Événements basés sur l'emplacement",
      text_sw: "Matukio Kulingana na Mahali",
    },
    lang: {
      text_en: "Language",
      text_ar: "اللغة",
      text_am: "ቋንቋ",
      text_fr: "Langue",
      text_sw: "Lugha",
    },
    new: {
      text_en: "New",
      text_ar: "جديد",
      text_am: "አዲስ",
      text_fr: "Nouvelle",
      text_sw: "Mpya",
    },
    currency: {
      text_en: "Currency",
      text_ar: "عملة",
      text_am: "ምንዛሬ",
      text_fr: "devise",
      text_sw: "sarafu",
    },
  },
  FooterTitles: {
    utilize: {
      text_en: "Utilize Owl",
      text_ar: "استخدم البومة",
      text_am: "ኦውልን ተጠቀሙ",
      text_fr: "Utiliser le hibou",
      text_sw: "Tumia Bundi",
    },
    discoverOccasions: {
      text_en: "Discover Occasions",
      text_ar: "اكتشف المناسبات",
      text_am: "አጋጣሚዎችን ያግኙ",
      text_fr: "Découvrez les événements",
      text_sw: "Gundua Matukio",
    },
    Connect: {
      text_en: "Connect with us",
      text_ar: "اتصل بنا",
      text_am: "ከእኛ ጋር ይገናኙ",
      text_fr: "Connecte-toi avec nous",
      text_sw: "Ungana nasi",
    },
    mobileTicketApp: {
      text_en: "Eventowl Mobile Ticket App",
      text_ar: "تطبيق تذكرة الحدث المتنقلة",
      text_am: "ኢቬንትኦውል የሞባይል ትኬት መተግበሪያ",
      text_fr: "Application de billetterie mobile Eventowl",
      text_sw: "Programu ya Tikiti ya Simu ya Tukio",
    },
    subPlans: {
      text_en: "Subscription Plans",
      text_ar: "خطط الاشتراك",
      text_am: "የደንበኝነት ምዝገባ ዕቅዶች",
      text_fr: "Formules d'abonnement",
      text_sw: "Mipango ya Usajili",
    },
    FAQ: {
      text_en: "FAQs",
      text_ar: "الأسئلة الشائعة",
      text_am: "የሚጠየቁ ጥያቄዎች",
      text_fr: "FAQ",
      text_sw: "Maswali Yanayoulizwa Mara kwa Mara",
    },
    FMUSIC: {
      text_en: "Music",
      text_ar: "موسيقى",
      text_am: "ሙዚቃ",
      text_fr: "Musique",
      text_sw: "Muziki",
    },
    FFOOD: {
      text_en: "Food",
      text_ar: "طعام",
      text_am: "ምግብ",
      text_fr: "Gastronomie",
      text_sw: "Chakula",
    },
    F21EVENT: {
      text_en: "21+ events",
      text_ar: "21+ الأحداث",
      text_am: "21+ ዝግጅቶች",
      text_fr: "21+ événements",
      text_sw: "matukio 21+",
    },
    FCONCERT: {
      text_en: "Concerts",
      text_ar: "حفلات",
      text_am: "ኮንሰርቶች",
      text_fr: "Concerts",
      text_sw: "Tamasha",
    },
    contact: {
      text_en: "Contact Support",
      text_ar: "الاتصال بالدعم",
      text_am: "ድጋፍን ያነጋግሩ",
      text_fr: "Contactez le support",
      text_sw: "Wasiliana na Usaidizi",
    },
    twitter: {
      text_en: "Twitter",
      text_ar: "تويتر",
      text_am: "ትዊተር",
      text_fr: "Twitter",
      text_sw: "Twitter",
    },
    linkedn: {
      text_en: "Linkedin",
      text_ar: "لينكد إن",
      text_am: "ሊንክዲን",
      text_fr: "Linkedin",
      text_sw: "Linkedin",
    },
    instagram: {
      text_en: "Instagram",
      text_ar: "إنستجرام",
      text_am: "ኢንስታግራም",
      text_fr: "Instagram",
      text_sw: "Instagram",
    },
    facebook: {
      text_en: "Facebook",
      text_ar: "فيسبوك",
      text_am: "ፌስቡክ",
      text_fr: "Facebook",
      text_sw: "Facebook",
    },
    refundpolicy: {
      text_en: "Refund Policy",
      text_ar: "سياسة الاسترداد",
      text_am: "የተመላሽ ገንዘብ ፖሊሲ",
      text_fr: "Politique de remboursement",
      text_sw: "Sera ya Kurejesha Pesa",
    },
    communityguidelines: {
      text_en: "Community Guidelines",
      text_ar: "إرشادات المجتمع",
      text_am: "የማህበረሰብ መመሪያዎች",
      text_fr: "Règles de la communauté",
      text_sw: "Miongozo ya Jumuiya",
    },
    terms: {
      text_en: "Terms & Conditions",
      text_ar: "البنود و الظروف",
      text_am: "ውሎች እና ሁኔታዎች",
      text_fr: "termes et conditions",
      text_sw: "Sheria na Masharti",
    },
    privacy: {
      text_en: "Privacy Policy",
      text_ar: "سياسة الخصوصية",
      text_am: "ግላዊነት",
      text_fr: "politique de confidentialité",
      text_sw: "Sera ya Faragha",
    },
  },
  Labels: {
    findEvent: {
      text_en: "Find Event",
      text_am: "ክስተት አግኙ",
      text_fr: "Rechercher un événement",
      text_sw: "Tafuta Tukio",
      text_ar: "البحث عن الحدث",
    },
    search: {
      text_en: "Search for Events",
      text_am: "ዝግጅቶችን  ይፈልጉ",
      text_fr: "Rechercher des événements",
      text_sw: "Tafuta Matukio",
      text_ar: "البحث عن الأحداث",
    },
    date: {
      text_en: "Date",
      text_am: "ቀን",
      text_fr: "Date",
      text_sw: "Tarehe",
      text_ar: "التاريخ",
    },
    all: {
      text_en: "All",
      text_am: "ሁሉም",
      text_fr: "Toute",
      text_sw: "Wote",
      text_ar: "الكل",
    },
    place: {
      text_en: "Place",
      text_am: "ቦታ",
      text_fr: "Lieu",
      text_sw: "Mahali",
      text_ar: "المكان",
    },
    get: {
      text_en: "Get",
      text_am: "ማሳወቂያ",
      text_fr: "Recevoir",
      text_sw: "Pata",
      text_ar: "احصل",
    },
    notification: {
      text_en: "Notification",
      text_am: "ያግኙ",
      text_fr: "une notification",
      text_sw: "Arifa",
      text_ar: "الإشعار",
    },
    buy: {
      text_en: "Buy",
      text_am: "ትኬት",
      text_fr: "Acheter",
      text_sw: "Nunua",
      text_ar: "شراء",
    },
    getticket: {
      text_en: "Get",
      text_am: "ትኬት",
      text_fr: "Acheter",
      text_sw: "Nunua",
      text_ar: "شراء",
    },
    ticket: {
      text_en: "Ticket",
      text_am: "ይግዙ",
      text_fr: "un billet",
      text_sw: "Tikiti",
      text_ar: "تذكرة",
    },
    free: {
      text_en: "Free",
      text_am: "ነፃ",
      text_fr: "Gratuit",
      text_sw: "Bure",
      text_ar: "حر",
    },
    entrance: {
      text_en: "Entrance",
      text_am: "መግቢያ",
      text_fr: "Entrée",
      text_sw: "Ingång",
      text_ar: "مدخل",
    },
    open: {
      text_en: "Open",
      text_am: "ነፃ",
      text_fr: "Porte",
      text_sw: "Fungua",
      text_ar: "باب",
    },
    door: {
      text_en: "Door",
      text_am: "በር",
      text_fr: "ouverte",
      text_sw: "mlango",
      text_ar: " مفتوح",
    },
    doorticket: {
      text_en: "Door",
      text_am: "በር ላይ",
      text_fr: "ouverte",
      text_sw: "mlango",
      text_ar: " مفتوح",
    },
    createevent: {
      text_en: "Create Event",
      text_am: "ክስተት ፍጠሩ",
      text_fr: "Créer un événement",
      text_sw: "Unda Tukio",
      text_ar: "إنشاء حدث",
    },
    signup: {
      text_en: "Sign Up",
      text_am: "ይመዝገቡ",
      text_fr: "S'enregistrer",
      text_sw: "Jisajili",
      text_ar: "التسجيل",
    },
    getSupport: {
      text_en: "Get Support",
      text_am: "ድጋፍ ያግኙ",
      text_fr: "Obtenir de l'aide",
      text_sw: "Pata Usaidizi",
      text_ar: "احصل على الدعم",
    },
    VendorEnabled: {
      text_en: "Vendor Enabled",
      text_am: "ሻጭ ነቅቷል።",
      text_fr: "Fournisseur activé",
      text_ar: "تمكين البائع",
      text_sw: "Muuzaji Amewezeshwa",
    },
    PresentsYou: {
      text_en: "Presents You",
      text_am: "ያቀርብልሃል",
      text_fr: "vous présente",
      text_ar: "يقدم لك",
      text_sw: "Inakuletea",
    },
    TicketAvailability: {
      text_en: "Ticket Availability",
      text_am: "የቲኬት መገኘት",
      text_fr: "Disponibilité des billets",
      text_sw: "Upatikanaji wa Tiketi",
      text_ar: "توافر التذاكر",
    },
    EventsLikethis: {
      text_en: "Events Like this",
      text_am: "እንደዚህ ያሉ ክስተቶች",
      text_fr: "Des événements comme celui-ci",
      text_sw: "Matukio Kama haya",
      text_ar: "أحداث مثل هذا",
    },
    More: {
      text_en: "More",
      text_am: "ተጨማሪ",
      text_fr: "Plus",
      text_sw: "Zaidi",
      text_ar: "أكثر",
    },
  },
  Titles: {
    settledin: {
      text_en: "Let's get you settled in",
      text_am: "በሚመጡት ክስተቶች",
      text_fr: "Laissez-vous installer",
      text_sw: "Hebu tukufanye utulie",
      text_ar: "دعونا نستقرك هنا",
    },
    upcoming: {
      text_en: "Upcoming Events",
      text_am: "ላይ እናስታውሳችሁ",
      text_fr: "dans les événements à venir",
      text_sw: "katika Matukio Yajayo",
      text_ar: "الأحداث القادمة",
    },
    ongoing: {
      text_en: "Ongoing Events",
      text_am: "በመካሄድ ላይ ያሉ ክስተቶች",
      text_fr: "Événements en cours",
      text_sw: "Matukio Yanayoendelea",
      text_ar: "الأحداث الجارية",
    },
    bundle: {
      text_en: "Bundle Tickets",
      text_am: "የጥቅል ቲኬቶች",
      text_fr: "Billets groupés",
      text_sw: "Tiketi za Bundle",
      text_ar: "تذاكر الحزمة",
    },

    flyThrough: {
      text_en: "Fly Through",
      text_am: "በምድቦች",
      text_fr: "Survoler les",
      text_sw: "Kuruka Kupitia",
      text_ar: "الطيران من خلال",
    },
    category: {
      text_en: "Category",
      text_am: "በኩል ብረሩ",
      text_fr: "catégories",
      text_sw: "Kategoria",
      text_ar: "الفئة",
    },
    checkMore: {
      text_en: "Check More",
      text_am: "በእርስዎ ከተማ ውስጥ",
      text_fr: "Vérifiez plus",
      text_sw: "Angalia Zaidi",
      text_ar: "تحقق من المزيد",
    },
    inYourTown: {
      text_en: "In your Town",
      text_am: "ተጨማሪ ይመልከቱ",
      text_fr: "dans votre ville",
      text_sw: "Katika Mji wako",
      text_ar: "في مدينتك",
    },
    checkMore2: {
      text_en: "Check More",
      text_am: "እንደዚህ ያሉ ተጨማሪ",
      text_fr: "Vérifiez plus",
      text_sw: "Angalia Matukio",
      text_ar: " تحقق من المزيد من",
    },
    eventslikethis: {
      text_en: "Events like this",
      text_am: "ክስተቶችን ይመልከቱ",
      text_fr: "d'événements comme celui-ci",
      text_sw: "Zaidi kama haya",
      text_ar: "الأحداث مثل هذا",
    },
    owlOrganizers: {
      text_en: "Owl Organizers",
      text_am: "የኦውል አደራጆች",
      text_fr: "Organisateurs de Owl",
      text_sw: "Waandaaji wa Owl",
      text_ar: "منظمي البوم",
    },
    owlVendors: {
      text_en: "Owl Vendors",
      text_am: "የኦውል ሻጮች",
      text_fr: "Tous les fournisseurs",
      text_sw: "Wachuuzi wote",
      text_ar: "جميع البائعين",
    },
    list: {
      text_en: "List",
      text_am: "ዝርዝር",
      text_fr: "Liste",
      text_sw: "Orodha",
      text_ar: "قائمة",
    },
    premium: {
      text_en: "Premium",
      text_am: "ፕሪሚየም",
      text_fr: "Prime",
      text_sw: "Premium",
      text_ar: "المتميزة",
    },
    events: {
      text_en: "Events",
      text_am: "ክስተቶች",
      text_fr: "Événements",
      text_sw: "Matukio",
      text_ar: "الأحداث",
    },
    showmore: {
      text_en: "Show More",
      text_am: "ተጨማሪ አሳይ",
      text_fr: "Montre plus",
      text_sw: "Onyesha zaidi",
      text_ar: "عرض المزيد",
    },
    showless: {
      text_en: "Show Less",
      text_am: "ያነሰ አሳይ",
      text_fr: "Montrer moins",
      text_sw: "Onyesha Chini",
      text_ar: "عرض أقل",
    },
    bookdemo: {
      text_en: "Need an Organizer?",
      text_am: "ማሳያ ያስፈልጋል?",
      text_fr: "Besoin d'un organisateur?",
      text_sw: "Unahitaji Mratibu?",
      text_ar: "هل تحتاج إلى منظم؟",
    },
    pastEvents: {
      text_en: "Past Events",
      text_am: "ያለፉ ክስተቶች",
      text_fr: "Événements passés",
      text_sw: "Matukio ya Zamani",
      text_ar: "الأحداث السابقة",
    },
    eventsAttending: {
      text_en: "Events Attending",
      text_am: "የሚሳተፉባቸው ክስተቶች",
      text_fr: "participation à des événements",
      text_sw: "matukio kuhudhuria",
      text_ar: "الأحداث التي تحضر",
    },
  },
  EventsListNew: {
    "Zoom Through": {
      text_en: "Zoom Through",
      text_am: "መመልከትዎን ይቀጥሉ",
      text_fr: "Zoom avant",
      text_sw: "Kuza Kupitia",
      text_ar: "التكبير من خلال",
    },
    Categories: {
      text_en: "Categories",
      text_am: "ምድቦች",
      text_fr: "Catégories",
      text_sw: "Kategoria",
      text_ar: "الفئات",
    },
    "Owl the Events": {
      text_en: "Owl the Events",
      text_am: "ሁሉም ክስተቶች",
      text_fr: "hibou les événements",
      text_sw: "Owl Matukio",
      text_ar: "احداث البوم",
    },
    "Owl the Live Events": {
      text_en: "Owl the Live Events",
      text_am: "ሁሉም የቀጥታ ስርጭት ክስተቶች",
      text_fr: "Tous les événements en direct",
      text_sw: "Matukio Yote ya Moja kwa Moja",
      text_ar: "جميع الأحداث الحية",
    },
    "Owl the New Events": {
      text_en: "Owl the New Events",
      text_am: "ሁሉም አዲስ ክስተቶች",
      text_fr: "Tous les nouveaux événements",
      text_sw: "Matukio Yote Mpya",
      text_ar: "كل الأحداث الجديدة",
    },
    "Here are": {
      text_en: "Here are",
      text_am: "እነዚህ",
      text_fr: "Voici",
      text_sw: "Hizi hapa",
      text_ar: "هنا هي",
    },
  },
  SubscriptionFee: {
    fairprice: {
      text_en: "Fair Price with Distinct Value",
      text_am: "ትክክለኛ ዋጋ ከተለየ ዋጋ ጋር",
      text_fr: "Prix équitable avec une valeur distincte",
      text_sw: "Bei Nafuu na Thamani Iliyojulikana",
      text_ar: "سعر عادل بقيمة مميزة",
    },
    fairpricessub: {
      text_en:
        "We provide all the tools you require to run events, sell tickets, and expand your business's reach.",
      text_am:
        "ዝግጅቶችን ለማካሄድ፣ ትኬቶችን ለመሸጥ እና የንግድዎን ተደራሽነት ለማስፋት የሚያስፈልጉዎትን ሁሉንም መሳሪያዎች እናቀርባለን።",
      text_fr:
        "Nous mettons à votre disposition tous les outils nécessaires pour organiser des événements, vendre des billets et étendre la portée de votre entreprise.",
      text_sw:
        "Tunatoa zana zote unazohitaji kuendesha matukio, kuuza tiketi, na kuongeza wigo wa biashara yako.",
      text_ar:
        "نحن نوفر لك جميع الأدوات التي تحتاجها لتشغيل الأحداث وبيع التذاكر وتوسيع نطاق عملك.",
    },
    Host: {
      text_en: "Host and Monitor Parallel Events",
      text_am: "ትይዩ ክስተቶችን ያስተናግዱ እና ይቆጣጠሩ",
      text_fr: "Héberger et surveiller des événements parallèles",
      text_sw: "Mwenyeji na Kufuatilia Matukio Yanayolingana",
      text_ar: "استضافة ومراقبة الأحداث المتوازية",
    },
    Flexible: {
      text_en: "Flexible Ticket Options For Live Or Recorded Events",
      text_am: "ለቀጥታ ወይም ለተመዘገቡ ክስተቶች ተለዋዋጭ የቲኬት አማራጮች",
      text_fr:
        "Options de billets flexibles pour les événements en direct ou enregistrés",
      text_sw:
        "Chaguzi Mbadala za Tiketi kwa Matukio ya Moja kwa Moja au Yaliyorekodiwa",
      text_ar: "خيارات تذاكر مرنة للأحداث المباشرة أو المسجلة",
    },
    Tools: {
      text_en:
        "Tools For Email Marketing and Post Event Surveys That Are Combined",
      text_am: "ለኢሜል ግብይት እና ለፖስት የክስተት ዳሰሳዎች የተጣመሩ መሳሪያዎች",
      text_fr:
        "Outils de marketing par e-mail et enquêtes après l'événement combinés",
      text_sw:
        "Zana za Masoko ya Barua pepe na Uchunguzi Baada ya Matukio Ambayo Yameunganishwa",
      text_ar:
        "أدوات التسويق عبر البريد الإلكتروني واستطلاعات ما بعد الحدث المدمجة",
    },
    Multi: {
      text_en: "Multi Currency Online Ticket Sales",
      text_am: "ባለብዙ ምንዛሪ የመስመር ላይ ቲኬት ሽያጭ",
      text_fr: "Ventes de billets en ligne en plusieurs devises",
      text_sw: "Uuzaji wa Tiketi Mtandaoni kwa Sarafu Mbalimbali",
      text_ar: "مبيعات تذاكر عبر الإنترنت بعملات متعددة",
    },
    Attendee: {
      text_en: "Attendee Check Out Process That Is Seamless",
      text_am: "ተሰብሳቢው እንከን የለሽ የሆነውን የክበብ ሂደትን ያረጋግጡ",
      text_fr: "Processus de sortie des participants sans couture",
      text_sw: "Mchakato wa Kukagua Washiriki Ambao Ni Waendana",
      text_ar: "عملية تسجيل خروج المشاركين التي لا تشوبها شائبة",
    },
    RSVP: {
      text_en: "RSVP In Advance Of Your Event",
      text_am: "ከክስተትዎ በፊት መልስ ይስጡ",
      text_fr: "Répondez s'il vous plaît à l'avance de votre événement",
      text_sw: "Tafadhali Jibu Kabla ya Matukio Yako",
      text_ar: "يرجى تأكيد الحضور قبل حدثك",
    },
    Detailed: {
      text_en: "Detailed Reporting And Analytics",
      text_am: "ዝርዝር ዘገባ እና ትንታኔ",
      text_fr: "Rapports détaillés et analyses",
      text_sw: "Ripoti na Uchambuzi wa Kina",
      text_ar: "تقارير وتحليلات مفصلة",
    },
    GetStarted: {
      text_en: "Get Started For Free",
      text_am: "በነጻ ይጀምሩ",
      text_fr: "Commencer",
      text_sw: "Anza",
      text_ar: "ابدأ مجانًا",
    },
  },
  Help: {
    Help: {
      text_en: "Looking for some help?",
      text_am: "እርዳታ እየፈለጉ ነው?",
      text_fr: "Vous cherchez de l'aide?",
      text_sw: "Je, unatafuta usaidizi?",
      text_ar: "هل تبحث عن مساعدة؟",
    },
    "Table of Content": {
      text_en: "Table of Content",
      text_am: "የይዘት ሰንጠረዥ",
      text_fr: "Table des matières",
      text_sw: "Jedwali la Yaliyomo",
      text_ar: "جدول المحتويات",
    },
    HomePage: {
      "Home Page": {
        text_en: "Home Page",
        text_am: "መነሻ ገጽ",
        text_fr: "Page d'accueil",
        text_sw: "Ukurasa wa Nyumbani",
        text_ar: "الصفحة الرئيسية",
      },
      "Main Home page of the public event": {
        text_en: "Main Home page of the public event",
        text_am:
          "የሕዝባዊ ክስተት አስተዳዳሪ ድር ጣቢያ ዋና መነሻ ገጽ ስለ ክስተቶች እና ስለመሳሰሉት የተለያዩ የተጣራ መረጃዎች።",
        text_fr: "Page d'accueil principale de l'événement public",
        text_sw: "Ukurasa Mkuu wa Tukio la Umma",
        text_ar: "الصفحة الرئيسية الرئيسية للحدث العام",
      },
      Features: {
        text_en: "Features",
        text_am: "ዋና መለያ ጸባያት",
        text_fr: "Fonctionnalités",
        text_sw: "Vipengele",
        text_ar: "الميزات",
      },
      "Premium Events are listed under the Premium Events section of the home page.":
        {
          text_en:
            "Premium Events are listed under the Premium Events section of the home page.",
          text_am: "ፕሪሚየም ዝግጅቶች በመነሻ ገጹፕሪሚየም ክስተቶች ክፍል ስር ተዘርዝረዋል።",
          text_fr:
            "Les événements Premium sont répertoriés dans la section Événements Premium de la page d'accueil.",
          text_sw:
            "Matukio ya Kulipiwa yameorodheshwa chini ya sehemu ya Matukio ya Kulipiwa ya ukurasa wa nyumbani.",
          text_ar:
            "يتم سرد الأحداث المتميزة تحت قسم الأحداث المتميزة في صفحة الرئيسية.",
        },
      "List of Event Organizers are displayed.": {
        text_en: "List of Event Organizers are displayed.",
        text_am: "የክስተት አዘጋጆች ዝርዝር ይታያል።",
        text_fr: "La liste des organisateurs d'événements est affichée.",
        text_sw: "Orodha ya waandaaji wa matukio inaonyeshwa.",
        text_ar: "يتم عرض قائمة منظمي الفعاليات.",
      },
      "List of Public Events in your location": {
        text_en: "List of Public Events in your location",
        text_am:
          "ከክስተቶች ዝርዝር ውስጥ በአከባቢህ ያሉ የህዝብ ክንውኖች ዝርዝር ተጣርቶ በከተማህ ውስጥ በክስተቶች ክፍል ስር ይታያል።",
        text_fr: "Liste des événements publics dans votre emplacement",
        text_sw: "Orodha ya Matukio ya Umma katika eneo lako",
        text_ar: "قائمة بالأحداث العامة في موقعك",
      },
      "Events can be searched and filtered by their location, category and their price range.":
        {
          text_en:
            "Events can be searched and filtered by their location, category and their price range.",
          text_am:
            "ክስተቶች በአካባቢያቸው፣ በምድባቸው እናይ ዋና መለያኻ ገጾታት በሕግኹን፣ ምድባችን እና ብዙን የዋጋ ዓይነት መቃጠል ተጣርቆ ይችላሉ።",
          text_fr:
            "Les événements publics peuvent être recherchés et filtrés en fonction de différents paramètres tels que leur emplacement, leur catégorie et leur gamme de prix.",
          text_sw:
            "Matukio ya umma yanaweza kutafutwa na kuchujwa kulingana na eneo, jamii, na kikomo cha bei yao.",
          text_ar:
            "يمكن البحث عن الأحداث وتصفيتها حسب موقعها وفئتها ونطاق الأسعار الخاص بها.",
        },
      "List Event Categories": {
        text_en: "List Event Categories",
        text_am: "የክስተቶች ዝርዝር",
        text_fr: "Liste des catégories d'événements",
        text_sw: "Orodha ya Jamii za Matukio",
        text_ar: "قائمة فئات الأحداث",
      },
      "Public events can be filtered based on different parameters based on the user’s interest,":
        {
          text_en:
            "Public events can be filtered based on different parameters based on the user’s interest,",
          text_am: "የህግ ዝርዝርዎን መጠቀም ይችላሉ በጥንቃቄዎች የተዘጋጀ መለዋጭ ላይ።",
          text_fr:
            "Les événements publics peuvent être filtrés en fonction de différents paramètres selon l'intérêt de l'utilisateur,",
          text_sw:
            "Matukio ya umma yanaweza kuchujwa kulingana na vigezo tofauti kulingana na maslahi ya mtumiaji,",
          text_ar:
            "يمكن تصفية الأحداث العامة بناءً على معايير مختلفة استنادًا إلى اهتمام المستخدم،",
        },
      "Click on any event category icon under the list of the categories(Fly Through Category section) in the homepage then you will be redirected to .":
        {
          text_en:
            "Click on any event category icon under the list of the categories (Fly Through Category section) in the homepageثم ستتم إعادة توجيهك إلى .",
          text_am: "ፕሪሚየም ክስተቶች ክፍል ስር ተዘርዝረዋል።",
          text_fr:
            "Cliquez sur n'importe quelle icône de catégorie d'événement sous la liste des catégories (section Fly Through Category) sur la page d'accueil, puis vous serez redirigé vers.",
          text_sw:
            "Bonyeza kwenye aikoni yoyote ya jamii ya tukio chini ya orodha ya jamii (sehemu ya Kupitia Jamii) kwenye ukurasa wa nyumbani kisha utaelekezwa kwa.",
          text_ar:
            "انقر فوق أي رمز فئة حدث في قائمة الفئات (قسم Fly Through Category) في الصفحة الرئيسية، ثم سيتم إعادة توجيهك إلى.",
        },
      "Premium Events": {
        text_en: "Premium Events",
        text_am: "ፕሪሚየም ዝርዝርዎን",
        text_fr: "Événements Premium",
        text_sw: "Matukio ya Kipekee",
        text_ar: "الأحداث المتميزة",
      },
      "Premium Events list will be showed up under the PREMIUM EVENTS section.":
        {
          text_en:
            "Premium Events list will be showed up under the PREMIUM EVENTS section.",
          text_am: "ፕሪሚየም ዝርዝር በፕሪሚየም ዝርዝርዎ የተሰኘ ይሆናል።",
          text_fr:
            "La liste des événements Premium apparaîtra sous la section ÉVÉNEMENTS PREMIUM.",
          text_sw:
            "Orodha ya Matukio ya Kipekee itaonyeshwa chini ya sehemu ya MATUKIO YA KIPEKEE.",
          text_ar: "سيتم عرض قائمة الأحداث المميزة تحت قسم الأحداث المميزة.",
        },
      "Click on(blue star)icon next to PREMIUM EVENTS to display list of all premium events .":
        {
          text_en:
            "Click on(blue star)icon next to PREMIUM EVENTS to display list of all premium events .",
          text_am:
            "በፕሪሚየም ዝርዝር በቀረበ ሰዓት የተሰኘ ቁልፍ(ስፖንሰር) የተሰማሩትን ፕሪሚየም ዝርዝር እዚህ ይመልከቱ።",
          text_fr:
            "Cliquez sur l'icône (étoile bleue) à côté des ÉVÉNEMENTS PREMIUM pour afficher la liste de tous les événements premium.",
          text_sw:
            "Bonyeza kwenye ishara (nyota ya bluu) iliyo karibu na MATUKIO YA KIPEKEE kuonyesha orodha ya matukio yote ya kipekee.",
          text_ar:
            "انقر فوق الرمز (النجمة الزرقاء) الموجود بجانب الأحداث المميزة لعرض قائمة جميع الأحداث المميتركيزًا على تفاصيل المنظم ومعلومات الاتصال الخاصة بهم والأحداث التي نظموها.",
        },
      "Click on any event and you will be redirected to event's detail page.": {
        text_en:
          "Click on any event and you will be redirected to event's detail page.",
        text_am: "የቀጥታችን የክስተት ማስታወቂያ ገጽን ይመልከቱ።",
        text_fr:
          "Cliquez sur n'importe quel événement et vous serez redirigé vers la page de détails de l'événement.",
        text_sw:
          "Bonyeza kwenye tukio lolote na utaelekezwa kwenye ukurasa wa maelezo ya tukio.",
        text_ar: "انقر على أي حدث وسيتم إعادة توجيهك إلى صفحة تفاصيل الحدث.",
      },
      "Click on any organizer and you will be redirected to organizer’s detail page.":
        {
          text_en:
            "Click on any organizer and you will be redirected to organizer’s detail page.",
          text_am: "በማንኛውም አደራጅ ላይ ጠቅ ያድርጉ እና ወደ የአደራጁ ዝርዝር ገጽ ይዛወራሉ።",
          text_fr:
            "Cliquez sur n'importe quel organisateur et vous serez redirigé vers la page de détail de l'organisateur.",
          text_sw:
            "Bofya kwenye mwandalizi yeyote na utaelekezwa kwenye ukurasa wa maelezo ya mratibu.",
          text_ar:
            "انقر على أي منظم وسيتم إعادة توجيهك إلى صفحة تفاصيل المنظم.",
        },
      "List of Owl Organizers List": {
        text_en: "List of Owl Organizers List",
        text_am: "የኦውል የተወሰኑበት ዝርዝር",
        text_fr: "Liste des organisateurs de Owl",
        text_sw: "Orodha ya Wasanidi wa Owl",
        text_ar: "قائمة بأسماء منظمي Owl",
      },
      "This section displays the list of registered OwlEvents Organizers.": {
        text_en:
          "This section displays the list of registered OwlEvents Organizers.",
        text_am: "ዚህ ክፍል የተወሰኑትን ኦውል የተመዘገበ የተገኙትን የክስተት ምናሌት ዝርዝር ይሰጣል።",
        text_fr:
          "Cette section affiche la liste des organisateurs enregistrés d'OwlEvents.",
        text_sw:
          "Sehemu hii inaonyesha orodha ya Wasanidi waliosajiliwa wa OwlEvents.",
        text_ar: "تعرض هذه القسم قائمة بمنظمي الفعاليات المسجلين في OwlEvents.",
      },
      "You can browse through the list of organizers and click on their names to view their profile.":
        {
          text_en:
            "You can browse through the list of organizers and click on their names to view their profile.",
          text_am:
            "ለተወሰኑት የተገኙት የክስተቶች ዝርዝር በማድረግ የእርስዎን ስም ይጫኑት እና መግለጫዎቹን ለመመልከት ይችላሉ።",
          text_fr:
            "Vous pouvez parcourir la liste des organisateurs et cliquer sur leurs noms pour voir leur profil.",
          text_sw:
            "Unaweza kuvinjari kupitia orodha ya wasanidi na bonyeza majina yao kuona wasifu wao.",
          text_ar:
            "يمكنك التصفح من خلال قائمة المنظمين والنقر على أسمائهم لعرض ملفاتهم الشخصية.",
        },
      "Profile includes the details of the organizer, their contact information, and the events they have organized.":
        {
          text_en:
            "Profile includes the details of the organizer, their contact information, and the events they have organized.",
          text_am:
            "ስለ ክስተተት ያሉትን የተወሰኑት የንግግር መረጃዎችን የተከታዩትን መገለጫ መርጃ ይኖርብልሃል።",
          text_fr:
            "Le profil comprend les détails de l'organisateur, leurs coordonnées et les événements qu'ils ont organisés.",
          text_sw:
            "Wasifu unajumuisha maelezo ya mwendeshaji, habari za mawasiliano yao, na matukio waliyoandaa.",
          text_ar:
            "الملف الشخصي يتضمن تفاصيل المنظم ومعلومات الاتصال الخاصة به والأحداث التي نظموها.",
        },
    },

    Events: {
      Events: {
        text_en: "Events",
        text_am: "ክስተቶች",
        text_fr: "Événements",
        text_sw: "Matukio",
        text_ar: "الأحداث",
      },
      "This page displays list of events filtered by multiple categories and types.":
        {
          text_en:
            "This page displays a list of events filtered by multiple categories and types.",
          text_am:
            "ይዘት በሰራተኛ ዝርዝርና አካልዎች የተጣራ መረጃዎችን ተግባርነት በማሰብ እዚህ በክስተቶች ገጽ ላይ ማሳሰቢያ ይችላሉ።",
          text_fr:
            "Cette page affiche une liste d'événements filtrés par plusieurs catégories et types.",
          text_sw:
            "Ukurasa huu unaonyesha orodha ya matukio iliyochujwa kwa makundi na aina mbalimbali.",
          text_ar:
            "يعرض هذا الصفحة قائمة الأحداث المُفلترة حسب الفئات والأنواع المتعددة.",
        },
      Features: {
        text_en: "Features",
        text_am: "የተጠቀመው ገጽ",
        text_fr: "Fonctionnalités",
        text_sw: "Vipengele",
        text_ar: "المميزات",
      },
      "Displays list of events filtered by many categories .": {
        text_en: "Displays a list of events filtered by many categories.",
        text_am: "በተጣራ ምድብ የተቀመጡ ክስተቶችን ተግባርነት ይሰጣል።",
        text_fr:
          "Affiche une liste d'événements filtrés par de nombreuses catégories.",
        text_sw: "Inaonyesha orodha ya matukio iliyochujwa kwa makundi mengi.",
        text_ar: "يعرض قائمة الأحداث المُفلترة حسب العديد من الفئات.",
      },
      "Events can be searched and filtered by their date, category, vendor and their price range.":
        {
          text_en:
            "Events can be searched and filtered by their date, category, vendor and their price range.",
          text_am:
            "ክስተቶች በቀንበር፣ ምድብ፣ ሻጩና የዋጋ ምርጫ ተፈላጊ መረጃዎች እና በዋጋ ድጋፈ ክልል ይፈልጋሉ።",
          text_fr:
            "Les événements peuvent être recherchés et filtrés par date, catégorie, fournisseur et gamme de prix.",
          text_sw:
            "Matukio yanaweza kutafutwa na kuchujwa kulingana na tarehe, kitengo, muuzaji na anuwai ya bei.",
          text_ar:
            "يمكن البحث عن الأحداث وتصفيتها حسب التاريخ والفئة والمورد ونطاق السعر.",
        },
      "View Event Details and Purchase tickets.": {
        text_en: "View Event Details and Purchase tickets.",
        text_am: "የክስተት መረጃዎችን ያሳውቁና ብዛት ይጫኑ።",
        text_fr: "Voir les détails de l'événement et acheter des billets.",
        text_sw: "Tazama Maelezo ya Tukio na Ununuzi tiketi.",
        text_ar: "عرض تفاصيل الحدث وشراء التذاكر.",
      },
      "Parameters To Purchase Tickets": {
        text_en: "Parameters To Purchase Tickets",
        text_am: "ምድቦች ለተጠቃሚው ለክስተት መረጃዎች",
        text_fr: "Paramètres pour acheter des billets",
        text_sw: "Vigezo vya Kununua Tiketi",
        text_ar: "معلمات شراء التذاكر",
      },
      "Full Name": {
        text_en: "Full Name",
        text_am: "ሙሉ ስም",
        text_fr: "Nom complet",
        text_sw: "Jina Kamili",
        text_ar: "الاسم الكامل",
      },
      "Email Address": {
        text_en: "Email Address",
        text_am: "ኢሜይል አድራሻ",
        text_fr: "Adresse e-mail",
        text_sw: "Anwani ya Barua pepe",
        text_ar: "عنوان البريد الإلكتروني",
      },
      "Phone Number": {
        text_en: "Phone Number",
        text_am: "ስልክ ቁጥር",
        text_fr: "Numéro de téléphone",
        text_sw: "Namba ya Simu",
        text_ar: "رقم الهاتف",
      },
      Quantity: {
        text_en: "Quantity",
        text_am: "መጠንያ ብዛት",
        text_fr: "Quantité",
        text_sw: "Idadi",
        text_ar: "الكمية",
      },
      "Promo Code": {
        text_en: "Promo Code",
        text_am: "ፕሮምኮድ",
        text_fr: "Code promotionnel",
        text_sw: "Nambari ya Uendelezaji",
        text_ar: "رمز الترويج",
      },
      "Payment method": {
        text_en: "Payment method",
        text_am: "የክፍያ መንገድ",
        text_fr: "Méthode de paiement",
        text_sw: "Njia ya Malipo",
        text_ar: "طريقة الدفع",
      },
      "List of Event": {
        text_en: "List of Event",
        text_am: "የክስተት ዝርዝር",
        text_fr: "Liste des événements",
        text_sw: "Orodha ya Matukio",
        text_ar: "قائمة الأحداث",
      },
      "Public events can be filtered based on different parameters based on the user’s interest,":
        {
          text_en:
            "Public events can be filtered based on different parameters based on the user’s interest,",
          text_am: "የህዝብ ክስተቶች በተጠቃሚዎችና በተሳሳተ የግል መረጃዎች መጠቀም ይችላሉ።",
          text_fr:
            "Les événements publics peuvent être filtrés en fonction de différents paramètres, selon l'intérêt de l'utilisateur,",
          text_sw:
            "Matukio ya umma yanaweza kuchujwa kulingana na vigezo tofauti kulingana na masilahi ya mtumiaji,",
          text_ar:
            "يمكن تصفية الأحداث العامة بناءً على معايير مختلفة استنادًا إلى اهتمام المستخدم.",
        },
      "Click on any event category icon under the list of the categories(on the left side of the events list)in the events page then filtered events list will be displayed. The default type of category is ALL.":
        {
          text_en:
            "Click on any event category icon under the list of the categories(on the left side of the events list)in the events page then filtered events list will be displayed. The default type of category is ALL.",
          text_am:
            "ከምድብ ውስጥ የክስተት ዝርዝር ሊያግዙ ይችላሉ። በክስተት ገጽ ከተወሰነው አካባቢዎች (በክስተት ዝርዝር ግራ ተከታታይ ምድቦችን) ላይ ይጫኑ። በቅርብ ምድቦች ብቻ በአንድ ትእዛዝ ይኖራሉ።",
          text_fr:
            "Cliquez sur n'importe quelle icône de catégorie d'événement sous la liste des catégories (sur le côté gauche de la liste des événements) dans la page des événements, puis la liste des événements filtrés sera affichée. Le type de catégorie par défaut est TOUS.",
          text_sw:
            "Bonyeza kwenye picha ya jamii ya tukio lolote chini ya orodha ya jamii (kwenye upande wa kushoto wa orodha ya matukio) kwenye ukurasa wa matukio kisha orodha iliyofichuliwa ya matukio itaonyeshwa. Aina ya jamii ya msingi ni YOTE.",
          text_ar:
            "انقر على أي رمز لفئة الحدث تحت قائمة الفئات (على الجانب الأيسر من قائمة الأحداث) في صفحة الأحداث ، ثم سيتم عرض قائمة الأحداث المفلترة. الفئة الافتراضية هي الكل.",
        },
      "Search and filter Events": {
        text_en: "Search and filter Events",
        text_am: "ክስተቶችን ፈልግዎት እና ማግኘት",
        text_fr: "Rechercher et filtrer les événements",
        text_sw: "Tafuta na Chuja Matukio",
        text_ar: "البحث وتصفية الأحداث",
      },
      "Events can be either searched or filtered by multiple parameters like category, date, vendor and price range.":
        {
          text_en:
            "Events can be either searched or filtered by multiple parameters like category, date, vendor and price range.",
          text_am: "ክስተቶች በካርታዎች፣ ቀን፣ ሻጩ፣ የዋጋ ምርጫ ተግባርነት ይፈልጋሉ።",
          text_fr:
            "Les événements peuvent être recherchés ou filtrés en fonction de plusieurs paramètres tels que la catégorie, la date, le vendeur et la fourchette de prix.",
          text_sw:
            "Matukio yanaweza kutafutwa au kuchujwa kwa vigezo vingi kama vile jamii, tarehe, muuzaji, na kiwango cha bei.",
          text_ar:
            "يمكن البحث عن الأحداث أو تصفيتها باستخدام معايير متعددة مثل الفئة والتاريخ والبائع ونطاق الأسعار.",
        },

      "To search events go to the search bar at the top center of the page and enter the event you want to search and press FIND EVENT button then the events list will be displayed.":
        {
          text_en:
            "To search events go to the search bar at the top center of the page and enter the event you want to search and press FIND EVENT button then the events list will be displayed.",
          text_am:
            "ክስተቶችን ፈልግዎት ለማግኘት ለስልክ ቅርጽ ምልክት ይምረጡ፣ ተግባርነትን ይምረጡና ቀጥለህ ይቆጥራል። ከዚያም በቀጥሎ የክስተት ዝርዝር ይለውጣል።",
          text_fr:
            "Pour rechercher des événements, allez à la barre de recherche en haut au centre de la page, saisissez l'événement que vous souhaitez rechercher et appuyez sur le bouton TROUVER L'ÉVÉNEMENT, puis la liste des événements sera affichée.",
          text_sw:
            "Ili kutafuta matukio, nenda kwenye kisanduku cha utafutaji katikati ya juu ya ukurasa na ingiza tukio unalotaka kutafuta na bonyeza kitufe cha TAFUTA TUKIO kisha orodha ya matukio itaonyeshwa.",
          text_ar:
            "للبحث عن الأحداث، انتقل إلى شريط البحث في الجزء العلوي المركزي من الصفحة وأدخل الحدث الذي ترغب في البحث عنه ثم اضغط على زر البحث عن الحدث ، ثم سيتم عرض قائمة الأحداث.",
        },

      "To filter events click on the filter icon on the left side of the search bar, select the filtering parameters and press continue, then events list will be displayed.":
        {
          text_en:
            "To filter events click on the filter icon on the left side of the search bar, select the filtering parameters and press continue, then events list will be displayed.",
          text_am:
            "ክስተቶችን ለመፈለግ ለስልክ ቅርጽ ምልክት ይምረጡ፣ ተግባርነትን ይምረጡና ቀጥለህ ይቆጥራል። ከዚያም በቀጥሎ የክስተት ዝርዝር ይለውጣል።",
          text_fr:
            "Pour filtrer les événements, cliquez sur l'icône de filtre sur le côté gauche de la barre de recherche, sélectionnez les paramètres de filtrage et appuyez sur Continuer, puis la liste des événements sera affichée.",
          text_sw:
            "Ili kuchuja matukio, bonyeza kwenye ishara ya kichujio upande wa kushoto wa kisanduku cha utafutaji, chagua vigezo vya kuchuja na bonyeza kuendelea, kisha orodha ya matukio itaonyeshwa.",
          text_ar:
            "لتصفية الأحداث ، انقر على رمز الترشيح على الجانب الأيسر من شريط البحث ، وحدد معايير التصفية واضغط على متابعة ، ثم سيتم عرض قائمة الأحداث.",
        },

      "View Event Details": {
        text_en: "View Event Details",
        text_am: "የክስተት መረጃዎችን ያሳውቁ",
        text_fr: "Voir les détails de l'événement",
        text_sw: "Angalia Maelezo ya Tukio",
        text_ar: "عرض تفاصيل الحدث",
      },
      "To display detail information about the event,": {
        text_en: "To display detail information about the event...",
        text_am: "የክስተት መረጃዎችን ያሳውቁ",
        text_fr: "Pour afficher des informations détaillées sur l'événement,",
        text_sw: "Ili kuonyesha habari za kina kuhusu tukio,",
        text_ar: "لعرض معلومات تفصيلية عن الحدث،",
      },
      "Click on any event item and you will be redirected automatically to the events detail page.":
        {
          text_en:
            "Click on any event item and you will be redirected automatically to the events detail page.",
          text_am:
            "ለምርጫ ውስጥ ያለውን የክስተት ንትርክ ይምረጡ፣ ከዚህ ከታች የክስተት መረጃ ገጽን በማስገባት በክስተት መግቢያ ገጽ ላይ ይሂዱ።",
          text_fr:
            "Cliquez sur n'importe quel élément d'événement et vous serez automatiquement redirigé vers la page de détails de l'événement.",
          text_sw:
            "Bonyeza kwenye kipengee chochote cha tukio na utaelekezwa moja kwa moja kwenye ukurasa wa maelezo ya kina ya matukio.",
          text_ar:
            "انقر على أي عنصر حدث وسيتم إعادة توجيهك تلقائيًا إلى صفحة تفاصيل الأحداث.",
        },

      "Purchase Tickets": {
        text_en: "Purchase Tickets",
        text_am: "መጥቀብ መረጃዎችን",
        text_fr: "Acheter des billets",
        text_sw: "Nunua Tiketi",
        text_ar: "شراء التذاكر",
      },

      "To purchase tickets for an event,": {
        text_en: "To purchase tickets for an event,",
        text_am: "መጥቀብ መረጃዎችን ለመጥቀብ የክስተት ምርጫ ይምረጡ።",
        text_fr: "Pour acheter des billets pour un événement,",
        text_sw: "Kununua Tiketi kwa Tukio",
        text_ar: "لشراء تذاكر لحدث ما،",
      },

      "Go to events detail page and select among the types of tickets.": {
        text_en:
          "Go to events detail page and select among the types of tickets.",
        text_am:
          "የክስተት መግቢያውን ይጠቀሙ እና ስልክህን በቀጥታ የሚል በመረጃ የክስተት መግቢያ ገጽ ላይ ይሂዱ።",
        text_fr:
          "Accédez à la page de détails des événements et sélectionnez parmi les types de billets.",
        text_sw:
          "Nenda kwenye ukurasa wa maelezo ya kina ya matukio na chagua kati ya aina za tiketi.",
        text_ar: "انتقل إلى صفحة تفاصيل الأحداث واختر من بين أنواع التذاكر.",
      },

      "Then enter the required parameters for purchasing ticket to an event and click NEXT button.":
        {
          text_en:
            "Then enter the required parameters for purchasing ticket to an event and click NEXT button.",
          text_am:
            "በመጀመሪያ የክስተት መጥቀብ ለመቀየር የሚጠቀሙትን ምርጫዎች ይምረጡ፣ ከግል አዝራሮች ይጠቀሙ።",
          text_fr:
            "Ensuite, saisissez les paramètres requis pour l'achat d'un billet pour un événement et cliquez sur le bouton SUIVANT.",
          text_sw:
            "Kisha ingiza parameta zinazohitajika kwa ununuzi wa tiketi kwa tukio na bonyeza kitufe cha IFUATACHO.",
          text_ar:
            "ثم أدخل المعلمات المطلوبة لشراء تذكرة لحدث ما وانقر على زر NEXT.",
        },
      "Finally the order details will be displayed and click on PLACE ORDER button to place your order or CANCEL to cancel the order.":
        {
          text_en:
            "Finally the order details will be displayed and click on PLACE ORDER button to place your order or CANCEL to cancel the order.",
          text_am:
            "የተላኩ መግለጫ ዝርዝር ለማሳየት የትኛውን ማሳወቅ እና PLACE ORDER አዝራርን ለማግኘት ያስችላል ወይም CANCEL አዝራርን ለማስተዋል ይህን ይጫኑ።",
          text_fr:
            "Enfin, les détails de la commande seront affichés et cliquez sur le bouton PASSER LA COMMANDE pour passer votre commande ou ANNULER pour annuler la commande.",
          text_sw:
            "Hatimaye maelezo ya agizo yataonyeshwa na bonyeza kitufe cha WEKA AGIZO kuweka agizo lako au BATILISHA kufuta agizo.",
          text_ar:
            "أخيرًا سيتم عرض تفاصيل الطلب والنقر على زر تقديم الطلب لتقديم طلبك أو إلغاء لإلغاء الطلب.",
        },
    },

    "Events Based on User's Location": {
      "Events based on User's Locations": {
        text_en: "Events based on User's Locations",
        text_am: "ክስተቶች በተጠቃሚው አካባቢ መካከል ያሉት የአካባቢ መገኛ ዝግጅቶች",
        text_fr: "Événements basés sur les emplacements de l'utilisateur",
        text_sw: "Matukio yanayotegemea Maeneo ya Mtumiaji",
        text_ar: "فعاليات تعتمد على مواقع المستخدم",
      },
      "This page displays list of live events filtered by locations.": {
        text_en:
          "This page displays list of live events filtered by locations.",
        text_am:
          "ይህ ገጽ የተዘጋጅባቸውን አካባቢዎችን በተጠቃሚው አካባቢ መገኛ ምርጫ የሚሰጡ የአይነት ዝግጅቶች ይመልከቱ።",
        text_fr:
          "Cette page affiche une liste d'événements en direct filtrés par emplacements.",
        text_sw:
          "Ukurasa huu unaonyesha orodha ya matukio yanayotokea moja kwa moja yaliyochujwa kwa kuzingatia maeneo.",
        text_ar: "يعرض هذا الصفحة قائمة بالفعاليات الحية المصفاة حسب المواقع.",
      },
      Features: {
        text_en: "Features",
        text_am: "ዋና መለያ ጸባያት",
        text_fr: "Fonctionnalités",
        text_sw: "Vipengele",
        text_ar: "الميزات",
      },
      "Displays list of live events filtered by location .": {
        text_en: "Displays list of live events filtered by location .",
        text_am: "የተጠቃሚው አካባቢ መገኛ ዝግጅቶችን ይሰጣሉ በተጠቃሚው አካባቢ መገኛ",
        text_fr:
          "Affiche une liste d'événements en direct filtrés par emplacement.",
        text_sw:
          "Inaonyesha orodha ya matukio yanayotokea moja kwa moja yaliyochujwa kwa eneo.",
        text_ar: "يعرض قائمة بالفعاليات الحية المصفاة حسب الموقع.",
      },
      "View Event Details and Purchase tickets.": {
        text_en: "View Event Details and Purchase tickets.",
        text_am: "የክስተት ዝርዝር ዝርዝር እና መጥቀብ መለያዎችን ይመልከቱ።",
        text_fr: "Afficher les détails de l'événement et acheter des billets.",
        text_sw: "Angalia Maelezo ya Tukio na Nunua tiketi.",
        text_ar: "عرض تفاصيل الفعالية وشراء التذاكر.",
      },
      "Parameters To Purchase Tickets": {
        text_en: "Parameters To Purchase Tickets",
        text_am: "መጥቀብ መረጃዎች ለመጥቀብ የሚያስገቡ መረጃዎች",
        text_fr: "Paramètres pour acheter des billets",
        text_sw: "Vigezo vya Kununua Tiketi",
        text_ar: "معلمات شراء التذاكر",
      },
      "Full Name": {
        text_en: "Full Name",
        text_am: "ሙሉ ስም",
        text_fr: "Nom complet",
        text_sw: "Jina Kamili",
        text_ar: "الاسم الكامل",
      },
      "Email Address": {
        text_en: "Email Address",
        text_am: "ኢሜል አድራሻ",
        text_fr: "Adresse e-mail",
        text_sw: "Anwani ya Barua pepe",
        text_ar: "عنوان البريد الإلكتروني",
      },
      "Phone Number": {
        text_en: "Phone Number",
        text_am: "ስልክ ቁጥር",
        text_fr: "Numéro de téléphone",
        text_sw: "Namba ya Simu",
        text_ar: "رقم الهاتف",
      },
      Quantity: {
        text_en: "Quantity",
        text_am: "ብዛት",
        text_fr: "Quantité",
        text_sw: "Idadi",
        text_ar: "الكمية",
      },
      "Promo Code": {
        text_en: "Promo Code",
        text_am: "ፕሮምኮድ",
        text_fr: "Code promotionnel",
        text_sw: "Nambari ya Matangazo",
        text_ar: "كود العرض الترويجي",
      },
      "Payment method": {
        text_en: "Payment method",
        text_am: "የክፍያ መስመር ክፍያ",
        text_fr: "Méthode de paiement",
        text_sw: "Njia ya Malipo",
        text_ar: "طريقة الدفع",
      },
      "List of Events filtered by location": {
        text_en: "List of Events filtered by location",
        text_am: "በተጠቃሚው አካባቢ ምርጫ ተዘጋጅቶች ዝርዝር",
        text_fr: "Liste des événements filtrés par emplacement",
        text_sw: "Orodha ya Matukio yaliyofungwa kwa Eneo",
        text_ar: "قائمة بالفعاليات المصفاة حسب الموقع",
      },
      "Live events can be filtered based on different parameters based on the user’s interest,":
        {
          text_en:
            "Live events can be filtered based on different parameters based on the user’s interest,",
          text_am:
            "በተጠቃሚው ለማጠቃለቅ የአገራችን ምርጫዎች ተዘጋጅቶች በተለያዩ መለያዎች ውስጥ ለሚፈልጉ በየአካባቢዎች የተሟላ ምርጫዎች ይመልከቱ።",
          text_fr:
            "Les événements en direct peuvent être filtrés en fonction de différents paramètres en fonction de l'intérêt de l'utilisateur,",
          text_sw:
            "Matukio hai yanaweza kuchujwa kulingana na vigezo tofauti kulingana na maslahi ya mtumiaji,",
          text_ar:
            "يمكن تصفية الفعاليات الحية بناءً على معايير مختلفة استنادًا إلى اهتمامات المستخدم",
        },
      "Click on(blue star)icon nav item on top right corner of the top navigation bar to get into events list page.":
        {
          text_en:
            "Click on(blue star)icon nav item on top right corner of the top navigation bar to get into events list page.",
          text_am:
            "የተጠቃሚውን መግለጫ ገጽ ለመጠቀም ለሌላት ምርጫ ሰንጠረዥ አድርግም ከላይ በላይ የሚሰጡትን እንዴትነው በማስቀመጥ በእቅድ ዝርዝር ገጽ ላይ ይግቡ።",
          text_fr:
            "Cliquez sur l'élément de navigation de l'icône (étoile bleue) dans le coin supérieur droit de la barre de navigation supérieure pour accéder à la page de liste des événements.",
          text_sw:
            "Bonyeza kwenye kipengele cha usafiri cha ishara ya nyota ya bluu katika kona ya juu kulia ya upau wa usafiri wa juu ili uingie kwenye ukurasa wa orodha ya matukio.",
          text_ar:
            "انقر على عنصر القائمة التنقلية (نجمة زرقاء) في الزاوية اليمنى العلوية من شريط التنقل العلوي للوصول إلى صفحة قائمة الفعاليات.",
        },
      "Then pin the location you want on the map and events in that location will be filtered and displayed.":
        {
          text_en:
            "Then pin the location you want on the map and events in that location will be filtered and displayed.",
          text_ar:
            "ثم قم بتثبيت الموقع الذي تريده على الخريطة وسيتم تصفية الأحداث في تلك المنطقة وعرضها.",
          text_fr:
            "Ensuite, épinglez l'emplacement que vous souhaitez sur la carte et les événements de cet emplacement seront filtrés et affichés.",
          text_sw:
            "Kisha gonga eneo unalotaka kwenye ramani na matukio katika eneo hilo yatachujwa na kuonyeshwa.",
        },
      "Select and View Event Details": {
        text_en: "Select and View Event Details",
        text_ar: "تحديد وعرض تفاصيل الحدث",
        text_fr: "Sélectionner et Afficher les Détails de l'Événement",
        text_sw: "Chagua na Angalia Maelezo ya Tukio",
      },
      "To select and display detail information about the event,": {
        text_en: "To select and display detail information about the event,",
        text_ar: "لتحديد وعرض تفاصيل الحدث،",
        text_fr:
          "Pour sélectionner et afficher des informations détaillées sur l'événement,",
        text_sw: "Kuchagua na Kuonyesha Maelezo ya Kina kuhusu Tukio,",
      },
      "Click on any event item and you will be redirected automatically to the events detail page.":
        {
          text_en:
            "Click on any event item and you will be redirected automatically to the events detail page.",
          text_ar:
            "انقر فوق أي عنصر حدث وستتم إعادة توجيهك تلقائيًا إلى صفحة تفاصيل الأحداث.",
          text_fr:
            "Cliquez sur n'importe quel élément d'événement et vous serez automatiquement redirigé vers la page des détails de l'événement.",
          text_sw:
            "Bonyeza kwenye kipengee chochote cha tukio na utaelekezwa moja kwa moja kwenye ukurasa wa maelezo ya tukio.",
        },
      "Purchase Tickets": {
        text_en: "Purchase Tickets",
        text_ar: "شراء التذاكر",
        text_fr: "Acheter des Billets",
        text_sw: "Kununua Tiketi",
      },
      "To purchase tickets for an event,": {
        text_en: "To purchase tickets for an event,",
        text_ar: "لشراء التذاكر لحدث،",
        text_fr: "Pour acheter des billets pour un événement,",
        text_sw: "Kununua tiketi kwa ajili ya tukio,",
      },
      "Go to events detail page and select among the types of tickets.": {
        text_en:
          "Go to events detail page and select among the types of tickets.",
        text_ar: "انتقل إلى صفحة تفاصيل الأحداث واختر بين أنواع التذاكر.",
        text_fr:
          "Accédez à la page des détails de l'événement et sélectionnez parmi les types de billets.",
        text_sw:
          "Nenda kwenye ukurasa wa maelezo ya tukio na chagua kati ya aina za tiketi.",
      },
      "Then enter the required parameters for purchasing ticket to an event and click NEXT button.":
        {
          text_en:
            "Then enter the required parameters for purchasing ticket to an event and click NEXT button.",
          text_ar:
            "ثم أدخل المعلمات المطلوبة لشراء تذكرة لحدث وانقر على الزر التالي.",
          text_fr:
            "Ensuite, saisissez les paramètres requis pour l'achat d'un billet pour un événement et cliquez sur le bouton SUIVANT.",
          text_sw:
            "Kisha ingiza vigezo vinavyohitajika kwa kununua tiketi ya tukio na bonyeza kitufe cha IFUATACHO.",
        },
    },

    "Live Events": {
      "Live Events": {
        text_en: "Live Events",
        text_ar: "الأحداث الحية",
        text_am: "ቀጥታ ክስተቶች",
        text_fr: "Événements en direct",
        text_sw: "Matukio Hai",
      },
      "This page displays list of live events filtered by multiple categories and types.":
        {
          text_en:
            "This page displays a list of live events filtered by multiple categories and types.",
          text_ar:
            "تعرض هذه الصفحة قائمةً بالأحداث الحية المصفاة حسب فئات وأنواع متعددة.",
          text_am:
            "ይህ ገጽ በብልሃትና አይነት ምርጫዎች ላይ የተጠቃሚውን ቀጥታ ክስተቶች የተጠቀምለትን ዝርዝር ማሳየት ይችላሉ።",
          text_fr:
            "Cette page affiche une liste d'événements en direct filtrés par plusieurs catégories et types.",
          text_sw:
            "Ukurasa huu unaonyesha orodha ya matukio hai yaliyochujwa kwa makundi na aina mbalimbali.",
        },
      "Displays list of live events filtered by many categories .": {
        text_en: "Displays a list of live events filtered by many categories.",
        text_ar: "يعرض قائمةً بالأحداث الحية المصفاة حسب فئاتٍ عديدة.",
        text_am: "በብልሃት በተጠቃሚው አካባቢ ምርጫ የተጠቀምለትን ቀጥታ ክስተቶች ዝርዝር ይሰጣል።",
        text_fr:
          "Affiche une liste d'événements en direct filtrés par plusieurs catégories.",
        text_sw:
          "Inaonyesha orodha ya matukio hai yaliyochujwa kwa makundi mengi.",
      },
      "Live events can be searched and filtered by their date, category, vendor and their price range.":
        {
          text_en:
            "Live events can be searched and filtered by their date, category, vendor, and price range.",
          text_ar:
            "يمكن البحث والتصفية عن الأحداث الحية حسب التاريخ والفئة والبائع ونطاق الأسعار الخاص بها.",
          text_am:
            "ቀጥታ ክስተቶች የተሰጠው ቀጥታውን በቀንድም አካባቢዎችን ምርጫዎችን ለተሳተፉ፣ ተሳትፎንም እና የገቢ አካባቢዎችን ለተጠቃሚው የብዙ ምርጫዎች እና የዋጋ ድርጅቶች በማግኘት ይፈልጋሉ።",
          text_fr:
            "Les événements en direct peuvent être recherchés et filtrés par leur date, leur catégorie, leur vendeur et leur gamme de prix.",
          text_sw:
            "Matukio hai yanaweza kutafutwa na kuchujwa kwa tarehe yake, kategoria, muuzaji na kiwango cha bei chake.",
        },
      "View Event Details and Purchase tickets.": {
        text_en: "View Event Details and Purchase Tickets.",
        text_am: "የክስተት ዝርዝርን ማስመልከት እና ክስተቶችን መጥቀብ",
        text_fr: "Afficher les détails de l'événement et acheter des billets.",
        text_sw: "Angalia Maelezo ya Matukio na Nunua Tiketi.",
        text_ar: "عرض تفاصيل الحدث وشراء التذاكر.",
      },
      "Parameters To Purchase Tickets": {
        text_en: "Parameters To Purchase Tickets",
        text_am: "ለክስተቶች መጥቀብ የሚያስችል መለያዎች",
        text_fr: "Paramètres pour acheter des billets",
        text_sw: "Parameta za Kununua Tiketi",
        text_ar: "معلمات لشراء التذاكر",
      },
      "Full Name": {
        text_en: "Full Name",
        text_am: "ሙሉ ስም",
        text_fr: "Nom complet",
        text_sw: "Jina Kamili",
        text_ar: "الاسم الكامل",
      },
      "Email Address": {
        text_en: "Email Address",
        text_am: "ኢሜይል አድራሻ",
        text_fr: "Adresse e-mail",
        text_sw: "Anwani ya Barua pepe",
        text_ar: "عنوان البريد الإلكتروني",
      },
      "Phone Number": {
        text_en: "Phone Number",
        text_am: "ስልክ ቁጥር",
        text_fr: "Numéro de téléphone",
        text_sw: "Namba ya Simu",
        text_ar: "رقم الهاتف",
      },
      "Filter by Category": {
        text_en: "Filter by Category",
        text_ar: "تصفية حسب الفئة",
        text_am: "በምድቦች ፍቃድ ፍትሕ",
        text_fr: "Filtrer par catégorie",
        text_sw: "Chuja kwa Jamii",
      },
      "Filter by Vendor": {
        text_en: "Filter by Vendor",
        text_ar: "تصفية حسب البائع",
        text_am: "በሻጭ ፍቃድ ፍትሕ",
        text_fr: "Filtrer par vendeur",
        text_sw: "Chuja kwa Muuzaji",
      },
      "Filter by Date": {
        text_en: "Filter by Date",
        text_ar: "تصفية حسب التاريخ",
        text_am: "በቀን ፍቃድ ፍትሕ",
        text_fr: "Filtrer par date",
        text_sw: "Chuja kwa Tarehe",
      },
      "Filter by Price Range": {
        text_en: "Filter by Price Range",
        text_ar: "تصفية حسب نطاق الأسعار",
        text_am: "በዋጋ የትንሹ መጠን ፍቃድ ፍትሕ",
        text_fr: "Filtrer par gamme de prix",
        text_sw: "Chuja kwa kiwango cha bei",
      },
      Quantity: {
        text_en: "Quantity",
        text_am: "ብዛት",
        text_fr: "Quantité",
        text_sw: "Kiasi",
        text_ar: "الكمية",
      },
      "Promo Code": {
        text_en: "Promo Code",
        text_am: "ፕሮምዎች ኮድ",
        text_fr: "Code promo",
        text_sw: "Nambari ya Matangazo",
        text_ar: "رمز الترويج",
      },
      "Payment method": {
        text_en: "Payment method",
        text_am: "የክፍያ መንገድ",
        text_fr: "Méthode de paiement",
        text_sw: "Njia ya Malipo",
        text_ar: "طريقة الدفع",
      },
      "List of Live Events": {
        text_en: "List of Live Events",
        text_am: "ቀጥታ ክስተቶችን ዝርዝር",
        text_fr: "Liste des événements en direct",
        text_sw: "Orodha ya Matukio Hai",
        text_ar: "قائمة الأحداث المباشرة",
      },
      "Live events can be filtered based on different parameters based on the user’s interest,":
        {
          text_en:
            "Live events can be filtered based on different parameters based on the user’s interest,",
          text_am:
            "ቀጥታ ክስተቶች በተለያዩ መለያዎች ላይ የተጠቀምለትን በተጠራው ስም የሚያገኙበት ቦታ ማሳየት ይችላሉ።",
          text_fr:
            "Les événements en direct peuvent être filtrés en fonction de différents paramètres en fonction de l'intérêt de l'utilisateur,",
          text_sw:
            "Matukio hai yanaweza kuchujwa kulingana na vigezo tofauti kulingana na maslahi ya mtumiaji,",
          text_ar:
            "يمكن تصفية الأحداث المباشرة بناءً على معايير مختلفة استنادًا إلى اهتمام المستخدم،",
        },
      "Click on LIVE nav item to get into live events list page.": {
        text_en: "Click on LIVE nav item to get into live events list page.",
        text_am: "ለቀጥታ ክስተቶች ዝርዝር ገጽላይ LIVE ን ጠቅ ያድርጉ።",
        text_fr:
          "Cliquez sur l'élément de navigation LIVE pour accéder à la page de liste des événements en direct.",
        text_sw:
          "Bonyeza kwenye kipengee cha urambazaji cha LIVE ili uingie kwenye ukurasa wa orodha ya matukio hai.",
        text_ar:
          "انقر على عنصر الملاحة LIVE للوصول إلى صفحة قائمة الأحداث المباشرة.",
      },
      "Then click on any event category icon under the list of the categories(on the left side of the events list)in the events page then filtered events list will be displayed. The default type of category is ALL.":
        {
          text_en:
            "Then click on any event category icon under the list of the categories(on the left side of the events list)in the events page then filtered events list will be displayed. The default type of category is ALL.",
          text_am:
            "በዚህ መግለጫ የክስተት አካባቢ ቅሬታ የሚለውን ሊስት ብርሃን የሚገኝ በተካሄደው የውስጥ ገጽ ላይ በሚለው አካባቢ የተጠቀምለትን ክስተቶች ዝርዝር ይሰጣል። አካባቢውን ስም የሚያይዝ አካባቢ በሰንበት ወይም በሌላ ክስተት ውስጥ ያሉ ክስተቶች ዝርዝሮች ይሰጣሉ። አካባቢውን ስም ማንበብ አካባቢ የተጠቀምለት ALL ነው።",
          text_fr:
            "Ensuite, cliquez sur une icône de catégorie d'événement dans la liste des catégories (sur le côtégauche de la liste des événements) sur la page des événements, puis la liste des événements filtrés s'affiche. Le type de catégorie par défaut est TOUS.",
          text_sw:
            "Kisha bofya aikoni ya kategoria yoyote ya tukio chini ya orodha ya kategoria (upande wa kushoto wa orodha ya matukio) katika ukurasa wa matukio kisha orodha ya matukio iliyochujwa itaonyeshwa. Aina chaguo-msingi ya kategoria ni YOTE.",
          text_ar:
            "ثم انقر على أي رمز لفئة الحدث تحت قائمة الفئات (على الجانب الأيسر من قائمة الأحداث) في صفحة الأحداث ، ثم سيتم عرض قائمة الأحداث المُصفاة. النوع الافتراضي للفئة هو جميع الفئات.",
        },
      "Search and filter Events": {
        text_en: "Search and filter Events",
        text_am: "ክስተቶችን ፈልግ እና ማስተካከያ ማጠቃለቂያ",
        text_fr: "Rechercher et filtrer les événements",
        text_sw: "Tafuta na Chuja Matukio",
        text_ar: "البحث وتصفية الأحداث",
      },
      "Events can be either searched or filtered by multiple parameters like category, date, vendor and price range.":
        {
          text_en:
            "Events can be either searched or filtered by multiple parameters like category, date, vendor and price range.",
          text_am:
            "ክስተቶችን በካርታውና ቀንድዎን፣ ሻንጣታውን፣ የገቢ ሰልተኛውን እና ዋጋ መጠን ማፈልግ ይችላሉ።",
          text_fr:
            "Les événements peuvent être recherchés ou filtrés par plusieurs paramètres tels que la catégorie, la date, le vendeur et la plage de prix.",
          text_sw:
            "Matukio yanaweza kutafutwa au kuchujwa kwa vigezo mbalimbali kama jamii, tarehe, muuzaji, na kiwango cha bei.",
          text_ar:
            "يمكن البحث عن الأحداث أو تصفيتها باستخدام معلمات متعددة مثل الفئة والتاريخ والبائع ونطاق السعر.",
        },
      "To search events go to the search bar at the top center of the page and enter the event you want to search and press FIND EVENT button then the events list will be displayed.":
        {
          text_en:
            "To search events go to the search bar at the top center of the page and enter the event you want to search and press FIND EVENT button then the events list will be displayed.",
          text_am:
            "ክስተቶችን ፈልግ፣ ከገጽ ከፍ ያለ በላይ ስም ይፈልጋሉ። እዚህ ገጽዎን ለማወቅ በግል በላይ ያሉትን ክስተቶችን እንዲጠቀሙ አቅርብ ፋይናል ከመጀመሪያ ይምረጡ። ከዚህም የክስተት ዝርዝር አድርግ ይበልጥ ፎቶን ይሙሉ ማለት ይችላሉ።",
          text_fr:
            "Pour rechercher des événements, accédez à la barre de recherche en haut centre de la page, saisissez l'événement que vous souhaitez rechercher, appuyez sur le bouton TROUVER L'ÉVÉNEMENT, puis la liste des événements sera affichée.",
          text_sw:
            "Ili kutafuta matukio, nenda kwenye kisanduku cha utaftaji katika sehemu ya juu ya kati ya ukurasa na ingiza tukio unalotaka kutafuta kisha bonyeza kitufe cha TAFUTA TUKIO kisha orodha ya matukio itaonyeshwa.",
          text_ar:
            "للبحث عن الأحداث ، انتقل إلى شريط البحث في أعلى منتصف الصفحة وأدخل الحدث الذي تريد البحث فيه واضغط على الزر FIND EVENT ثم سيتم عرض قائمة الأحداث.",
        },
      "To filter events click on the filter icon on the left side of the search bar, select the filtering parameters and press continue, then events list will be displayed.":
        {
          text_en:
            "To filter events click on the filter icon on the left side of the search bar, select the filtering parameters and press continue, then events list will be displayed.",
          text_am:
            "ክስተቶችን ማፍልግ ይህንን አካባቢ ማስገባት ይችላሉ። የማጥፋት ቀንድዎን ይምረጡ ፣ ከምርጫ ስትል ውስጥ ማፍልግ ይችላሉ።",
          text_fr:
            "Pour filtrer les événements, cliquez sur l'icône de filtre à gauche de la barre de recherche, sélectionnez les paramètres de filtrage et appuyez sur continuer, puis la liste des événements sera affichée.",
          text_sw:
            "Ili kuchuja matukio, bonyeza ikoni ya kichujio upande wa kushoto wa kisanduku cha utaftaji, chagua vigezo vya kuchuja na bonyeza endelea, kisha orodha ya matukio itaonyeshwa.",
          text_ar:
            "لتصفية الأحداث، انقر على أيقونة التصفية على الجانب الأيسر من شريط البحث، وحدد معلمات التصفية واضغط على متابعة، ثم سيتم عرض قائمة الأحداث.",
        },
      "By using these search and filtering options, you can easily find the events that match your interests and preferences. Enjoy exploring the live events!":
        {
          text_en:
            "By using these search and filtering options, you can easily find the events that match your interests and preferences. Enjoy exploring the live events!",
          text_am:
            "በዚህ ፍለልን ማስገባትና ማፍልግ አማርኛ መመልከቻዎን ይገባል። ለቀጥታ ክስተቶች ውስጥ መሳሪያዎችን በማክበር እንዴት እንደሚታይ መስጠት ይችላል። ስለዚህ ቀጥታ ክስተቶችን ይደርስባችኋል!",
          text_fr:
            "En utilisant ces options de recherche et de filtrage, vous pouvez facilement trouver les événements qui correspondent à vos intérêts et préférences. Profitez de l'exploration des événements en direct !",
          text_sw:
            "Kwa kutumia chaguo hizi za utaftaji na uchujaji, unaweza kwa urahisi kupata matukio yanayolingana na masilahi na mapendeleo yako. Furahia kuchunguza matukio yanayoendelea!",
          text_ar:
            "باستخدام خيارات البحث والتصفية هذه ، يمكنك بسهولة العثور على الأحداث التي تتوافق مع اهتماماتك وتفضيلاتك. استمتع باستكشاف الأحداث المباشرة!",
        },
      "Click on any event item and you will be redirected automatically to the events detail page.":
        {
          text_en:
            "Click on any event item and you will be redirected automatically to the events detail page.",
          text_am:
            "በዚህ ክስተት ንቁ አካባቢ የሚለውን ይጠቀሙበት እንደሚቻል ክስተት ዝርዝር ለመለየት ይህንን ገጽ ላይ ጥቅም ይላል።",
          text_fr:
            "Cliquez sur n'importe quel élément d'événement et vous serez automatiquement redirigé vers la page de détails des événements.",
          text_sw:
            "Bonyeza kwenye kipengee chochote cha tukio na utaelekezwa moja kwa moja kwenye ukurasa wa maelezo ya tukio.",
          text_ar:
            "انقر على أي عنصر حدث وسيتم توجيهك تلقائيًا إلى صفحة تفاصيل الأحداث.",
        },
      "Purchase Tickets": {
        text_en: "Purchase Tickets",
        text_am: "መግዛት የትኬት ማስተካከያ",
        text_fr: "Acheter des billets",
        text_sw: "Nunua Tiketi",
        text_ar: "شراء التذاكر",
      },
      "To purchase tickets for an event,": {
        text_en: "To purchase tickets for an event,",
        text_am: "ክስተትን ለመግዛት የትኬት ማስተካከያ ይህንን ገጽ ላይ ይጠቀሙበት ።",
        text_fr: "Pour acheter des billets pour un événement,",
        text_sw: "Kununua tiketi kwa tukio,",
        text_ar: "لشراء التذاكر لحدث ما،",
      },
      "Go to events detail page and select among the types of tickets.": {
        text_en:
          "Go to events detail page and select among the types of tickets.",
        text_am: "የክስተት ዝርዝር ገጽ ይለውጡ፣ በትኬቶች ዓይነት ከመምረጥ ይጠቀሙበታል።",
        text_fr:
          "Accédez à la page de détails des événements et sélectionnez parmi les types de billets.",
        text_sw:
          "Nenda kwenye ukurasa wa maelezo ya tukio na chagua kati ya aina za tiketi.",
        text_ar: "انتقل إلى صفحة تفاصيل الحدث واختر من بين أنواع التذاكر.",
      },
      "Then enter the required parameters for purchasing ticket to an event and click NEXT button.":
        {
          text_en:
            "Then enter the required parameters for purchasing ticket to an event and click NEXT button.",
          text_am:
            "በዚህ ክስተት የትኬት ማስተካከያ መግዛት የሚፈልጉትን አማራጭ መለያዎችን ያስገቡ፣ NEXT አድርጉ።",
          text_fr:
            "Ensuite, saisissez les paramètres requis pour l'achat d'un billet pour un événement et cliquez sur le bouton SUIVANT.",
          text_sw:
            "Kisha ingiza parameta zinazohitajika kwa ununuzi wa tiketi kwa tukio na bonyeza kitufe cha NEXT.",
          text_ar:
            "ثم أدخل المعلمات المطلوبة لشراء تذكرة لحدث ما وانقر على زر التالي.",
        },
      "Finally the order details will be displayed and click on PLACE ORDER button to place your order or CANCEL to cancel the order.":
        {
          text_en:
            "Finally the order details will be displayed and click on PLACE ORDER button to place your order or CANCEL to cancel the order.",
          text_am:
            "የትኬት ማስተካከያ ውስጥ የተሰጠውየሚገጥም ማሳያ ላይ ያስገቡ፣ ወይም ይህንን ገጽ ይሰረዛል። በዚህ ገጽ ላይ PLACE ORDER የሚለውን ማሳያ ያስገቡ፣ ለማሰብ፣ CANCEL የሚለውን ማሳያ ያስገቡ።",
          text_fr:
            "Enfin, les détails de la commande seront affichés et cliquez sur le bouton PASSER LA COMMANDE pour passer votre commande ou ANNULER pour annuler la commande.",
          text_sw:
            "Hatimaye maelezo ya agizo yataonyeshwa na bonyeza kitufe cha WEKA ODA kuweka agizo lako au BATALI kufuta agizo.",
          text_ar:
            "أخيرًا، سيتم عرض تفاصيل الطلب وانقر على زر PLACE ORDER لتقديم طلبك أو CANCEL لإلغاء الطلب.",
        },
    },

    "New Events": {
      "New Events": {
        text_en: "New Events",
        text_am: "አዲስ ክስተቶች",
        text_fr: "Nouveaux événements",
        text_sw: "Matukio Mapya",
        text_ar: "أحداث جديدة",
      },
      "This page displays a list of new events filtered by multiple categories and types.":
        {
          text_en:
            "This page displays a list of new events filtered by multiple categories and types.",
          text_am:
            "ይህ ገጽ በተሻሻለ የተለያዩ ክስተቶችን ማግኛት የሚችሉ በችግርና ዓይነት የሚለው ተዘጋጅቶ ማስተካከያውን ይሰጣል።",
          text_fr:
            "Cette page affiche une liste de nouveaux événements filtrés par plusieurs catégories et types.",
          text_sw:
            "Ukurasa huu unaonyesha orodha ya matukio mapya yaliyofunguliwa kwa kuzingatia makundi na aina mbalimbali.",
          text_ar:
            "هذه الصفحة تعرض قائمة من الأحداث الجديدة المصفاة حسب الفئات والأنواع المتعددة.",
        },
      "Displays list of new events filtered by many categories .": {
        text_en: "Displays a list of new events filtered by many categories.",
        text_am:
          "በተለያዩ ክስተቶች ያግኙት ከዛም የተዘጋጀው ተዘጋጅቶ የሚለውን ክስተት ዝርዝር ማስተካከያ ይሰጣል።",
        text_fr:
          "Affiche une liste de nouveaux événements filtrés par plusieurs catégories.",
        text_sw:
          "Inaonyesha orodha ya matukio mapya yaliyofunguliwa kwa kuzingatia makundi mengi.",
        text_ar:
          "تعرض قائمة من الأحداث الجديدة المصفاة بواسطة العديد من الفئات.",
      },
      "New events can be searched and filtered by their date, category, vendor, and price range.":
        {
          text_en:
            "New events can be searched and filtered by their date, category, vendor, and price range.",
          text_am:
            "ነዚህ የተለያዩ ክስተቶች በቀንድና በምድባቸው፣ በሰርቨርና በዋጋ ውስጥ ሊፈልጉ እና ሊጣሩ ይችላል።",
          text_fr:
            "Les nouveaux événements peuvent être recherchés et filtrés par leur date, leur catégorie, leur vendeur et leur plage de prix.",
          text_sw:
            "Matukio mapya yanaweza kutafutwa na kuchujwa kulingana na tarehe yao, kategoria, muuzaji, na kiwango cha bei.",
          text_ar:
            "يمكن البحث عن الأحداث الجديدة وتصفيتها حسب تاريخها وفئتها والبائع ونطاق الأسعار.",
        },
      "View event details and purchase tickets.": {
        text_en: "View event details and purchase tickets.",
        text_am: "ክስተትን ዝርዝር ማስተካከያውን ይመልከቱ፣ ትኬቶችን ይግዛብሩ።",
        text_fr: "Afficher les détails de l'événement et acheter des billets.",
        text_sw: "Angalia maelezo ya tukio na nunua tiketi.",
        text_ar: "عرض تفاصيل الحدث وشراء التذاكر.",
      },
      "Parameters to purchase tickets": {
        text_en: "Parameters to purchase tickets",
        text_am: "ለትኬት ታክስ የሚለው መለያዎች",
        text_fr: "Paramètres pour acheter des billets",
        text_sw: "Parameta za kununua tiketi",
        text_ar: "معلمات شراء التذاكر",
      },
      "Full Name": {
        text_en: "Full Name",
        text_am: "ሙሉ ስም",
        text_fr: "Nom complet",
        text_sw: "Jina Kamili",
        text_ar: "الاسم الكامل",
      },
      "Email Address": {
        text_en: "Email Address",
        text_am: "ኢሜል አድራሻ",
        text_fr: "Adresse e-mail",
        text_sw: "Anwani ya Barua pepe",
        text_ar: "عنوان البريد الإلكتروني",
      },
      "Phone Number": {
        text_en: "Phone Number",
        text_am: "ስልክ ቁጥር",
        text_fr: "Numéro de téléphone",
        text_sw: "Nambari ya Simu",
        text_ar: "رقم الهاتف",
      },
      Quantity: {
        text_en: "Quantity",
        text_am: "ብዛት",
        text_fr: "Quantité",
        text_sw: "Kiasi",
        text_ar: "الكمية",
      },
      "Promo Code": {
        text_en: "Promo Code",
        text_am: "ፕሮምን ኮድ",
        text_fr: "Code promotionnel",
        text_sw: "Nambari ya Matangazo",
        text_ar: "كود العرض الترويجي",
      },
      "Payment method": {
        text_en: "Payment method",
        text_am: "የክፍያ ዘዴ",
        text_fr: "Méthode de paiement",
        text_sw: "Njia ya Malipo",
        text_ar: "طريقة الدفع",
      },
      "List of new events": {
        text_en: "List of new events",
        text_am: "የአዲስ ክስተቶችን ዝርዝር",
        text_fr: "Liste des nouveaux événements",
        text_sw: "Orodha ya matukio mapya",
        text_ar: "قائمة الأحداث الجديدة",
      },
      "New events can be filtered based on different parameters depending on the user's interest.":
        {
          text_en:
            "New events can be filtered based on different parameters depending on the user's interest.",
          text_am:
            "አዲስ ክስተቶች ከተሻሻለ የሚችሉ በችግርና በሰርቨር ከዛ ያግኙት የተለያዩን የአገልግሎት መለያዎች መዝግበህ በማሻሻል ይሰጣል።",
          text_fr:
            "Les nouveaux événements peuvent être filtrés en fonction de différents paramètres en fonction de l'intérêt de l'utilisateur.",
          text_sw:
            "Matukio mapya yanaweza kuchujwa kulingana na vigezo tofauti kulingana na maslahi ya mtumiaji.",
          text_ar:
            "يمكن تصفية الأحداث الجديدة بناءً على معايير مختلفة تعتمد على اهتمام المستخدم.",
        },
      "Click on the 'NEW' navigation item to access the new events page.": {
        text_en:
          "Click on the 'NEW' navigation item to access the new events page.",
        text_am: "'ነው' አማራጭ እንዲሁም የአዲስ ክስተቶች ገጽዎን ማግኘት ይችላል።",
        text_fr:
          "Cliquez sur l'élément de navigation 'NOUVEAU' pour accéder à la page des nouveaux événements.",
        text_sw:
          "Bonyeza kwenye kipengele cha urambazaji 'MPYA' ili ufikie ukurasa wa matukio mapya.",
        text_ar: "انقر على عنصر الملاحة 'NEW' للوصول إلى صفحة الأحداث الجديدة.",
      },
      "Then click on any type listed under the categories (on the left side of the events list) on the events page to display the filtered events list. The default category type is 'ALL'.":
        {
          text_en:
            "Then click on any type listed under the categories (on the left side of the events list) on the events page to display the filtered events list. The default category type is 'ALL'.",
          text_am:
            "ከዛም የተዘጋጅቶ የሚለውን የምድርና የዓይነት የሚደረግ ዓይነትን (በየትኛው ወረቀት ወይም በሌላ በሽግር ያሉትን) ወደ የክስተት ገጽ ማምጣት ይችላሉ። ክስተት የተዘጋጁት አይነት በ'ሁሉም' መሆን ነው።",
          text_fr:
            "Ensuite, cliquez sur n'importe quel type répertorié sous les catégories (sur le côté gauche de la liste des événements) sur la page des événements pour afficher la liste des événements filtrés. Le type de catégorie par défaut est 'TOUS'.",
          text_sw:
            "Kisha bonyeza kwenye aina yoyote iliyoorodheshwa chini ya makundi (upande wa kushoto wa orodha ya matukio) kwenye ukurasa wa matukio ili kuonyesha orodha iliyochujwa ya matukio. Aina ya kategoria ya chaguo-msingi ni 'YOTE'.",

          text_ar:
            "ثم انقر على أي نوع مدرج تحت الفئات (على الجانب الأيسر من قائمة الأحداث) على صفحة الأحداث لعرض قائمة الأحداث المُنقاة. نوع الفئة الافتراضي هو 'الكل'.",
        },
      "Please fill out the form below to submit your payment.": {
        text_en: "Please fill out the form below to submit your payment.",
        text_am:
          "እባክዎ የትክክል ማስገባትን ይመልከቱ። ከታች ያለው ቅርጸት በመሆን ጊዜ ለመክፈል ከታች የተሰራውን ቅርጸት ይጫኑ።",
        text_fr:
          "Veuillez remplir le formulaire ci-dessous pour soumettre votre paiement.",
        text_sw: "Tafadhali jaza fomu hapa chini ili kuwasilisha malipo yako.",
        text_ar: "يرجى ملء النموذج أدناه لتقديم الدفع الخاص بك.",
      },
      Submit: {
        text_en: "Submit",
        text_am: "ይላኩ",
        text_fr: "Soumettre",
        text_sw: "Wasilisha",
        text_ar: "إرسال",
      },
      "Thank you for your payment!": {
        text_en: "Thank you for your payment!",
        text_am: "እናመሰግናለን ብሎ ተሳክቷል!",
        text_fr: "Merci pour votre paiement!",
        text_sw: "Asante kwa malipo yako!",
        text_ar: "شكرا لدفعتك!",
      },
      "Search and filter Events": {
        text_en: "Search and filter events",
        text_am: "ክስተቶችን ፍለጋግን እና አጠቃላይ መግለጫ",
        text_fr: "Rechercher et filtrer les événements",
        text_sw: "Tafuta na chuja matukio",
        text_ar: "ابحث وصفِّ لتصفية الأحداث",
      },
      "New events can be either searched or filtered by multiple parameters like category, date, vendor, and price range.":
        {
          text_en:
            "New events can be searched or filtered based on multiple parameters such as category, date, vendor, and price range.",
          text_am:
            "አዲስ ክስተቶች ከምስል ጋር ወደዚህ በመፍለግ ከተሻሻለው ክስተት፣ ቀን፣ ሰርቨርና ዋጋ መግለጫውን መጠቀም ይችላሉ።",
          text_fr:
            "Les nouveaux événements peuvent être recherchés ou filtrés en fonction de plusieurs paramètres tels que la catégorie, la date, le fournisseur et la fourchette de prix.",
          text_sw:
            "Matukio mapya yanaweza kutafutwa au kuchujwa kulingana na vigezo mbalimbali kama vile jamii, tarehe, muuzaji, na kikomo cha bei.",
          text_ar:
            "يمكن البحث عن الأحداث الجديدة أو تصفيتها بواسطة معايير متعددة مثل الفئة، التاريخ، البائع، ونطاق السعر.",
        },
      "To search events go to the search bar at the top center of the page and enter the event you want to search and press FIND EVENT button then the events list will be displayed.":
        {
          text_en:
            "To search for events, go to the search bar located at the top center of the page. Enter the event you want to search for and press the 'FIND EVENT' button. The events list will then be displayed.",
          text_am:
            "ክስተቶችን ፈልጉ ለማድረግ፣ ገጽዎን ከመስማማት ላይ ያስገቡት። የሚያገለግሉትን ክስተት ያስገቡት መጠቀም ማለት ይችላሉ።",
          text_fr:
            "Pour rechercher des événements, accédez à la barre de recherche située en haut au centre de la page. Entrez l'événement que vous souhaitez rechercher et appuyez sur le bouton 'TROUVER UN ÉVÉNEMENT'. La liste des événements sera alors affichée.",
          text_sw:
            "Kutafuta matukio, nenda kwenye mstari wa utafutaji ulioko katikati ya juu ya ukurasa na ingiza tukio unalotaka kutafuta, kisha bonyeza kitufe cha 'TAFUTA TUKIO', kisha orodha ya matukio itaonyeshwa.",
          text_ar:
            "للبحث عن الفعاليات، اذهب إلى شريط البحث الموجود في الأعلى وسط الصفحة وأدخل الفعالية التي تريد البحث عنها واضغط زر 'البحث عن الفعالية' ثم سيتم عرض قائمة الفعاليات.",
        },
      "To filter events click on the filter icon on the left side of the search bar, select the filtering parameters, and press continue. Then the events list will be displayed.":
        {
          text_en:
            "To filter events, click on the filter icon located on the left side of the search bar. Select the filtering parameters and click 'Continue'. The events list will then be displayed.",
          text_am:
            "ክስተቶችን ማጥፊያ ያገለግሉት ከመስማማት ላይ ያስገቡት። የማጥፊያ መለያዎችን ይምረጡ፣ በ'ቀጣይ' ይጠቀሙ። ከዚህ በፊት የክስተት ዝርዝር ያስገቡት መጠቀም ማለት ይችላሉ።",
          text_fr:
            "Pour filtrer les événements, cliquez sur l'icône de filtre située sur le côté gauche de la barre de recherche. Sélectionnez les paramètres de filtrage et cliquez sur 'Continuer'. La liste des événements sera alors affichée.",
          text_sw:
            "Kuweka chujio kwenye matukio, bonyeza alama ya chujio iliyoko upande wa kushoto wa mstari wa utafutaji, chagua vigezo vya kuchuja, na bonyeza 'Endelea'. Kisha orodha ya matukio itaonyeshwa.",
          text_ar:
            "لتصفية الأحداث، انقر على أيقونة التصفية الموجودة على الجانب الأيسر من شريط البحث. حدد معلمات التصفية وانقر على 'استمرار'. سيتم عرض قائمة الأحداث بعد ذلك.",
        },
      "View Event Details": {
        text_en: "View event details",
        text_am: "ክስተትን ዝርዝር ማስተካከያ",
        text_fr: "Afficher les détails de l'événement",
        text_sw: "Angalia Maelezo ya Tukio",
        text_ar: "عرض تفاصيل الحدث",
      },
      "To display detailed information about an event, click on any event item, and you will be redirected automatically to the event's detail page.":
        {
          text_en:
            "To view detailed information about an event, click on any event item, and you will be automatically redirected to the event's detail page.",
          text_am:
            "ክስተት የሚመልከት ዝርዝርን ያሳውቁ ከምስል ጋር ይገኛል፣ ከቀንድ ወደ ክስተት ዝርዝርዎ የሚቀርብ ትርጉም ያለውን የክስተት ዝርዝር ገጽ ይመልከቱ።",
          text_fr:
            "Pour afficher des informations détaillées sur un événement, cliquez sur n'importe quel élément de l'événement et vous serez automatiquement redirigé vers la page de détails de l'événement.",
          text_sw:
            "Kuonyesha habari za kina kuhusu tukio, bonyeza kwenye kipengee chochote cha tukio, na utaelekezwa moja kwa moja kwenye ukurasa wa undani wa tukio.",
          text_ar:
            "لعرض معلومات مفصلة عن حدث، انقر على أي عنصر حدث وسيتم توجيهك تلقائيًا إلى صفحة تفاصيل الحدث.",
        },
      "Purchase Tickets": {
        text_en: "Purchase tickets",
        text_am: "ታክስ ይግዙ",
        text_fr: "Acheter des billets",
        text_sw: "Nunua Tiketi",
        text_ar: "شراء التذاكر",
      },
      "To purchase tickets for an event, go to the event's detail page and select among the available ticket types. Then enter the required parameters for purchasing a ticket and click the 'NEXT' button. Finally, the order details will be displayed. Click on the 'PLACE ORDER' button to place your order or 'CANCEL' to cancel the order.":
        {
          text_en:
            "To purchase tickets for an event, follow these steps:\n\n1. Go to the event's detail page.\n2. Select the desired ticket type from the available options.\n3. Enter the required parameters, such as full name, email address, phone number, quantity, promo code, and payment method.\n4. Click the 'NEXT' button.\n5. Review the order details displayed.\n6. Click the 'PLACE ORDER' button to place your order or 'CANCEL' to cancel the order.",
          text_am:
            "በክስተት የታክስ ዝርዝርን ለማግኘት በእርስዎ የተሻሻለውን ደቂቃ ገጽ ላይ በመስማማት ላይ ይግዙ። በተሻሻለ ምስል ለማስገባት የተሳሳተ ክስተት አካልን ይምረጡ፣ ማለትም በ'ቀጣይ' ይጠቀሙ። እንደገና የተመለከተውን ታክስ ዝርዝር መጠቀም ይችላሉ። በ'አጥፊው ቀጥታ' አድርጉ፣ ወይም 'ተዘጋጅ' አልክዎ ተመልከቱ።",
          text_fr:
            "Pour acheter des billets pour un événement, suivez ces étapes :\n\n1. Rendez-vous sur la page de détails de l'événement.\n2. Sélectionnez le type de billet souhaité parmi les options disponibles.\n3. Saisissez les paramètres requis, tels que le nom complet, l'adresse e-mail, le numéro de téléphone, la quantité, le code promo et le mode de paiement.\n4. Cliquez sur le bouton 'SUIVANT'.\n5. Vérifiez les détails de la commande affichés.\n6. Cliquez sur le bouton 'PASSER LA COMMANDE' pour passer votre commande ou sur 'ANNULER' pour annuler la commande.",
          text_sw:
            "Kununua tiketi kwa ajili ya tukio, fuata hatua hizi:\n\n1. Nenda kwenye ukurasa wa undani wa tukio.\n2. Chagua aina ya tiketi unayotaka kutoka kwenye chaguo zilizopo.\n3. Ingiza vigezo muhimu, kama jina kamili, anwani ya barua pepe, namba ya simu, kiasi, nambariya matangazo, na njia ya malipo.\n4. Bonyeza kitufe cha 'NEXT'.\n5. Angalia maelezo ya agizo yaliyoonyeshwa.\n6. Bonyeza kitufe cha 'WEKA AGIZO' ili kuweka agizo lako au 'GHAIRI' ili kughairi agizo.",
          text_ar:
            "لشراء التذاكر لحدث ما، اتبع هذه الخطوات:\n\n١. انتقل إلى صفحة تفاصيل الحدث.\n٢. حدد نوع التذكرة المطلوب من بين الخيارات المتاحة.\n٣. أدخل المعلمات المطلوبة، مثل الاسم الكامل، عنوان البريد الإلكتروني، رقم الهاتف، الكمية، رمز الترويج، وطريقة الدفع.\n٤. انقر على زر 'NEXT'.\n٥. استعرض تفاصيل الطلب المعروضة.\n٦. انقر على زر 'PLACE ORDER' لتقديم طلبك أو على 'CANCEL' لإلغاء الطلب.",
        },
    },

    Organizer: {
      Organizer: {
        text_en: "Organizer",
        text_am: "አስተዳዳሪ",
        text_fr: "Organisateur",
        text_sw: "Msimamizi",
        text_ar: "المنظم",
      },
      "This page displays information related to organizers like features provided by OwlEvents and subscription plans to organizers.":
        {
          text_en:
            "This page displays information related to organizers like features provided by OwlEvents and subscription plans to organizers.",
          text_am:
            "ይህ ገጽ ለአስተዳዳሪዎች የOwlEvents ተጠቃሚዎችን እና የአስተዳዳሪዎችን መመዝገብ የሚፈልጉትን መረጃዎችን ይመልከቱ።",
          text_fr:
            "Cette page affiche des informations relatives aux organisateurs, telles que les fonctionnalités fournies par OwlEvents et les plans d'abonnement aux organisateurs.",
          text_sw:
            "Ukurasa huu unaonyesha habari zinazohusiana na waandaaji kama vile huduma zinazotolewa na OwlEvents na mipango ya kujiandikisha kwa waandaaji.",
          text_ar:
            "تعرض هذه الصفحة معلومات تتعلق بالمنظمين مثل الميزات المقدمة من قبل OwlEvents وخطط الاشتراك للمنظمين.",
        },
      Features: {
        text_en: "Features",
        text_am: "ስራዎች",
        text_fr: "Fonctionnalités",
        text_sw: "Vipengele",
        text_ar: "الميزات",
      },
      "Displays Subscription Plans": {
        text_en: "Displays Subscription Plans",
        text_am: "የመመዝገብ ስራዎችን ይመልከቱ",
        text_fr: "Affiche les plans d'abonnement",
        text_sw: "Inaonyesha Mipango ya Usajili",
        text_ar: "عرض خطط الاشتراك",
      },
      "Organizers Registration based on their plan selection": {
        text_en: "Organizers Registration based on their plan selection",
        text_am: "አስተዳዳሪዎች መመዝገብ በማስተላለፍ የተለያዩ ስራዎችን መመልከት",
        text_fr:
          "Enregistrement des organisateurs basé sur leur sélection de plan",
        text_sw: "Usajili wa Waandaaji kulingana na chaguo lao la mpango",
        text_ar: "تسجيل المنظمين بناءً على اختيارهم للخطة",
      },
      "Parameters to register organizer": {
        text_en: "Parameters to register organizer",
        text_am: "የአስተዳዳሪዎች መለያ መረጃዎች",
        text_fr: "Paramètres pour enregistrer l'organisateur",
        text_sw: "Vigezo vya kusajili msimamizi",
        text_ar: "معايير تسجيل المنظم",
      },
      "First name": {
        text_en: "First name",
        text_am: "ስም",
        text_fr: "Prénom",
        text_sw: "Jina la kwanza",
        text_ar: "الاسم الأول",
      },
      "Last name": {
        text_en: "Last name",
        text_am: "የአያት ስም",
        text_fr: "Nom de famille",
        text_sw: "Jina la mwisho",
        text_ar: "الاسم الأخير",
      },
      Username: {
        text_en: "Username",
        text_am: "የመጠቀሚያ ስም",
        text_fr: "Nom d'utilisateur",
        text_sw: "Jina la mtumiaji",
        text_ar: "اسم المستخدم",
      },
      "Email address": {
        text_en: "Email address",
        text_am: "ኢሜይል አድራሻ",
        text_fr: "Adresse e-mail",
        text_sw: "Anwani ya barua pepe",
        text_ar: "عنوان البريد الإلكتروني",
      },
      Bank: {
        text_en: "Bank",
        text_am: "ባንክ",
        text_fr: "Banque",
        text_sw: "Benki",
        text_ar: "البنك",
      },
      "Bank book number": {
        text_en: "Bank book number",
        text_am: "የባንክ መለያ ቁጥር",
        text_fr: "Numéro du carnet de banque",
        text_sw: "Namba ya kitabu cha benki",
        text_ar: "رقم كتاب البنك",
      },
      "Company Name": {
        text_en: "Company Name",
        text_am: "የድርጅት ስም",
        text_fr: "Nom de l'entreprise",
        text_sw: "Jina la Kampuni",
        text_ar: "اسم الشركة",
      },
      "Company Email": {
        text_en: "Company Email",
        text_am: "የድርጅት ኢሜይል",
        text_fr: "Email de l'entreprise",
        text_sw: "Barua pepe ya Kampuni",
        text_ar: "البريد الإلكتروني للشركة",
      },
      "Company phone": {
        text_en: "Company phone",
        text_am: "የድርጅት ስልክ",
        text_fr: "Téléphone de l'entreprise",
        text_sw: "Simu ya Kampuni",
        text_ar: "هاتف الشركة",
      },
      "Company TIN number": {
        text_en: "Company TIN number",
        text_am: "የድርጅት TIN ቁጥር",
        text_fr: "Numéro TIN de l'entreprise",
        text_sw: "Namba ya TIN ya Kampuni",
        text_ar: "رقم TIN للشركة",
      },
      Password: {
        text_en: "Password",
        text_am: "የይለፍ ቃል",
        text_fr: "Mot de passe",
        text_sw: "Nenosiri",
        text_ar: "كلمة المرور",
      },
      "Confirm Password": {
        text_en: "Confirm Password",
        text_am: "የይለፍ ቃል አረጋግጥ",
        text_fr: "Confirmer le mot de passe",
        text_sw: "Thibitisha Nenosiri",
        text_ar: "تأكيد كلمة المرور",
      },
      "Company LOGO": {
        text_en: "Company LOGO",
        text_am: "የድርጅት LOGO",
        text_fr: "Logo de l'entreprise",
        text_sw: "Logo ya Kampuni",
        text_ar: "شعار الشركة",
      },
      "Attach business license file": {
        text_en: "Attach business license file",
        text_ar: "إرفاق ملف ترخيص الأعمال التجارية",
        text_am: "የንግድ ማረጋገጫ ፋይል በማስገባት",
        text_fr: "Joindre le fichier de licence commerciale",
        text_sw: "Ambatisha faili la leseni ya biashara",
      },
      "Select Plan and Register": {
        text_en: "Select Plan and Register",
        text_ar: "اختر الخطة وسجل",
        text_am: "ስህተት ይምረጡና መመዝገብ",
        text_fr: "Sélectionner un plan et s'inscrire",
        text_sw: "Chagua mpango na Jisajili",
      },
      "To select a plan and register as an event organizer,": {
        text_en: "To select a plan and register as an event organizer,",
        text_ar: "لتحديد الخطة والتسجيل كمنظم فعالية،",
        text_am: "አስተዳዳሪ የሆነውን ስህተት ይምረጡና ማስተላለፍ",
        text_fr:
          "Pour sélectionner un plan et s'inscrire en tant qu'organisateur d'événements,",
        text_sw: "Kuchagua mpango na kujiandikisha kama msimamizi wa tukio,",
      },
      "Click on ORGANIZER nav item on top navigation bar of the page": {
        text_en:
          "Click on ORGANIZER nav item on top navigation bar of the page",
        text_ar:
          "انقر على عنصر الملاحة ORGANIZER في شريط الملاحة العلوي للصفحة",
        text_am: "የገጽ መሰረታዊ ባለጥልብ ማስተላለፍ ለORGANIZER ይጫኑ",
        text_fr:
          "Cliquez sur l'élément de navigation ORGANIZER dans la barre de navigation supérieure de la page",
        text_sw:
          "Bonyeza kwenye kipengee cha urambazaji cha ORGANIZER kwenye mstari wa urambazaji wa juu wa ukurasa",
      },
      "Then click on SELECT button to select a plan and it redirects to organizers registration page.":
        {
          text_en:
            "Then click on SELECT button to select a plan and it redirects to organizers registration page.",
          text_ar:
            "ثم انقر فوق زر الاختيار لتحديد الخطة ويتم إعادة توجيهك إلى صفحة تسجيل المنظمين.",
          text_am:
            "ለስህተቱ ይምረጡና SELECT አዝራርያን ይጫኑና ወደ አስተዳዳሪዎች መመዝገብ ገብተው ይመጣል።",
          text_fr:
            "Cliquez ensuite sur le bouton SELECT pour sélectionner un plan et il vous redirigera vers la page d'inscription des organisateurs.",
          text_sw:
            "Kisha bonyeza kitufe cha CHAGUA kuchagua mpango na itakupeleka kwenye ukurasa wa usajili wa watoa huduma.",
        },
      "Finally enter the parameters required to organizers registration page fields and enter the CONTINUE button to request for registration.":
        {
          text_en:
            "Finally enter the parameters required to organizers registration page fields and enter the CONTINUE button to request for registration.",
          text_ar:
            "أدخل أخيرًا المعلمات المطلوبة في حقول صفحة تسجيل المنظمين واضغط على زر CONTINUE لطلب التسجيل.",
          text_am:
            "የአስተዳዳሪዎች መለያ መረጃዎችን የሚያስፈልጉትን የተለያዩ ፓስተርስ ይጫኑና CONTINUE አዝራርያን ይላኩን እዚህ ላይ መላክ ይፈልጋል።",
          text_fr:
            "Enfin, saisissez les paramètres requis dans les champs de la page d'inscription des organisateurs et cliquez sur le bouton CONTINUE pour demander l'inscription.",
          text_sw:
            "Hatimaye ingiza vigezo vinavyohitajika kwenye uwanja wa ukurasa wa usajili wa watoa huduma na bonyeza kitufe cha ENDELEA kuomba usajili.",
        },
    },

    "Subscription Plans": {
      "Subscription Plans": {
        text_en: "Subscription Plans",
        text_am: "የደንበኝነት ምዝገባ ዕቅዶች",
        text_fr: "Forfaits d'abonnement",
        text_sw: "Mipango ya Usajili",
        text_ar: "خطط الاشتراك",
      },
      "List of packages included in subscription plans.": {
        text_en: "List of packages included in subscription plans.",
        text_am: "የመመዝገቦች ዝርዝር እውቅና የመመዝገቦች ዝርዝር አስተካክል",
        text_fr: "Liste des packages inclus dans les plans d'abonnement.",
        text_sw: "Orodha ya vifurushi vilivyomo kwenye mipango ya usajili.",
        text_ar: "قائمة الباقات المتضمنة في خطط الاشتراك.",
      },
      "List of subscription plans": {
        text_en: "List of subscription plans",
        text_am: "የመመዝገቦች ዝርዝር",
        text_fr: "Liste des plans d'abonnement",
        text_sw: "Orodha ya mipango ya usajili",
        text_ar: "قائمة خطط الاشتراك",
      },
      "To get into subscription plans page,": {
        text_en: "To get into subscription plans page,",
        text_am: "ለመመዝገቦች ዝርዝር ገጽ ይጫኑ",
        text_fr: "Pour accéder à la page des plans d'abonnement,",
        text_sw: "Ili kuingia ukurasa wa mipango ya usajili,",
        text_ar: "للدخول إلى صفحة خطط الاشتراك،",
      },
      "Click on the SUBSCRIPTION PLAN nav item on top navigation bar.": {
        text_en:
          "Click on the SUBSCRIPTION PLAN nav item on top navigation bar.",
        text_am: "ለምሳሌ የሚመልከቱ ምስል SUBSCRIPTION PLAN አምራች አዝራርያን ይጫኑ",
        text_fr:
          "Cliquez sur l'élément de navigation PLAN D'ABONNEMENT dans la barre de navigation supérieure.",
        text_sw:
          "Bonyeza kwenye kipengee cha urambazaji SUBSCRIPTIONPLAN kwenye mstari wa urambazaji wa juu.",
        text_ar: "انقر فوق عنصر الملاحة PLAN الاشتراك في شريط الملاحة العلوي.",
      },
      "Then List of subscription plans and packages will be displayed.": {
        text_en:
          "Then List of subscription plans and packages will be displayed.",
        text_am: "ከዚያም የመመዝገቦች ዝርዝርና መለያዎች ማስተላለፍ ይሻላል",
        text_fr:
          "Ensuite, la liste des plans d'abonnement et des packages sera affichée.",
        text_sw: "Kisha Orodha ya mipango ya usajili na vifurushi itaonyeshwa.",
        text_ar: "سيتم عرض قائمة خطط الاشتراك والباقات.",
      },
      "Types of Subscription Plans": {
        text_en: "Types of Subscription Plans",
        text_am: "የመመዝገቦች ዝርዝር አይነትዎች",
        text_fr: "Types de plans d'abonnement",
        text_sw: "Aina za mipango ya usajili",
        text_ar: "أنواع خطط الاشتراك",
      },
      Free: {
        text_en: "Free",
        text_am: "ባንክ",
        text_fr: "Gratuit",
        text_sw: "Bure",
        text_ar: "مجاني",
      },
      Standard: {
        text_en: "Standard",
        text_am: "ምሳሌ",
        text_fr: "Standard",
        text_sw: "Kawaida",
        text_ar: "قياسي",
      },
      Corporate: {
        text_en: "Corporate",
        text_am: "ኮርፖሬት",
        text_fr: "Entreprise",
        text_sw: "Kampuni",
        text_ar: "شركة",
      },
      Premium: {
        text_en: "Premium",
        text_am: "ፕሪሚየም",
        text_fr: "Premium",
        text_sw: "Premium",
        text_ar: "متميز",
      },
    },
  },
  FAQ: {
    "Frequently Asked Questions": {
      text_en: "Frequently Asked Questions",
      text_am: "በጥቂት ስለዚህ ጥያቄዎች",
      text_ar: "الأسئلة المتكررة",
      text_fr: "Questions fréquemment posées",
      text_sw: "Maswali Yanayoulizwa Mara kwa Mara",
    },
    "Can I change my package": {
      text_en: "Can I change my package",
      text_am: "መቀየር እንዴት ይችላሉ",
      text_ar: "هل يمكنني تغيير الباقة الخاصة بي",
      text_fr: "Puis-je changer mon forfait",
      text_sw: "Je, naweza kubadilisha kifurushi changu",
    },
    "Yes! If you're interested in a custom package, contact our Sales Partnerships Team. Any changes will apply only to events created after you make the change.":
      {
        text_en:
          "Yes! If you're interested in a custom package, contact our Sales Partnerships Team. Any changes will apply only to events created after you make the change.",
        text_am:
          "እዚህ! የቅንጅቶችዎን ማጥቃት ያድርጉት፣ እንዴትም ለእኛ የሚወጡት ጥያቄዎች ለውጥ እንዳቀረቡት ይግዙ። በመቀጠል የተሰጠው መቀጠል ስለሚሰጥ ተጠቃሚው ላቀረቡት ምሳሌ አግኝቷል።",
        text_ar:
          "نعم! إذا كنت مهتمًا بحزمة مخصصة، يرجى التواصل مع فريق شراكات المبيعات الخاص بنا. ستنطبق أي تغييرات فقط على الأحداث التي تم إنشاؤها بعد إجراء التغيير.",
        text_fr:
          "Oui ! Si vous êtes intéressé par un forfait personnalisé, veuillez contacter notre équipe de partenariats commerciaux. Toutes les modifications ne s'appliqueront qu'aux événements créés après avoir effectué le changement.",
        text_sw:
          "Ndiyo! Ikiwa una nia ya kifurushi cha kipekee, tafadhali wasiliana na Timu yetu ya Ushirikiano wa Uuzaji. Mabadiliko yoyote yatahusu tu matukio yaliyoundwa baada ya kufanya mabadiliko.",
      },
  },
};
