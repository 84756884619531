import { Box } from "@mui/material";
import { colors } from "constants/themeConstants";
const { styled } = require("@mui/system");

export const StyledService = styled(Box)(({ theme }) => ({
  "&:hover": {
    transform: "scale(1.1)",
    transition: "ease-in 0.2s",
    cursor: "pointer",
  },
  borderRadius: "1rem",
  paddingTop: "1rem",
  paddingBottom: "1rem",

  border: `1px solid ${colors.background.secondary}`,
  transition: "ease-in 0.2s",
}));
export const StyledButton = styled("button")(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Comfortaa",
  fontWeight: 400,
  fontSize: "1.3rem",
  // border: `2px ${colors.background.outline} solid`,
  backgroundColor: colors.background.secondary,
  color: colors.background.tertiary,
  transition: "background-color 0.3s ease-in-out", // add transition for smooth effect
  // Add hover effect
  "&:hover": {
    backgroundColor: colors.background.secondary,
    color: colors.background.primary,
  },
  borderRadius: "10px",
  padding: "2px 17px",
}));
