import { Controller, useController } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  Box,
  Checkbox,Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { CancelSharp } from "@mui/icons-material"; 
import { styled } from '@mui/material/styles';
import {
  DateTimePicker,
  MobileDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { StyledTextField } from "components/SearchBar/Styles";
import { colors } from "constants/themeConstants";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useGetTermsQuery } from "features/tos/tosApiSlice";
import { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import RichTextViewer from "components/richText/RichTextViewer";
import { AiOutlinePicture } from "react-icons/ai";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const StyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fcfcfb",
  ".MuiChip-deleteIcon": {
    color: "#fcfcfb",
  },
  "&:hover": {
    background: "transparent",
    color: theme.palette.primary.main,
    ".MuiChip-deleteIcon": {
      color: theme.palette.primary.main,
    },
  },
}));
export const FastTextField = ({
  name,
  type,
  required,
  control,
  label,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <label
            htmlFor={label}
            style={{
              color: "grey",
              fontWeight: "normal",
              paddingBottom: "5px", // Padding between label and field
              textAlign: "left", // Align the label to the left
              display: "block", // Ensure label stays on the left
            }}
          >
            {required ? "*" : ""}
            {label}
          </label>
          <StyledTextField
            id={label}
            type={type}
            sx={{
              my: 1,
              mr: 3,
              height: "4rem",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: colors.background.tertiary,
                  backgroundColor: "none",
                  outline: "none",
                },
                "&:hover fieldset": {
                  borderColor: colors.background.secondary,
                  backgroundColor: "none",
                  outline: "none",
                },
                "&.Mui-focused fieldset": {
                  borderColor: colors.background.secondary,
                  backgroundColor: "none",
                  outline: "none",
                },
                "& input": {
                  color: colors.background.tertiary,
                },
              },
              marginTop: "10px", // Adds space between label and input field
            }}
            fullWidth
            variant="outlined"
            required={required}
            placeholder={label}
            inputProps={{
              style: {
                color: colors.background.tertiary, // Matches input color with background color
              },
            }}
            error={!!error}
            helperText={error ? error.message : null}
            {...field}
            {...rest}
          />
        </>
      )}
    />
  );
};
export const FastPhoneField = ({ name, required, control, label, ...rest }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
    console.log("Selected Country:", countryCode); // Log selected country
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <label
          htmlFor={label}
          style={{
            display: "block",
            textAlign: "start",
            color: "grey",
            marginBottom: "2px",
          }}
        >
          {required ? "*" : ""}
          {label}
        </label>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <PhoneInput
              id={label}
              style={{
                width: "100%",
                maxWidth: "100%",
                border: "1px solid black", // Main black border
                borderRadius: "4px",
                padding: "8px",
                boxSizing: "border-box",
                "--border-inner-color": "white", // Custom CSS variable for inner border
              }}
              inputStyle={{
                borderColor: "transparent", // Remove the inner border around the input field
                outline: "none", // Remove outline on focus
              }}
              defaultCountry="ET"
              placeholder={label}
              value={field.value}
              onCountryChange={(countryCode) => {
                handleCountryChange(countryCode);
              }}
              onChange={(value) => {
                field.onChange(value); // Update field value
              }}
              required={required}
              {...rest}
            />
          )}
        />
      </Grid>

      {/* Conditionally render based on selected country */}
      {selectedCountry === "ET" && (
        <Grid item xs={12}>
          <PhoneInput
            label={label}
            style={{
              borderInlineColor: "white",
              width: "100%",
              maxWidth: "100%",
              border: "1px solid black",
              borderRadius: "4px",
              padding: "8px",
              boxSizing: "border-box",
            }}
            inputStyle={{
              borderColor: "transparent", // Remove inner border
              outline: "none", // Remove focus outline
            }}
            {...rest}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const FastTextAreaField = ({
  name,
  control,
  label,
  required,
  ...rest
}) => {
  const multiline = true;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <TextField
          fullWidth
          variant="standard"
          sx={{ my: 1, color: colors.background.tertiary }}
          label={label}
          {...field}
          error={!!error}
          helperText={error ? error.message : null}
          multiline={multiline}
          minRows={multiline ? 3 : null}
          maxRows={multiline ? 10 : null}
          {...rest}
        />
      )}
    />
  );
};

export const FastSwitchField = ({
  name,
  control,
  label,
  required,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={field.value}
                onChange={field.onChange}
                {...rest}
              />
            }
            label={label}
            error={!!error}
            helperText={error ? error.message : null}
          />
        </FormGroup>
      )}
    />
  );
};

export const FastCheckboxField = ({
  name,
  control,
  required,
  label,
  options,
  ...rest
}) => {
  const { data: termsText } = useGetTermsQuery();
  const [modalPayload, setModalPayload] = useState({ open: false, text: "" });
  return (
    <>
      <Controller
        name={name}
        defaultValue={[]}
        control={control}
        render={({ field, fieldState: { error }, formState }) => (
          <FormControl
            fullWidth
            variant="standard"
            error={!!error}
            helperText={error ? error.message : null}
            sx={{ my: 1 }}
            {...rest}
          >
            {/* <FormLabel component="legend">{label}</FormLabel> */}
            <Grid container width="100%">
              <FormGroup>
                {options.map((item) => (
                  <Grid key={item} item xs={12}>
                    <FormControlLabel
                      key={item}
                      control={
                        <Box
                          // onClick={() => toggleValue(item.name)}
                          sx={{
                            padding: "10px",
                            width: "100%",
                            cursor: "pointer",
                            // '&:hover': {
                            //   border: `2px solid ${colors.background.secondary}`,
                            // },
                            // border: `${field.value.some(obj => obj.name === item.name) ? '2px' : '1px'} solid ${
                            //   colors.background.secondary
                            // }`,
                            // backgroundColor: field.value.some(obj => obj.name === item.name) ?
                            //   colors.background.secondary:'none',
                            borderRadius: "20px",
                          }}
                        >
                          <Checkbox
                            checked={field.value.some((obj) => obj === item)}
                            onChange={(event) => {
                              const newValue = event.target.checked
                                ? [...field.value, item]
                                : field.value.filter((value) => value !== item);

                              field.onChange(newValue);
                            }}
                            name={"accept"}
                          />
                          <Typography
                            color={colors.background.tertiary}
                            fontFamily={"Comfortaa"}
                            display={"inline"}
                            fontSize={"14px"}
                            textAlign={"center"}
                            fontWeight={"500"}
                            // alt={item.value}
                            // style={{
                            //   objectFit: 'cover',
                            //   width: 'auto',
                            //   height: '3rem',
                            // }}
                          >
                            {"I accept the "}
                            <span
                              onClick={() =>
                                setModalPayload({
                                  text: termsText ? item : "Loading...",
                                  open: true,
                                  // toggleAccepted: () => setTermsAccepted((prev) => !prev),
                                  // accepted: termsAccepted,
                                })
                              }
                              style={{
                                textDecoration: "underline",
                                color: colors.background.tertiary,
                                fontFamily: "Campton",
                                fontSize: "14px",
                              }}
                            >
                              {"Terms and Conditions"}
                            </span>
                          </Typography>
                        </Box>
                      }
                      // label={item?.name}
                    />
                  </Grid>
                ))}
              </FormGroup>
            </Grid>
          </FormControl>
        )}
      />
      <Modal
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={modalPayload.open}
        onClose={() =>
          setModalPayload({
            open: false,
            text: "",
          })
        }
      >
        <ModalWrapper>
          <Box>
            <RichTextViewer
              readOnly={true}
              value={modalPayload.text}
              setFieldValue={() => {}}
              placeholder=""
            />
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  );
};
export const FastSelectFieldSingle = ({
  name,
  control,
  label,
  required,
  options,
  inputColor, // New prop for specifying input color
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl
          fullWidth
          error={!!error}
          sx={{
            my: 1,
            width: "100%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.background.black,
                backgroundColor: "none",
                outline: "none",
              },
              "&:hover fieldset": {
                borderColor: colors.background.black,
                backgroundColor: "none",
                outline: "none",
              },
              "&.Mui-focused fieldset": {
                borderColor: colors.background.black,
                backgroundColor: "none",
                outline: "none",
              },
              "& input": {
                color: colors.background.tertiary,
              },
              marginTop: "10px", // Adds padding between label and field
            },
          }}
        >
          <label
            htmlFor={label}
            style={{
              color: "grey",
              fontWeight: "normal",
              paddingBottom: "5px", // Adds padding between label and field
              textAlign: "left", // Ensure the label is left-aligned
              display: "block", // Make sure it takes full width
            }}
          >
            {required ? "*" : ""}
            {label}
          </label>
          <Select
            id={label}
            fullWidth
            required={required}
            value={value || ""}
            onChange={(event) => onChange(event.target.value)}
            displayEmpty
            inputProps={{
              style: {
                color: inputColor || "black", // Apply input color or default to black
              },
            }}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <Typography
                    variant="body2"
                    style={{
                      color: "grey",
                      fontWeight: "bold",
                      textAlign: "left",
                    }} // Bold and left-aligned placeholder
                  >
                    {label}
                  </Typography>
                );
              }

              // Ensure the selected item is always displayed in black
              return (
                <Typography
                  textAlign={"start"}
                  variant="body2"
                  style={{ color: "black" }}
                >
                  {selected}
                </Typography>
              );
            }}
            {...rest}
          >
            {options.map((item) => (
              <MenuItem
                key={item}
                value={item}
                style={{
                  fontFamily: "Campton",
                  color: item === value ? "black" : "grey", // Selected item black, others grey
                }}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export const SelectMultipleChipField = ({
  label,
  labelId,
  options,
  disabled = false,
  control, // Accept control from react-hook-form
  name, // Accept name for controlled field
  ...rest
}) => {
  return (
    <FormControl
      {...rest}
      disabled={disabled}
      sx={{ my: 1 }} 
      fullWidth
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={[]} // Set the default value to an empty array
        render={({ field: { onChange, value } }) => (
          <Select
            label={label}
            multiple
            value={value}
            onChange={(event) => {
              const newValue = event.target.value;
              onChange(newValue); // Use onChange from the controller to update value
            }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((val) => (
                  <StyledChip
                    disabled={disabled}
                    deleteIcon={
                      <CancelSharp
                        onMouseDown={(event) => event.stopPropagation()} // Prevents the select from closing
                      />
                    }
                    key={val}
                    label={val}
                    onDelete={() => {
                      const newValues = selected.filter(item => item !== val);
                      onChange(newValues); // Update controlled state on delete
                    }} 
                    sx={{
                      "text-fill-color": "white",
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {options.map((item) => (
              <MenuItem sx={{ color: 'grey' }} key={item.value} value={item.value || item}>
                {item.label || item}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};



// export const SelectMultipleChipField = ({ 
//   label,
//   labelId,
//   options, // Accept options directly
//   disabled = false,
//   ...rest
// }) => {
//   const [selectedValue, setSelectedValue] = useState([]); // Local state for selected values

//   const handleDelete = (itemToDelete) => {
//     const newValues = selectedValue.filter(item => item !== itemToDelete);
//     setSelectedValue(newValues);
//   };

//   const handleChange = (event) => {
//     const value = event.target.value;
//     setSelectedValue(value); // Update the local state
//   };

//   const findItem = (value) => {
//     const item = options.find(
//       (option) => option.value === value || option.value === value?.value
//     );
//     return item ? item.label : "";
//   };

//   return (
//     <FormControl
//       {...rest}
//       disabled={disabled}
//       sx={{ my: 1,}} fullWidth
//     >
//       <InputLabel id={labelId}>{label}</InputLabel>
//       <Select
//         label={label}
//         multiple
//         value={selectedValue}
//         onChange={handleChange}
//         renderValue={(selected) => (
//           <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
//             {selected.map((value) => (
//               <StyledChip
//                 disabled={disabled}
//                 deleteIcon={
//                   <CancelSharp
//                     onMouseDown={(event) => event.stopPropagation()}
//                   />
//                 }
//                 key={value}
//                 label={value}
//                 onDelete={() => handleDelete(value)}
//                 sx={{
//                   "text-fill-color": "white",
//                 }}
//               />
//             ))}
//           </Box>
//         )}
//       >
//         {options.map((item) => (
//           <MenuItem sx={{ color:'grey'}} key={item.value} value={item?.value || item}>
//             {item?.label || item}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

export const FastSelectField = ({
  name,
  control,
  required,
  label,
  options,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error} sx={{ my: 1 }}>
          <InputLabel
            style={{ fontFamily: "Campton", marginLeft: 2 }}
            id={`${name}Label`}
          >
            {label}
            {/* <span style={{color:'s', fontSize:12}}>{required&& '  *required'}</span> */}
          </InputLabel>
          <Select
            fullWidth
            variant="standard"
            multiple6
            required={required}
            label={label}
            value={value || []}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            inputProps={{
              style: {
                color: colors.background.tertiary || colors.background.tertiary,
              },
            }}
            {...rest}
          >
            {options.map((item) => (
              <MenuItem
                style={{
                  fontFamily: "Campton",
                  color: colors.background.tertiary,
                }}
                key={item}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export const FastDateField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <MobileDatePicker
          InputProps={{
            variant: "standard",
          }}
          sx={{ my: 1 }}
          label={label}
          {...field}
          {...rest}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : null,
              ...rest,
            },
          }}
        />
      )}
    />
  );
};

export const FastDateTimeField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <DateTimePicker
          InputProps={{
            variant: "standard",
          }}
          sx={{ my: 1 }}
          label={label}
          {...field}
          {...rest}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : null,
              ...rest,
            },
          }}
        />
      )}
    />
  );
};

export const FastTimeField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <TimePicker
          InputProps={{
            variant: "standard",
          }}
          sx={{ my: 1 }}
          label={label}
          {...field}
          {...rest}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : null,
              ...rest,
            },
          }}
        />
      )}
    />
  );
};
export const FileUploadField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center", // Align vertically to the center
              justifyContent: "start",
              my: 1, // Consistent top and bottom margin
            }}
          >
            <Box
              component="label"
              sx={{
                display: "flex",
                alignItems: "center",
                color: colors.background.outline,
                textTransform: "none",
                fontFamily: "Campton",
                fontWeight: "normal",
                paddingBottom: "5px", // Padding between label and field
                cursor: "pointer",
              }}
            >
              <AttachFileIcon
                sx={{
                  width: "18px",
                  height: "18px",
                  color: colors.background.tertiary,
                }}
              />{" "}
              &nbsp;&nbsp;
              <Typography
                variant="body2"
                style={{ color: "grey", fontWeight: "bold" }}
              >
                {label}
              </Typography>
              <input
                hidden
                required
                accept={true}
                type="file"
                onChange={(e) => field.onChange(e.target.files[0])}
                {...rest}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{ ml: 2, color: colors.background.tertiary }}
            >
              {field.value?.name || ""}
            </Typography>
          </Box>
          {error && (
            <Typography variant="body2" color="error">
              {error.message}
            </Typography>
          )}
        </>
      )}
    />
  );
};

// import React, { useState } from "react";

export const ImageUploadField = ({
  name,
  control,
  label,
  required,
  value, // Existing value if any
  onChange, // Pass the onChange handler
  onBlur,
  error,
  invalid,
  ...rest
}) => {
  const [imageData, setImageData] = useState(null);
  const [imageUpload, setimageUpload] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setimageUpload(file);
    if (!file) {
      return; // Handle empty file selection gracefully
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageDataUrl = reader.result;
      const base64Index = imageDataUrl.indexOf("base64,") + "base64,".length;
      const imageData = imageDataUrl.substring(base64Index);
      setImageData(imageData);
      onChange(imageData); // Pass the image data back through the onChange handler
    };
    reader.onerror = (error) => {
      console.error("Error:", error);
    };
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} textAlign={"start"} mb={1}>
        <label
          htmlFor={label}
          style={{ color: "grey", paddingBottom: "10px", marginBottom: "10px" }}
        >
          {required ? "*" : ""}
          {label}
        </label>
        <TextField
          sx={{ mt: 1 }}
          onClick={() => document.getElementById(name).click()}
          id={label}
          fullWidth
          placeholder={label}
          value={imageUpload?.name || value || ""} // Display file name or placeholder
          InputProps={{
            style: { width: "100%" },
            readOnly: true, // Prevent direct editing of text field
            endAdornment: (
              <IconButton
                component="span"
                onClick={() => document.getElementById(name).click()}
              >
                <AiOutlinePicture style={{ marginLeft: "8px" }} />
              </IconButton>
            ),
            sx: {
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set the border color to black
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Black border on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Black border when focused
                },
                "& input::placeholder": {
                  color: colors.background.tertiary, // More visible placeholder color
                  opacity: 1, // Ensure opacity is not reduced
                },
              },
            },
          }}
          {...rest}
        />
      </Grid>
      <input
        accept="image/*"
        id={name}
        type="file"
        onChange={handleImageChange}
        onBlur={onBlur}
        style={{ display: "none" }} // Hidden input for file selection
      />
      {imageData && (
        <Grid item xs={12}>
          <Typography variant="body1" color="success">
            Image uploaded successfully!
          </Typography>
        </Grid>
      )}
      {invalid && (
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {error?.message || "Required"}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

// export const FastSelectField = ({ name, control, label, options, ...rest }) => {

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field: { value, onChange }, fieldState: { error } }) => (
//         <FormControl
//           fullWidth
//           variant="standard"
//           error={!!error}
//           helperText={error ? error.message : null}
//           sx={{ my: 1 }}
//           {...rest}
//         >
//           <InputLabel id={`${name}Label`}>{label}</InputLabel>
//           <Select
//             fullWidth
//             multiple // Enable multiple selection
//             variant="standard"
//             label={label}
//             value={value || []} // Use the value prop provided by react-hook-form
//             // onChange={(event) => {
//             //   // Convert the selected options into an array
//             //   const selectedOptions = Array.from(
//             //     event.target.selectedOptions,
//             //     (option) => option.value
//             //   );
//             //   onChange(selectedOptions); // Update the field value with the array
//             // }}
//             onChange={(event) => {
//               console.log('event')
//               const selectedOptions = event.target.value; // Selected values will be an array
//               onChange(selectedOptions); // Update the form field's value
//             }}
//           >
//             {options.map((item) => (
//               <MenuItem key={item.id} value={item.id}>
//                 {item?.name || item}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       )}
//     />
//   );
//     // <Controller
//     //   name={name}
//     //   control={control}
//     //   render={({ field, fieldState: { error }, formState }) => (
//     //     <FormControl
//     //       fullWidth
//     //       variant="standard"
//     //       error={!!error}
//     //       helperText={error ? error.message : null}
//     //       sx={{ my: 1 }}
//     //       {...rest}
//     //     >
//     //       <InputLabel id={`${name}Label`}>{label}</InputLabel>
//     //       <Select
//     //         fullWidth
//     //         // multiple
//     //         variant="standard"
//     //         label={label}
//     //         {...field}
//     //         {...rest}
//     //       >
//     //         {options.map((item) => (
//     //           <MenuItem
//     //             value={item}
//     //           >
//     //             {item?.name || item}
//     //           </MenuItem>
//     //         ))}
//     //       </Select>
//     //     </FormControl>
//     //   )}
//     // />

// };
