import { apiSlice } from "app/api/apiSlice";

export const vendorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerVendor: builder.mutation({
      query: (body) => ({
        url: "/vendor/register/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Vendor"],
    }),
    getVendors: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/vendors/?event_id=${id}&page=0&page_size=20`,
        };
      },
      transformResponse: (response) => {
        console.log(response.data);
        return response.data;
      },
      providesTags: ["Vendors"],
    }),
    getVendorTypes: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/public/vendor/service_types/?page=0&page_size=100`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Vendors"],
    }),
    getAllVendors: builder.query({
      query: () => {
        return {
          url: `/portal/public/vendors/?page=0&page_size=20`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Vendors"],
    }),
    getVendorDetail: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `portal/public/vendor/detail/?vendor_id=${id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Companies");
        }
        return response.data;
      },
      providesTags: ["Vendors"],
    }),
    getEventbyVendor: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/events/?vendor_id=${id}&page=0&page_size=25`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Companies");
        }
        return response.data;
      },
      providesTags: ["vendors"],
    }),
  }),
});

export const {
  useRegisterVendorMutation,
  useGetVendorTypesQuery,
  useGetVendorsQuery,
  useGetAllVendorsQuery,
  useGetVendorDetailQuery,
  useGetEventbyVendorQuery,
} = vendorApiSlice;
