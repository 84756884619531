import { colors } from "../../constants/themeConstants";
import { /*Box,  Chip,*/ Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ErrorTextMain = styled(Typography)`
  color: ${colors.background.gray};
  font-family: "Comfortaa";
  font-size: 4rem;
  font-weight: 800;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ErrorTextSub = styled(Typography)`
  color: ${colors.background.gray};
  font-family: "Comfortaa";
  font-size: 1.5rem;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ErrorButton = styled(Button)`
  background-color: ${colors.background.tertiary};
  padding: 1rem 4rem;
  border-radius: 2rem;
  text-transform: none;
  color: ${colors.background.primary};
  &:hover {
    background-color: ${colors.background.secondary};
  }
`;
