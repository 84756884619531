import React from "react";
import PropTypes from "prop-types";
import { /*Button, CircularProgress,*/ Typography } from "@mui/material";
import { colors } from "constants/themeConstants";
import { StyledButton } from "pages/AboutUs/styles";

const StepperNavigation = (props) => {
  const { isLastStep, hasPrevious, handlePrev, isSubmitting } = props;

  return (
    <React.Fragment>
      {false ? (
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            Thank you for your order.
          </Typography>
          <Typography variant="subtitle1">
            Your order number is #2001539. We have emailed your order
            confirmation, and will send you an update if there are any changes.
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {hasPrevious && (
              <StyledButton
                sx={{
                  mt: 3,
                  ml: 1,
                  backgroundColor: colors.background.secondary,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: colors.background.tertiary,
                    color: colors.background.primary,
                  },
                }}
                onClick={handlePrev}
              >
                Back
              </StyledButton>
            )}
            {/* <StyledButton
              // variant="contained"
              sx={{
                mt: 3,
                ml: 1,
                backgroundColor: colors.background.secondary,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: colors.background.tertiary,
                  color: colors.background.primary,
                },
              }}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : isLastStep ? (
                "Book"
              ) : (
                "Next"
              )}
            </StyledButton> */}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

StepperNavigation.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default StepperNavigation;
