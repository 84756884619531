export const GlobalDebug = (function () {
  var debugOn = false;
  var suppressAll = false;
  
  /**
   * Custom logger function that respects debugOn and suppressAll flags.
   */
  function customLogger(type, ...args) {
    if (debugOn) {
      if (suppressAll) {
        return;
      }
      console[type](...args);
    }
  }

  return function (debugFlag, suppressFlag) {
    debugOn = debugFlag || false;
    suppressAll = suppressFlag || false;
    
    if (!debugOn) {
      // Override console functions with custom logger
      console = {
        log: customLogger.bind(null, "log"),
        info: customLogger.bind(null, "info"),
        warn: customLogger.bind(null, "warn"),
        error: customLogger.bind(null, "error"),
        debug: customLogger.bind(null, "debug"),
      };
    } else {
      // Restore the original console object
      console = window.console || {};
    }
  };
})();
