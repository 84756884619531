import React, { useState } from "react";
import FormStep from "./FormStep";
import MuiCustomStepper from "./MuiCustomStepper";
import * as yup from "yup";
//import PaymentForm from "./PaymentForm";
//import Review from "./Review";
import { useNavigate } from "react-router-dom";
//import DefaultSnackbar from "components/Snackbar";
//import { useTicketCheckoutMutation } from "features/ticket/ticketApiSlice";
import { Box, /*Button, CircularProgress*/ } from "@mui/material";
//import PriceSelect from "./priceSelect";
import { GetFields } from "components/formComponents/DynamicForm";
import { /*useGetServiceProviderRequestFormQuery,*/ useRequestServiceMutation } from "features/services/servicesApiSlice";
import { getProperDateTime, getProperTime } from "helpers/dateFormat";
//import { getProperDate } from "utils/dateFormat";
import { getInitialValues } from "helpers/dynamicFormInit";
import { useForm } from "react-hook-form";
import { colors } from "constants/themeConstants";
import { StyledButton } from "pages/AboutUs/styles";

const CheckoutService = ({
  id,
  price,
  name,
  setModalData,
  eventId,
  tickets,
  data,
}) => {
  const [error, setError] = useState(null);
  const [useEmail, setUseEmail] = useState(true);
  console.log("id", id, "evid", eventId);
  // const [checkout] = useRequestServiceMutation();

  console.log("data", data);
  const { register, handleSubmit, reset, control, setValue } = useForm({
    defaultValues: getInitialValues(data || {}),
  });
  const validationSchema = yup.object({
    service_id: yup.array().required("Services should be selected"),
    accessories: yup.array().required("Accessories should be selected"),
    // price: yup
    // .number()
    // .test('sum-of-prices', 'Price should be the sum of service prices', function (value) {
    //   const { service_id } = this.parent; // Access the `service_id` field value from the form data

    //   if (!service_id || !service_id.length) {
    //     // If `service_id` is empty or not provided, the validation passes
    //     return true;
    //   }

    //   const sumOfPrices = service_id.reduce((sum, service) => sum + (service.price || 0), 0);

    //   return value === sumOfPrices;
    // }),
    attendees: yup
      .number()
      .required("Quantity is required")
      .min(1, "Quantity cannot be less than 1"),
    user_email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),

    user_phone: yup.string().required("Phone number is required"),
  });
  const [snackOptions, setSnackOptions] = useState({ open: false, text: "" });
  const navigate = useNavigate();

  const [snapshot, setSnapshot] = useState({
    attendees: 1,
    user_email: "",
    accessories: [],
    //  price:0,
    user_phone: "",
    service_id: 0,
  });
  const [respond, { isLoading }] = useRequestServiceMutation();

  const onSubmit = async (values, { setErrors }) => {
    let formData = { ...values };
    console.log("formDataaa", formData);
    console.log("check", getProperDateTime(formData["start_date"]));
    console.log("21/1/23 2:12:12");
    // prepare values
    Object.keys(data).map((fieldName, index) => {
      const item = data[fieldName];

      // if (fieldName === "start_date" || fieldName === "end_date") {
      //   formData[fieldName] = getProperDateTime(formData[fieldName].toDate());
      // }
      if (fieldName === "start_date" || fieldName === "end_date") {
        formData[fieldName] = getProperDateTime(formData[fieldName]);
        console.log("fieldName", fieldName);
        console.log("fieldName2", formData[fieldName]);
      }
      if (item.type === "TimeField") {
        formData[item.name] = getProperTime(formData[item.name].toDate());
      }
      if (item.type === "CheckboxField") {
        const keys = Object.keys(formData[item.name]).filter(
          (key) => formData[item.name][key]
        );
        formData[item.name] = keys;
      }
    });
    const v = [];
    v.push({ name: "light", type: "bool", price: "100.2", value: true });
    formData = {
      service_id: Number(values.service_id),
      accessories: values.accessories,
      attendees: Number(values.attendees),
      end_date: getProperDateTime(values.end_date),
      start_date: getProperDateTime(values.start_date),
      user_email: values.user_email,
      user_phone: values.user_phone,
    };
    console.log("formData", formData);
    const res = await respond(formData);

    // await mutatationAdapter({
    //   mutateFn: async () => await respond(formData),
    //   // onSuccess: () => toggleModal("modal1"),
    //   onSuccess: () => console.log('success'),

    //   successMessage: "Form response sent",
    //   setErrors: (errors) => reactHookErrorAdapter(errors, setError),
    // });
  };

  // const onSubmit = async (values, { setErrors }) => {
  //   let formData = { ...values };
  //   // prepare values
  //   data?.forEach((item) => {
  //     if (item.field_type === "DateField") {
  //       formData[item.name] = getProperDate(formData[item.name].toDate());
  //     }
  //     if (item.field_type === "DateTimeField") {
  //       formData[item.name] = getProperDateTime(formData[item.name].toDate());
  //     }
  //     if (item.field_type === "TimeField") {
  //       formData[item.name] = getProperTime(formData[item.name].toDate());
  //     }
  //     if (item.field_type === "CheckboxField") {
  //       const keys = Object.keys(formData[item.name]).filter(
  //         (key) => formData[item.name][key]
  //       );
  //       formData[item.name] = keys;
  //     }
  //   });

  //   formData = { id: eventId, values: { ...formData } };

  //   // await mutatationAdapter({
  //   //   mutateFn: async () => await respond(formData),
  //   //   onSuccess: () => toggleModal("modal1"),
  //   //   successMessage: "Form response sent",
  //   //   setErrors: (errors) => reactHookErrorAdapter(errors, setError),
  //   // });
  // };
  // const { register, handleSubmit:hs, reset, control, setValue, setError:se } =
  // useForm({
  //   defaultValues: getInitialValues(data),
  // });
  return (
    <Box px={3}>
      <MuiCustomStepper
        desc={`Select Date and Service `}
        title=""
        onBack={() => setModalData((prev) => ({ ...prev, open: false }))}
        snapshot={snapshot}
        setSnapshot={setSnapshot}
        // error={error}
      >
        <FormStep stepName="Select Date and Service Hours">
          <form onSubmit={handleSubmit(onSubmit)}>
            <GetFields id={eventId} fields={data} control={control} />
            {/* <Button type="submit" text="Submit form"  /> */}
            <StyledButton
              // variant="contained"
              sx={{
                mt: 3,
                ml: 1,
                backgroundColor: colors.background.secondary,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: colors.background.tertiary,
                  color: colors.background.primary,
                },
              }}
              type="submit"
              // disabled={isSubmitting}
            >
              Book
            </StyledButton>
          </form>
          {/* <PriceSelect
          tickets={tickets}
         eventId={eventId}
         price={pricee}
         ticket={ticket}
         setPrice={setPrice}
         setTicket={setTicket}
           
           
          /> */}
        </FormStep>
        {/* <FormStep  onSubmit={handleSubmit} validationSchema={validationSchema} stepName="Full Information">
          <PaymentForm
         eventId={eventId}
            useEmail={useEmail}
            setUseEmail={setUseEmail}
           
            price={price ==0 ?pricee:price}
          />
        </FormStep> */}
        {/* <FormStep
          validationSchema={validationSchema}
          stepName="Review"
          onSubmit={handleSubmit}
        >
          <Review
            snapshot={{ ...snapshot, pricee, setError, id, reason: `${name} ticket payment` }}
          />
        </FormStep> */}
      </MuiCustomStepper>
      {/* <DefaultSnackbar options={snackOptions} handleClose={handleClose} /> */}
    </Box>
  );
};

export default CheckoutService;
