import { colors } from "../../constants/themeConstants";
const { Pagination } = require("@mui/material");
const { styled } = require("@mui/system");
export const StyledPagination = styled(Pagination)`
&& {
  & .MuiPaginationItem-root {
    color: ${({ theme }) => colors.background.tertiary};
    font-family: 'Comfortaa';
    font-size: 14px;
    /* Add any other styles you want to apply */
  }

  & .Mui-selected {
    background-color: ${({ theme }) => colors.background.secondary};
    color: ${({ theme }) => colors.background.tertiary};
    /* Add any other styles you want to apply to the selected item */
  }

  /* Add any other styles you want to apply */
}
`;