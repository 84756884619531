import { apiSlice } from "app/api/apiSlice";

export const tosApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTerms: builder.query({
      query: () => "tos/terms_of_service/",
      method: "GET",
      providesTags: ["Tos"],
    }),
    getSupport: builder.query({
      query: () => "/tos/contact_support/",
      method: "GET",
      providesTags: ["Tos"],
    }),
    getPrivacy: builder.query({
      query: () => "/tos/privacy_policy/",
      method: "GET",
      providesTags: ["Tos"],
    }),
    createTerms: builder.mutation({
      query: (body) => ({
        url: "/tos/terms_and_conditions/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Tos"],
    }),
    createPrivacy: builder.mutation({
      query: (body) => ({
        url: "/tos/privacy_policy/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Tos"],
    }),
  }),
});

export const {
  useGetTermsQuery,
  useGetPrivacyQuery,
  useGetSupportQuery,
  useCreateTermsMutation,
  useCreatePrivacyMutation,
} = tosApiSlice;
