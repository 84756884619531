import { colors } from "../../constants/themeConstants";
const { Box, Chip, Typography, IconButton } = require("@mui/material");
//const {Button } = require("@mui/material");
const { styled } = require("@mui/system");

export const StyledConcertImg = styled("img")({
  objectFit: "cover",
  width: "100%",
  height: "100%",
  minHeight: "40vh",
  minWidth: "100vw",
});
export const LogoOverlayimg = styled("img")({
  alt: "Overlay Logo",
  justifyContent: "center",
  objectFit: "cover",
});
export const ManageEvents = styled("img")({
  alt: "play image",
});
export const StyledAdSharp = styled("img")({
  // borderRadius: "20px",
  objectFit: "cover",
  cursor: "pointer",
  width: "100%",
  marginTop: "30px",
  marginBottom: "10px",
});
export const StyledAdRounded = styled("img")({
  borderRadius: "20px",
  objectFit: "cover",
  cursor: "pointer",
  width: "100%",
  marginTop: "30px",
  marginBottom: "10px",
});
export const StyledAdCircular = styled("img")({
  borderRadius: "100px",
  objectFit: "cover",
  cursor: "pointer",
  width: "100%",
  marginTop: "30px",
  marginBottom: "10px",
});
export const StyledDateTextTitle = styled("span")({
  color: colors.background.yellow,
  fontSize: "13px",
  fontFamily: "Comfortaa",
});
export const StyledDateTextBody = styled("span")({
  color: colors.background.white,
  fontSize: "11px",
  fontFamily: "Comfortaa",
  textAlign: "start",
  lineHeight: 1.3,
});
export const StyledInfo = styled(Typography)({
  color: colors.background.white,
  fontSize: "12px",
  fontFamily: "Comfortaa",
  textAlign: "start",
  lineHeight: 1.1,
});
export const StyledTitle = styled("span")({
  color: colors.background.white,
  fontSize: "22px",
  fontFamily: "Comfortaa",
  textAlign: "start",
  display: "block",
});
export const StyledTitleYellow = styled("span")({
  color: colors.background.secondary,
  fontSize: "28px",
  fontFamily: "Campton",
  fontWeight: "medium",
  textAlign: "start",
});
export const StyledBlackLabel = styled("span")({
  color: "black",
  fontSize: "20px",
  fontFamily: "Campton",
  fontWeight: "light",
});
export const StyledMoreButtonText = styled(Typography)({
  display: "inline",
  fontFamily: "Campton",
  fontWeight: 300,
  color: colors.background.tertiary,
  cursor: "pointer",
  fontSize: "0.8rem",
  transition: "transform 0.25s",
  "&:hover": {
    fontSize: "1.0rem",
  },
});
export const StyledButton = styled("button")(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Campton",
  fontWeight: 400,
  fontSize: 18,
  border: `2.5px ${colors.background.secondary} dashed`,
  backgroundColor: colors.background.pureWhite,
  color: colors.background.tertiary,

  transition: "background-color 0.3s ease-in-out", // add transition for smooth effect
  // Add hover effect
  "&:hover": {
    backgroundColor: colors.background.secondary,
    color: colors.background.primary,
    border: `2.5px ${colors.background.pureWhite} dashed`,
  },
  borderRadius: "20px",
  padding: "6px 22px",
}));
export const StyledCategoryChip = styled(Chip)({
  // display:'inline',
  width: "100%",
  height: "20px",
});
export const StyledSmallText = styled("span")({
  color: colors.background.white,
  fontSize: "10px",
  fontFamily: "Comfortaa",
});

export const StyledBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    transform: "scale(1.2)",
    transition: "ease-in 0.2s",
    cursor: "pointer",
  },
  transition: "ease-in 0.2s",
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: colors.background.tertiary,
  border: `solid 1px ${colors.background.outline} `,
  [theme.breakpoints.only("xs")]: {
    padding: "4px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "8px",
  },
}));
