import { Box, /*Paper, IconButton,*/ Typography, styled } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
//import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "constants/themeConstants";
import { StyledAuthBackground } from "./style";

const RegisterContainer = styled(Box)(({ theme }) => ({
  width: "70%",
  height: "60%",
  margin: 20,
  background: colors.background.primary,

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const RegisterWrapper = (props) => {
  const { title, subTitle, children } = props;

  const navigate = useNavigate();

  return (
    <StyledAuthBackground width="100%" height="70%">
      <Box
        style={{
          mx: 3,
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <RegisterContainer
          sx={{
            borderRadius: "10px",
            py: 2,
            px: { xl: 5, lg: 5, xs: -2, md: 5 },
            my: 6,
          }}
        >
          <Box
            sx={{
              minHeight: "3rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <IconButton onClick={() => navigate("/")}>
              <ArrowBackIcon />
            </IconButton> */}

            <Typography
              fontFamily={"Campton"}
              color={colors.background.tertiary}
              component="h1"
              variant="h5"
            >
              {title}
            </Typography>
          </Box>
          <Typography
            fontFamily={"Campton"}
            color={colors.background.tertiary}
            textAlign={"center"}
            variant="subtitle1"
            sx={{ mt: 0 }}
          >
            {subTitle}
          </Typography>
          {children}
        </RegisterContainer>
      </Box>
    </StyledAuthBackground>
  );
};

export default RegisterWrapper;
