import {
  /*ArrowDownward,
  ArrowDropDown,
  ArrowDropDownOutlined,*/
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import { StyledTitleYellow } from "./Styles";
import { colors } from "constants/themeConstants";

const SnippitHeader = ({
  title,
  showMore,
  showLess,
  seeAll = null,
  title2 = null,
  handleClick,
}) => {
  const theme = useTheme();
  //const navigate = useNavigate();
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
        // ml:8,
        // mr:5
      }}
    >
      <Typography
        fontFamily="Campton"
        fontSize={{ xs: "25px", md: "30px" }}
        color={theme.palette.background.tertiary}
        fontWeight={300}
      >
        {title}
        <StyledTitleYellow sx={{ fontSize: { xs: "25px", md: "30px" } }}>
          {title2}
        </StyledTitleYellow>
      </Typography>
      {showMore ? (
        <KeyboardArrowUp
          onClick={showLess}
          sx={{ color: colors.background.tertiary, ml: 1 }}
        />
      ) : (
        <KeyboardArrowDown
          onClick={handleClick}
          sx={{ color: colors.background.tertiary, ml: 1 }}
        />
      )}
      {/* {(seeAll && !isMobile)  && (
        <Typography
        
          variant="p2"
          sx={{
            color: "primary.main",
            cursor: "pointer",
            transition: "0.5s ease",
            "&:hover": { color: "text.primary" },
            pr: 4,
          }}
          onClick={() => navigate(seeAll)}
        >
          See All
        </Typography>
      )} */}
    </Box>
  );
};

export default SnippitHeader;
