
import { useState, useEffect } from "react"

const getLocalValue = (key, initValue) => {
  //SSR Next.js
  if (typeof window === "undefined") return initValue

  // if a value is already store
  const localValue = localStorage.getItem(key) || null
  if (localValue) return localValue
    

  // return result of a function
  if (initValue instanceof Function) return initValue()

  return initValue
}

const useLocalStorage = (key, initValue) => {
  const [value, setValue] = useState(() => {
    return getLocalValue(key, initValue)
  })

  useEffect(() => {
    //Get rid of JSON.stringify because it adds an extra quote to the token
    //localStorage.setItem(key, JSON.stringify(value))
    localStorage.setItem(key, value)
  }, [key, value])

  return [value, setValue]
}

export default useLocalStorage