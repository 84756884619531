import { Box, Grid, /*Button, Typography,*/ useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
// import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import {
  // FeaturedTitle,
  FeaturedSubTitle,
  // FeaturedBody,
  FeaturedCardImage,
  FeaturedEventsTitle,
  FeaturedSubTitle2,
} from "./Styles";
import { apiUrl } from "constants/api";
import { getParsedDate /*getParsedTime*/ } from "utils/dateFormat";

// import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoCart /*IoPricetagOutline*/ } from "react-icons/io5";
import { IoIosNotifications /*IoMdTime*/ } from "react-icons/io";
import StorefrontIcon from "@mui/icons-material/Storefront";
// import { HiMusicNote } from "react-icons/hi";
import { colors } from "constants/themeConstants";
// import { FaHiking } from "react-icons/fa";
import CheckoutModal from "components/Checkout/CheckoutModal";
import { useNavigate } from "react-router-dom";
import { StyledButton, StyledDateTextBody } from "../SquareEventCard/Styles";
import { LanguageContext } from "hooks/useLanguage";
import { localization } from "constants/localalization";
import { CurrencyContext } from "hooks/useCurrency";
const Venue = ({ location }) => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container>
        <Grid item xs={2}>
          <FeaturedSubTitle>where</FeaturedSubTitle>
        </Grid>
        <Grid item xs={10}>
          <FeaturedSubTitle2>{location}</FeaturedSubTitle2>
        </Grid>
      </Grid>
    </Box>
  );
};

const Price = ({ event }) => {
  const { currency } = useContext(CurrencyContext);

  const theme = useTheme();
  return (
    <Box>
      <Grid container>
        <Grid item xs={2}>
          <FeaturedSubTitle>price</FeaturedSubTitle>
        </Grid>
        <Grid item xs={10}>
          <FeaturedSubTitle2
          // variant="p2"
          >
            {event?.tickets?.length > 0
              ? Math.ceil(
                  event?.tickets[0]?.prices?.find(
                    (item) => item.currency === currency
                  )?.price ??
                    event?.tickets[0]?.prices?.find(
                      (item) => item.currency === "ETB"
                    )?.price
                ) +
                (event?.tickets[0]?.prices?.find(
                  (item) => item.currency === currency
                )?.currency ?? "ETB")
              : "Free"}
          </FeaturedSubTitle2>
        </Grid>
      </Grid>
    </Box>
  );
};

// const Organizer = ({ organizer }) => {
//   const theme = useTheme();
//   return (
//     <Box>
//       <FeaturedTitle>Organizer</FeaturedTitle>
//       <FeaturedSubTitle style={{ color: colors.background.almostBlack }}>
//         {organizer}
//       </FeaturedSubTitle>
//     </Box>
//   );
// };

const Datee = ({ event }) => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container>
        <Grid item xs={2}>
          <FeaturedSubTitle>when</FeaturedSubTitle>
        </Grid>
        <Grid paddingRight={3} item xs={10}>
          <FeaturedSubTitle2>
            {getParsedDate(new Date(event?.start_date)) ===
            getParsedDate(new Date(event?.end_date))
              ? `${getParsedDate(new Date(event?.start_date))} `
              : `${getParsedDate(
                  new Date(event?.start_date)
                )} - ${getParsedDate(new Date(event?.end_date))} `}
          </FeaturedSubTitle2>
        </Grid>
      </Grid>
    </Box>
  );
};

const FeaturedEventCard = ({ event }) => {
  const { language } = useContext(LanguageContext);

  const navigate = useNavigate();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const innerDivRef = useRef(null);

  useEffect(() => {
    if (innerDivRef.current) {
      const shouldAnimate =
        innerDivRef.current.scrollWidth > innerDivRef.current.offsetWidth;
      setShouldAnimate(shouldAnimate);
    }
  }, []);
  const [modalData, setModalData] = useState({
    open: false,
    id: 0,
    price: 0,
    tickets: [],
    name: "",
  });

  return (
    <Box width="100%">
      <FeaturedCardImage
        className="h-auto w-full max-h-[210px] sm:max-h-[165px] md:max-h-[180px] lg:max-h-[180px] xl:max-h-[200px] 2xl:max-h-[180px] 3xl:max-h-[200px]"
        src={`${apiUrl}${event?.image?.image_medium_path}`}
        onClick={() => navigate(`/events/detail?event_id=${event.id}`)}
      />
      <Box sx={{ position: "absolute", bottom: 5, right: 45 }}>
        {event.allow_vendors && (
          <StorefrontIcon color="primary" sx={{ width: 20, height: 20 }} />
        )}
      </Box>
      {/* <Box sx={{ position: "absolute", bottom: 5, right: 10 }}>
          {chooseIcon(event?.category?.length>0?event?.category[0].name:'')}
        </Box> */}
      <div className="w-100 h-10 mt-5 overflow-hidden">
        <div
          className="whitespace-nowrap"
          style={{
            animation: shouldAnimate ? "marquee 10s linear infinite" : "none",
          }}
          ref={innerDivRef}
        >
          <FeaturedEventsTitle style={{ color: colors.background.tertiary }}>
            {event?.name[language]}
          </FeaturedEventsTitle>
        </div>
      </div>
      {/* <FeaturedEventsTitle   
        >{event?.name}</FeaturedEventsTitle>
         */}

      <Grid container sx={{ mt: 2.5 }}>
        <Grid item xs={12} lg={12}>
          <Datee event={event} />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2.5 }}>
        <Grid item xs={12} lg={12}>
          <Venue location={event?.venue?.name} />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 2.5 }}>
        <Grid item xs={12} lg={12}>
          <Price event={event} />
        </Grid>
      </Grid>
      {/* <Grid direction="column" justify="center" item xs={12} lg={12}> */}
      <Grid mt={{ xs: 4, md: 0, lg: 4 }} spacing={2} container>
        <Grid
          item
          xs={event?.tickets?.length === 0 ? 12 : 6}
          md={12}
          lg={event?.tickets?.length === 0 ? 12 : 6}
        >
          <StyledButton
            className="group"
            sx={{
              width: "130px",
              height: 1,
              border: `dashed 1px ${colors.background.primary}`,
              // borderRadius: 20,
              backgroundColor: colors.background.pureWhite,
              paddingLeft: 3,
              paddingRight: 3,
              paddingTop: 1,
              paddingBottom: 1,
              // borderRadius: 20,
            }}
            onClick={() => {
              navigate("/vendors/register");
            }}
            startIcon={
              <IoIosNotifications
                className={`group-hover:bg-[${colors.background.secondary}] group-hover:border-white border-[1px] border-[${colors.background.outline}] group-hover:text-white text-slate-800`}
                // color={colors.background.tertiary}
                style={{
                  padding: 5,
                  width: "30px",
                  height: "30px",
                  borderRadius: "50px",
                }}
              />
            }
            variant=""
          >
            <StyledDateTextBody
              className="sm:text-[15px] md:text-[13px] lg:text-[13px] group-hover:text-white hover:font-medium"
              style={{
                color: colors.background.tertiary,
              }}
            >
              Get <br /> Notification
            </StyledDateTextBody>{" "}
          </StyledButton>
        </Grid>

        <Grid
          display={event?.tickets?.length === 0 ? "none" : "flex"}
          item
          xs={event?.tickets?.length === 0 ? 0 : 6}
          md={event?.tickets?.length === 0 ? 0 : 12}
          lg={event?.tickets?.length === 0 ? 0 : 6}
        >
          {/* <Box display="flex" justifyContent="flex-end"> */}
          <StyledButton
            className="group"
            display={event?.tickets?.length === 0 ? "none" : "flex"}
            sx={{
              width: "95%",
              height: 1,
              border: `dashed 1px ${colors.background.primary}`,
              // borderRadius: 20,
              backgroundColor: colors.background.pureWhite,
              paddingLeft: 3,
              paddingRight: 3,
              paddingTop: 1,
              paddingBottom: 1,
              // borderRadius: 20,
            }}
            onClick={() =>
              setModalData((prev) => ({
                ...prev,
                open: true,
                id: 0,
                eventId: event?.id,
                desc: "item.desc",
                isRecurring: event?.is_recurring,
                isFree: event?.is_free,
                name: event.name[language],
                price: -1,
                tickets: event?.tickets,
              }))
            }
            startIcon={
              <IoCart
                className={`group-hover:bg-[${colors.background.secondary}] group-hover:border-white border-[1px] border-[${colors.background.outline}] group-hover:text-white text-slate-800`}
                style={{
                  padding: 5,
                  width: "30px",
                  height: "30px",
                  borderRadius: "50px",
                }}
              />
            }
            variant=""
          >
            <StyledDateTextBody
              className="sm:text-[13px] md:text-[13px] lg:text-[13px] group-hover:text-white hover:font-medium"
              style={{ color: colors.background.tertiary }}
            >
              {event?.is_free
                ? event?.tickets?.length === 0
                  ? localization.Labels.open[language]
                  : localization.Labels.free[language]
                : localization.Labels.buy[language]}{" "}
              <br />{" "}
              {event?.is_free
                ? event?.tickets?.length === 0
                  ? localization.Labels.door[language]
                  : localization.Labels.ticket[language]
                : localization.Labels.ticket[language]}
            </StyledDateTextBody>{" "}
          </StyledButton>
          {/* </Box> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
      <CheckoutModal modalData={modalData} setModalData={setModalData} />
    </Box>
  );
};

export default FeaturedEventCard;
