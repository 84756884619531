import { apiSlice } from "app/api/apiSlice";

export const ticketApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ticketCheckout: builder.mutation({
      query: (body) => ({
        url: "/portal/public/ticket/checkout/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
    }),
    verifyPromoCode: builder.mutation({
      query: (body) => ({
        url: "public_app/ticket/promo_code/validate/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
    }),
    getSpecialService: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/public_app/event/special_services/?event_id=${id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Special Service Data");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getTicketBundles: builder.query({
      query: (query) => {
        const { id, scheduleId } = query;
        return {
          url: `/portal/public/event/ticket/bundles/?event_id=${id}&event_schedule_id=${scheduleId}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Bundles");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getEventTicket: builder.query({
      query: (query) => {
        const { scheduleId, id } = query;
        return {
          url: `/portal/public/event/tickets/?event_schedule_id=${scheduleId}&event_id=${id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Bundles");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getMultipleTickets: builder.query({
      query: (query) => {
        const { order_id } = query;
        return {
          url: `/portal/public/ticket/?system_order_id=${order_id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error loading tickets");
        }
        return response.data;
      },
    }),
  }),
});

export const {
  useTicketCheckoutMutation,
  useGetSpecialServiceQuery,
  useVerifyPromoCodeMutation,
  useGetTicketBundlesQuery,
  useGetEventTicketQuery,
  useGetMultipleTicketsQuery,
} = ticketApiSlice;
