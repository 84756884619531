import { Box, Grid } from "@mui/material";
//import { Pagination, Typography } from "@mui/material";
//import SquareEventCard from "components/Cards/SquareEventCard";
import SquareLoadingEventCard from "components/Cards/SquareEventCard/SquareLoadingEventCard";
import SnippitHeader from "../Snippit";
//import { Navigation } from "swiper";
//import { Swiper, SwiperSlide } from "swiper/react";
import { StyledMoreButtonText} from "../Styles";
//import { StyledTitle } from "../Styles";
//import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
//import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//import { useNavigate } from "react-router-dom";
import {
  //ArrowDownward,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
//import useGetData from "hooks/useGetData";
//import { useGetEventsQuery } from "features/events/eventsApiSlice";
import { useContext, useState, useEffect } from "react";
import { colors } from "constants/themeConstants";
import { localization } from "constants/localalization";
import { LanguageContext } from "hooks/useLanguage";
import { StyledPagination } from "pages/Events/styles";
import SquareServiceCard from "components/Cards/SquareEventCard/variant";
const CardsWrapper = ({ children }) => {
  return <Box sx={{ mr: 2 }}>{children}</Box>;
};
const MoreServices = ({
  title,
  title2,
  events,
  isLoading,
  seeAll,
  setPagination,
  pagination,
  rowCount,
  refetch,
}) => {
  //const navigate = useNavigate();
  const [displayCount, setDisplayCount] = useState(3);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [minCount, setMinCount] = useState(3);
  useEffect(() => {
    function handleResize() {
      const newWidth = window.innerWidth;
      setDimensions(newWidth);
      if (newWidth > 768 && newWidth < 1350) {
        setDisplayMore(false);
        setDisplayCount(2);
        setMinCount(2);
      } else {
        setDisplayMore(false);
        setDisplayCount(3);
        setMinCount(3);
      }
    }

    setDimensions(window.innerWidth);
    handleResize();

    window.addEventListener("resize", handleResize);

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);
  const { language } = useContext(LanguageContext);

  const [displayMore, setDisplayMore] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  function handleShowMore(len) {
    setDisplayCount(len);
    setDisplayMore(!displayMore);
    setIsOpen(true);
  }
  function handleShowLess(len) {
    setDisplayCount(len);
    setDisplayMore(!displayMore);
    setIsOpen(false);
  }
  // console.log("Here");
  const loadingCards = [1, 2, 3];
  return (
    <Box sx={{ my: 4, mx: 8, width: "auto" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={0}>
        <Grid item xs={0}></Grid>
        <Grid item xs={12}>
          {" "}
          <SnippitHeader
            handleClick={() =>
              !isOpen
                ? handleShowMore(events?.length)
                : handleShowLess(minCount)
            }
            title={title}
            title2={title2}
            seeAll={seeAll}
          />{" "}
        </Grid>
        <Grid item xs={0}></Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <Grid container >
            {isLoading
              ? loadingCards?.map((item) => (
                  <Grid
                    key={item.id}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                    dxl={4}
                    ddxl={4}
                  >
                    <SquareLoadingEventCard />
                  </Grid>
                ))
              : events?.slice(0, displayCount).map((item) => (
                  <Grid
                    item
                    key={item.id}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                    dxl={4}
                    ddxl={4}
                  >
                    <SquareServiceCard event={item} />
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Grid>
      {events?.length > displayCount && (
        <Grid
          container
          width={{ xs: "100%", md: "40%", lg: "30%", dxl: "20%" }}
          mx="auto"
          borderRadius={"30px"}
          backgroundColor={colors.background.primary}
          mt={3}
          py="5px"
          justifyContent={"center"}
          alignContent="center"
          alignItems={"center"}
          justifyItems="center"
          onClick={() => handleShowMore(events?.length)}

        >
          <Grid item>
            <KeyboardDoubleArrowDown
              sx={{ color: colors.background.tertiary }}
            />
            <StyledMoreButtonText>
              {localization.Titles.showmore[language] + " "+ title2}
            </StyledMoreButtonText>
          </Grid>
        </Grid>
      )}
      {displayMore && (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <StyledPagination
              style={{ color: colors.background.tertiary }}
              page={events?.page_metadata?.page}
              count={Math.ceil(rowCount / pagination.pageSize)}
              // count={data?.page_metadata?.length % data?.page_metadata?.size}
              onChange={(e, page) => {
                setPagination({
                  pageIndex: page - 1,
                  pageSize: pagination.pageSize,
                });
                refetch();
              }}
            />
          </Box>
          <Grid
            container
            // border='solid white 0.5px'
            width={{ xs: "100%", md: "40%", lg: "30%", dxl: "20%" }}
            mx="auto"
            borderRadius={"20px"}
            backgroundColor={colors.background.primary}
            mt={3}
            py="5px"
            justifyContent={"center"}
            alignContent="center"
            alignItems={"center"}
            onClick={() => handleShowLess(minCount)}

            justifyItems="center"
          >
            <Grid item>
              <KeyboardDoubleArrowUp
                sx={{ color: colors.background.tertiary }}
              />
               <StyledMoreButtonText>
                {localization.Titles.showless[language] + " "+title2}
              </StyledMoreButtonText>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
export default MoreServices;
