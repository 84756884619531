import RegisterWrapper from "components/RegisterWrapper";
import BodyWrapper from "components/BodyWrapper";
import ServiceRegisterForm from "./serviceRegistrationForm";

const ServiceRegister = () => {
  return (
    <BodyWrapper>
      <RegisterWrapper
        title="Service Registration"
        subTitle="Register and Get paid for your services."
      >
        <ServiceRegisterForm />
      </RegisterWrapper>
    </BodyWrapper>
  );
};

export default ServiceRegister;
