import { Box, /*Typography*/ } from "@mui/material";
import React, { useEffect, useState } from "react";
// import img from "../../../assets/images/cardbgtrending.png";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  EffectCube,
  // EffectFade,
  // Navigation,
  Pagination,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../Home/styleSwiper.css";

// import eventImage from "assets/images/event_image.png";
import { colors } from "constants/themeConstants";
// import SnippitHeader from "./SnippitHeader";
import LoadingFeaturedEventCard from "components/Cards/FeaturedEventCard/LoadingFeaturedEventCard";
import FeaturedEventCard from "components/Cards/FeaturedEventCard";
//import { useNavigate } from "react-router-dom";

// const event = {
//   name: "Bermel Fest",
//   image: eventImage,
//   location: {
//     venue: "Wenchi",
//     loc: "Ghion Hotel",
//   },
//   date: {
//     date: "Sat 25 Dec 2022",
//     time: "6:00AM - 6:00PM",
//   },
//   organizer: "Shega Events",
//   category: "Music",
//   price: {
//     amount: "300",
//     currency: "ETB",
//   },
//   allow_vendors: true,
// };
// const event2 = {
//   name: "Bermel Fest2",
//   image: eventImage,
//   location: {
//     venue: "Wenchi",
//     loc: "Ghion Hotel",
//   },
//   date: {
//     date: "Sat 25 Dec 2022",
//     time: "6:00AM - 6:00PM",
//   },
//   organizer: "Shega Events",
//   category: "Hiking",
//   price: {
//     amount: "300",
//     currency: "ETB",
//   },
//   allow_vendors: false,
// };
// const event3 = {
//   name: "Bermel Fest3",
//   image: eventImage,
//   location: {
//     venue: "Wenchi",
//     loc: "Ghion Hotel",
//   },
//   date: {
//     date: "Sat 25 Dec 2022",
//     time: "6:00AM - 6:00PM",
//   },
//   organizer: "Shega Events",
//   allow_vendors: true,
//   category: "Art",
//   price: {
//     amount: "300",
//     currency: "ETB",
//   },
// };

const FeaturedEvents = ({ events, isLoading, seeAll }) => {
  // const navigate = useNavigate();
  const [isMainLoading, setIsMainLoading] = useState(true);
  useEffect(() => {
    const t = setTimeout(() => setIsMainLoading(false), 2000);

    return () => clearTimeout(t);
  }, []);
  return (
    <Box marginTop={4}>
      {/* <SnippitHeader title={"Featured Events"} /> */}
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          // paddingLeft:'20px',
          backgroundColor: colors.background.secondary,
          borderRadius: "16px",
          px: 3,
          pb: 3,
        }}
      >
        {isLoading ? (
          <LoadingFeaturedEventCard />
        ) : (
          <Swiper
            // direction={"vertical"}
            // spaceBetween={25}
            slidesPerView={1}
            // effect={"cube"}
            grabCursor={true}
            cubeEffect={{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 1,
            }}
            // navigation={true}
            className="mySwiper"
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            // navigation={true}
            // loop={true}
            modules={[EffectCube, Autoplay, Pagination]}
            pagination={{
              clickable: true,
            }}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {events?.map((event) => (
              <SwiperSlide key={event.id}>
                <FeaturedEventCard event={event} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
    </Box>
  );
};

export default FeaturedEvents;
