import { Box, /*Button, Icon, SvgIcon, Typography*/ } from "@mui/material";
//import owlIcon from "../../assets/images/logos/owlLogo.png";
//import { Link } from "react-router-dom";
import { /*ErrorButton,*/ ErrorTextMain, ErrorTextSub } from "./style";
//import ResponsiveAppBar from "pages/Homepage/appBar";
//import BodyWrapper from "components/BodyWrapper";
import { colors } from "constants/themeConstants";
import DynamicFormIcon from "@mui/icons-material/DynamicFormRounded";

export default function NoEvents() {
  return (
    
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          height: "80vh",
          py: "4.5rem",
        }}
      >
        <DynamicFormIcon
          style={{ color: colors.background.gray, scale: "5" }}
        />

        <ErrorTextMain>Oops!</ErrorTextMain>
        <ErrorTextSub>No events found</ErrorTextSub>
        {/* <Link to="/">
          <ErrorButton>Back to homepage</ErrorButton>
        </Link> */}
      </Box>
    // </BodyWrapper>
  );
}
