import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const index = ({ options, handleClose }) => {
  const { open, text = "Success", severity = "success" } = options;
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
      anchorOrigin={anchorOrigin}
      sx={{ width: "100%" }}
      TransitionComponent={SlideTransition}
    >
      <Alert
        sx={{ width: "60vw" }}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default index;
