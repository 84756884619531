import { Box, useTheme, Typography, Grid } from "@mui/material";
//import { lineHeight } from "@mui/system";
import React, { useContext } from "react";

import {
  StyledFooter,
  StyledFooterCol,
  StyledFooterMain,
  StyledFooterTitle,
  StyledFooterTitleSec,
} from "./Styles";
import { colors } from "constants/themeConstants";
import { useNavigate } from "react-router-dom";
import {
  LinkednUrl,
  emailUrl,
  facebookUrl,
  instagramUrl,
  twitterUrl,
} from "constants/api";
import { LanguageContext } from "hooks/useLanguage";
import { localization } from "constants/localalization";
import { versionNumber } from "constants/version";

const Footer = () => {
  const { language } = useContext(LanguageContext);

  const navigate = useNavigate();

  const theme = useTheme();
  const topics = [
    // 'About',
    //  '.','Blog',
    //  '.',
    {
      name: localization.FooterTitles.refundpolicy[language],
      url: "/RefundPolicy",
    },
    {
      name: localization.FooterTitles.communityguidelines[language],
      url: "/CommunityGuidelines",
    },
    {
      name: localization.FooterTitles.terms[language],
      url: "/TermsAndConditions",
    },
    {
      name: localization.FooterTitles.privacy[language],
      url: "/PrivacyPolicy",
    },
    // localization.FooterTitles.refundpolicy[language],
    // //  '.','Press',
    // //  '.','Investors',
    // //  '.','Security',
    // ".",
    // localization.FooterTitles.communityguidelines[language],
    // ".",
    // localization.FooterTitles.terms[language],
    // ".",
    // localization.FooterTitles.privacy[language],
    // //  '.','Accessiblity',
    //  '.','Cookies'
  ];
  return (
    <Box sx={{ position: "relative" }}>
      <StyledFooter style={{ backgroundColor: colors.background.secondary }}>
        {/* </StyledFooterCol>  */}

        <StyledFooterMain
          style={{ color: colors.background.tertiary }}
          className="mt-4"
        >
          <StyledFooterCol>
            <StyledFooterTitle
              style={{ color: colors.background.tertiary, cursor: "pointer" }}
              className="text-[24px]"
            >
              {localization.FooterTitles.utilize[language]}
            </StyledFooterTitle>
            <StyledFooterTitleSec
              className="text-[12px]"
              onClick={() => {
                navigate("/createEvents");
              }}
            >
              {localization.Labels.createevent[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec className="text-[12px]">
              {localization.FooterTitles.subPlans[language]}
            </StyledFooterTitleSec>
            {/* <StyledFooterTitleSec  className="text-[12px]">Eventowl Boost</StyledFooterTitleSec> */}
            <StyledFooterTitleSec
              onClick={() => {
                navigate("/Ticketing App");
              }}
              className="text-[12px]"
            >
              {localization.FooterTitles.mobileTicketApp[language]}
            </StyledFooterTitleSec>
            {/* <StyledFooterTitleSec  className="text-[12px]">Content Standards</StyledFooterTitleSec> */}
            <StyledFooterTitleSec
              onClick={() => {
                navigate("/faq");
              }}
              className="text-[12px]"
            >
              {localization.FooterTitles.FAQ[language]}
            </StyledFooterTitleSec>

            {/* <StyledFooterTitleSec>
              <p>OwlEvents v{`1.2`}</p>
            </StyledFooterTitleSec> */}
          </StyledFooterCol>
          <StyledFooterCol>
            <StyledFooterTitle className="text-[24px]">
              {localization.FooterTitles.discoverOccasions[language]}
            </StyledFooterTitle>
            {/* <StyledFooterTitleSec  className="text-[12px]">EventOwl Boost</StyledFooterTitleSec> */}
            {/* <StyledFooterTitleSec  className="text-[12px]">EventOwl Sell Concert Tickets</StyledFooterTitleSec> */}
            {/* <StyledFooterTitleSec  className="text-[12px]"> Sell Virtual Events</StyledFooterTitleSec> */}
            {/* <StyledFooterTitleSec  className="text-[12px]"> Sell Corporate Tickets</StyledFooterTitleSec> */}
            {/* <StyledFooterTitleSec  className="text-[12px]">Sell Trade Show Events</StyledFooterTitleSec> */}
            {/* <StyledFooterTitleSec  className="text-[12px]">Sell Exhibitions Mobile Ticket App</StyledFooterTitleSec> */}
            {/* <StyledFooterTitleSec  className="text-[12px]">Content Standards</StyledFooterTitleSec> */}
            <StyledFooterTitleSec
              className="text-[12px]"
              onClick={() => {
                navigate(`/createEvents?category=Music`);
              }}
            >
              {localization.FooterTitles.FMUSIC[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec
              className="text-[12px]"
              onClick={() => {
                navigate(`/createEvents?category=Food`);
              }}
            >
              {localization.FooterTitles.FFOOD[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec
              className="text-[12px]"
              onClick={() => {
                navigate(`/createEvents?category=21Events`);
              }}
            >
              {localization.FooterTitles.F21EVENT[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec
              className="text-[12px]"
              onClick={() => {
                navigate(`/createEvents?category=Concerts`);
              }}
            >
              {localization.FooterTitles.FCONCERT[language]}
            </StyledFooterTitleSec>
          </StyledFooterCol>
          {/* <StyledFooterCol> */}
          {/* <StyledFooterTitle  className="text-[24px]">Solutions</StyledFooterTitle> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Virtual Events</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Webinars</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Conferences</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Concerts</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Hiking</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Arts and Craft</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Jazzy Events</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Sports</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Non Profits</StyledFooterTitleSec> */}
          {/* <StyledFooterTitleSec  className="text-[12px]">Higher Education</StyledFooterTitleSec> */}
          {/* </StyledFooterCol> */}
          <StyledFooterCol>
            <StyledFooterTitle className="text-[24px]">
              {localization.FooterTitles.Connect[language]}
            </StyledFooterTitle>
            <StyledFooterTitleSec
              onClick={(e) => {
                // window.location.href = emailUrl;
                // e.preventDefault();
                navigate("/Support");
              }}
              className="text-[12px]"
            >
              {localization.FooterTitles.contact[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec
              onClick={(e) => {
                window.open(twitterUrl, "_self");
              }}
              className="text-[12px]"
            >
              {" "}
              {localization.FooterTitles.twitter[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec
              onClick={(e) => {
                window.open(LinkednUrl, "_self");
              }}
              className="text-[12px]"
            >
              {" "}
              {localization.FooterTitles.linkedn[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec
              onClick={(e) => {
                window.open(instagramUrl, "_self");
              }}
              className="text-[12px]"
            >
              {" "}
              {localization.FooterTitles.instagram[language]}
            </StyledFooterTitleSec>
            <StyledFooterTitleSec
              onClick={(e) => {
                window.open(facebookUrl, "_self");
              }}
              className="text-[12px]"
            >
              {" "}
              {localization.FooterTitles.facebook[language]}
            </StyledFooterTitleSec>
          </StyledFooterCol>
          {/* <StyledFooter
            style={{
              textAlign: "center",
            }}
          >
          
            <p style={{ fontWeight: "600", color: "#333333" }}>v{`1.2`}</p>
          </StyledFooter> */}
        </StyledFooterMain>
        {/* 
        <StyledFooterMain className="mt-2 mb-5">
          <StyledFooterCol>
            <StyledFooterTitleSec
              style={{ margin: "10px" }}
              className="text-[12px]"
            >
              OwlEvents v1.2
            </StyledFooterTitleSec>
          </StyledFooterCol>
           <StyledFooterTitleSec>
          {topics.map((topic, index) => (
            <StyledFooterTitleSec
              onClick={() => {
                navigate(topic.url);
              }}
              key={index}
              style={{ margin: "10px" }}
              className="text-[12px]"
            >
              {topic.name}
            </StyledFooterTitleSec>
          ))}
          </StyledFooterCol>
        </StyledFooterMain> */}
        <Box sx={{ width: { xs: "100%", md: "68%" }, margin: "auto" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              <StyledFooterTitleSec
                style={{ margin: "10px" }}
                className="text-[12px]"
              >
                OwlEvents v1.2
              </StyledFooterTitleSec>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                mt: 2,
                mb: 5,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", md: "space-around" },
              }}
            >
              {topics.map((topic, index) => (
                <StyledFooterTitleSec
                  onClick={() => {
                    navigate(topic.url);
                  }}
                  key={index}
                  style={{ margin: "10px" }}
                  className="text-[12px]"
                >
                  {topic.name}
                </StyledFooterTitleSec>
              ))}
            </Grid>
          </Grid>
        </Box>

        {/* About     .     Blog     .     Help Careers     .     Press     .     Investors     .     Security */}
      </StyledFooter>
      {/* <Box
        sx={{
          // display: { xs: "none", md: "block" },
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 20000,
          // px: 4,
          // py: 1.2,
          // backgroundColor: "rgb(222, 164, 73)",
          borderRadius: 10,
          // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adjust the values as needed
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "400", color: "black" }}>
          OwlEvents v1.2
        </Typography>
      </Box> */}
      {/* <Box
        sx={{
          display: { xs: "block", md: "none" },
          position: "fixed",
          bottom: 70,
          right: 15,
          zIndex: 20000,
          px: 4,
          py: 1.2,
          backgroundColor: "rgb(222, 164, 73)",
          borderRadius: 10,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adjust the values as needed
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: "900", color: "whitesmoke" }}
        >
          OwlEvents v1.2
        </Typography>
      </Box> */}
    </Box>
  );
};

export default Footer;
