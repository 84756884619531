import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import * as yup from "yup";

//import { useRegisterVendorMutation } from "features/vendor/vendorApiSlice";
import Snackbar from "components/Snackbar";
import Terms from "components/Terms/Terms";
import { apiUrl, portalUrl } from "constants/api";
import FormikImagePicker from "components/formComponents/FormikImagePicker";
import DefaultImage from "assets/images/default_image.png";
import { colors } from "constants/themeConstants";
import {
  //FormikFileField,
  FormikPasswordField,
  FormikPhoneField,
  //FormikSelectField,
  FormikSelectFieldForServices,
  //FormikTextAreaField,
  FormikTextField,
} from "components/formComponents/CustomFormikFieldsRegistration";
import {
  useGetServiceTypeQuery,
  useRegisterServiceMutation,
} from "features/services/servicesApiSlice";

const initialValues = {
  email: "",
  username: "",
  first_name: "",
  last_name: "",
  password: "",
  company_name: "",
  company_tin_number: "",
  company_phone: "",
  company_email: "",
  service_type_id: "",
  logo_file: "",
};

const validationSchema = yup.object({
  email: yup.string("Enter your email").email().required("Email is required"),
  username: yup.string("Enter username").required("Username is required"),
  first_name: yup
    .string("Enter your first name")
    .required("First Name is required"),
  last_name: yup
    .string("Enter your last name")
    .required("Last Name is required"),
  password: yup
    .string("Enter your password")
    .min(6, "min length is 6")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  company_name: yup
    .string("Enter your company Name")
    .required("Company name required"),
  company_tin_number: yup
    .string("Enter your company Name")
    .matches(/^[0-9]+$/, "Must be only numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("Tin number is required"),
  company_phone: yup
    .string("Enter Company phone")
    .required("Company phone is required"),
  company_email: yup
    .string("Enter your company email")
    .email()
    .required("Company email is required"),
  service_type_id: yup
    .number("Choose a Service Type")
    .required("Service Type is required"),
  //   company_business_licence: yup
  //     .mixed(),
  //     logo_file: yup.mixed().required("Logo is required"),
  // .required("Business licence is required"),
});

const ServiceRegisterForm = (props) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };
  const { data: serviceTypes, isLoading: isServicesLoading } =
    useGetServiceTypeQuery();
  console.log("serviceTypes", serviceTypes);
  const [error, setError] = useState(null);
  const [create, { isLoading }] = useRegisterServiceMutation();
  const handleSubmit = async (values, { setSubmitting }) => {
    if (!(termsAccepted && privacyAccepted)) {
      alert("Please accept the terms and privacy policy.");
      return;
    }
    const data = { ...values };
    const formData = new FormData();
    try {
      for (let key in data) {
        formData.append(key, data[key]);
      }
      const res = await create(formData);
      setSubmitting(false);
      if (res?.error) {
        throw new Error(res.error.data.message);
      }
      setSnackOptions({
        open: true,
        text: "You have successfully registered.",
      });
      setTimeout(
        () => window.location.replace(`${portalUrl}/vendors-web/login`),
        2000
      );
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <Grid container rowSpacing={1}>
              <Grid item xs={6}>
                <FormikTextField
                  fullWidth
                  margin="dense"
                  id="first_name"
                  name="first_name"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      First Name
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  fullWidth
                  margin="dense"
                  id="last_name"
                  name="last_name"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Last Name
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  fullWidth
                  margin="dense"
                  id="username"
                  name="username"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      User Name
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField
                  fullWidth
                  margin="dense"
                  id="email"
                  name="email"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Email Address
                    </Typography>
                  }
                  required
                />
              </Grid>

              <Grid item xs={6}>
                {" "}
                <FormikTextField
                  fullWidth
                  margin="dense"
                  id="company_name"
                  name="company_name"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Company Name
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <FormikTextField
                  fullWidth
                  margin="dense"
                  id="company_email"
                  name="company_email"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Company Email
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <FormikPhoneField
                  fullWidth
                  margin="dense"
                  id="company_phone"
                  name="company_phone"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Company Phone
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <FormikTextField
                  fullWidth
                  margin="dense"
                  id="company_tin_number"
                  name="company_tin_number"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Company Tin Number
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <FormikPasswordField
                  fullWidth
                  margin="dense"
                  id="password"
                  name="password"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Password
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <FormikPasswordField
                  fullWidth
                  margin="dense"
                  id="confirmPassword"
                  name="confirmPassword"
                  label={
                    <Typography
                      fontSize={"12px"}
                      fontFamily={"Campton"}
                      color={colors.background.tertiary}
                    >
                      Confirm Password
                    </Typography>
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontFamily={"Campton"}
                  color={colors.background.tertiary}
                >
                  Company Logo (optional)
                </Typography>
                <FormikImagePicker
                  height={90}
                  width={160}
                  aspectRatio={16 / 9}
                  borderRadius="100px"
                  initialImage={
                    formik.values["logo_file"]
                      ? `${apiUrl}${formik.values["image_file_server"]}`
                      : DefaultImage
                  }
                  name="logo_file"
                  acceptType={["png", "jpg"]}
                  enableCrop={true}

                  // disabled={role !== "ADMIN"}
                />
              </Grid>
              <Grid item xs={6}>
                {!isServicesLoading && (
                  <FormikSelectFieldForServices
                    fullWidth
                    options={serviceTypes}
                    margin="dense"
                    id="service_type_id"
                    name="service_type_id"
                    label={
                      <Typography
                        fontSize={"12px"}
                        fontFamily={"Campton"}
                        color={colors.background.tertiary}
                      >
                        Service Type
                      </Typography>
                    }
                  />
                )}
              </Grid>
            </Grid>
            <Box sx={{ my: 1 }}>
              <Terms
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
                privacyAccepted={privacyAccepted}
                setPrivacyAccepted={setPrivacyAccepted}
              />
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                textTransform: "none",
                backgroundColor: colors.background.secondary,
              }}
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                <Typography fontFamily={"Campton"}>Continue</Typography>
              )}
            </Button>
            {error && (
              <Typography textAlign={"center"} varient="body2" color="error">
                {error}
              </Typography>
            )}
          </Form>
        )}
      </Formik>
      <Snackbar options={snackOptions} handleClose={handleClose} />
    </>
  );
};

export default ServiceRegisterForm;
