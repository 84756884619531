import { apiSlice } from "app/api/apiSlice";

export const adApiSplice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    
    getAds: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/in_app_ads/?dimension_type=${id}&page=0&page_size=25&order_by_clause=ASC&order_by=date_updated`,
        };
      },
      transformResponse: (response) => {
        return response.data.data;
      },
      providesTags: ["Ads"],
    }),
  }),
});
export const { useGetAdsQuery } = adApiSplice;
