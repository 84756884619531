export const apiUrl = process.env.REACT_APP_API_URL;
export const portalUrl = process.env.REACT_APP_PORTAL_URL;
export const vendorURl =
  process.env.REACT_APP_PORTAL_URL + "/vendors-web/login";
export const emailUrl = "mailto:support@awuraplc.com";
export const twitterUrl = "https://twitter.com/owlevents_et";
export const instagramUrl = "https://www.instagram.com/owlevents_ets";
export const facebookUrl = "https://facebook.com/owlevents.awura";
export const LinkednUrl = "https://linkedin.com/awura";
export const webSocketUrl = process.env.REACT_APP_WEB_SOCKET_URL;

// portal endpoints
export const vendorEventApply = `${portalUrl}/vendors-web/event`;
export const portalLoginUrl = `${portalUrl}/login`;
export const CalendlyURL = "https://calendly.com/awura_";
export const googlePlayLink =
  "https://play.google.com/store/apps/details?id=org.awuraplc.owlevents";
export const appStoreLink =
  "https://apps.apple.com/us/app/owlevents/id6469656287";
export const youtubeApiKey = "AIzaSyCac6VPJ4Tb2copi43hBPtvXIYEapdRWSM";
export const youtubePlaylistId = "PLh34W2PN8_QFO2Ppz3gT6GgRxmTgQgtAR";
