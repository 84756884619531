import { apiSlice } from "../../app/api/apiSlice";

export const ticketPromoCodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    validatePromoCode: builder.query({
      query: ({ ticket_id, promo_code }) => ({
        url: `/portal/public/ticket/promo_code/?ticket_id=${ticket_id}&promo_code=${promo_code}`,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});

export const { useValidatePromoCodeQuery } = ticketPromoCodeApiSlice;
