import { colors } from "constants/themeConstants";
import styled from "styled-components";
//import img from "../../assets/images/cardbg.png";
import { Typography } from "@mui/material";
//import theme from "assets/theme";

// const { styled } = require("@mui/system");

export const StyledFooter = styled.footer`
  background: ${colors.background.yellow};
  color: #ecf5ff;
  width: 100%;
  font-family: "Campton";
  line-height: 1.7em;
  display: flex;
  flex-direction: column;
`;

export const StyledFooterMain = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: ${colors.background.almostBlack};
  font-family: "Campton";
  width: "100%";
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin: auto;
  }
`;

export const StyledFooterCol = styled.div`
  width: 22%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 2em;
  }
`;

export const StyledFooterTitle = styled.h1`
  color: ${colors.background.tertiary};
  "&:hover": {
    color: ${colors.background.primary};
  }
  font-family: "Campton";
  fontsize: "30px";
  font-weight: 500;
  margin-top: 0.35em;
  margin-bottom: 0.4em;
  @media screen and (max-width: 768px) {
    margin: 0.35em auto 0.4em auto;
  }
`;

// export const StyledFooterTitleSec = styled.div`
//   color: ${colors.background.almostBlack};
//   "&:hover": {

//     color: ${colors.background.primary}
//   };
//   font-family: "Campton";
//   font-weight: 500;
//   line-height: 1.5em;
// `;
export const StyledFooterTitleSec = styled(Typography)`
  color: ${colors.background.tertiary};
  font-size: 12px;
  font-family: "Campton";
  cursor: pointer;
  @media screen and (max-width: 768px) {
    margin: auto;
  }
  font-weight: 500;
  transition: 0.2s linear;
  &:hover {
    // Separate object for &:hover selector
    transform: scale(1.02);
    transition: 0.2s linear;
    color: ${colors.background.primary};
  }
  line-height: 1.5em;
`;
