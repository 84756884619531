import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SquareEventCard from "components/Cards/SquareEventCard";
import SquareLoadingEventCard from "components/Cards/SquareEventCard/SquareLoadingEventCard";
import EventsCategoriesHeader from "./EventsCategoriesHeader";
// import { usePagination } from "hooks/usePagination";
import useGetData from "hooks/useGetData";
import {
  // useGetEventsQuery,
  useGetHotEventsQuery,
  useGetNewEventsQuery,
  // useGetTrendingEventsQuery,
} from "features/events/eventsApiSlice";
import { useNavigate } from "react-router-dom";
import FeaturedEvents from "../home/Home/FeaturedEvents";
import defaultAd from "../../assets/images/ad300x300.jpg";
import defaultAd2 from "../../assets/images/ad300x400.jpg";
import defaultAd3 from "../../assets/images/ad500x100.jpg";
import defaultAd4 from "../../assets/images/ad800x100.jpg";

import SnippitHeader from "pages/Homepage/Snippit";
// import { colors } from "constants/themeConstants";
// import { Ads } from "components/Ads";
import CategoryEvents from "pages/Homepage/CategoryEvents";
import { useGetAdsQuery } from "features/ads/adApiSlice";
import { apiUrl } from "constants/api";
import { StyledAdRounded, StyledAdSharp } from "pages/Homepage/Styles";
import { useGetSettingsQuery } from "features/settings";
import { LanguageContext } from "hooks/useLanguage";
import { StyledPagination } from "./styles";
import NoEvents from "pages/Error/noEvents";
import { localization } from "constants/localalization";
import { useErrorBoundary } from "react-error-boundary";
import useGetDataPagination from "hooks/useGetDataPagination";
const EventListNew = ({ query, category, filterValues, title }) => {
  const { language } = useContext(LanguageContext);
  const { showBoundary } = useErrorBoundary();

  const {
    data: hotEvents,
    /* error: errorHot,
    isError: isErrorHot,
    isLoading: ishotLoading,
    pagination: paginationHot,
    setPagination: setPaginationHot,
    rowCount: rowCountHot,
    refetch: refetchHot,*/
  } = useGetDataPagination()(useGetHotEventsQuery);
  const {
    isLoading,
    pagination,
    setPagination,
    rowCount,
    data: newEvents,
    isError,
    error,
    refetch,
  } = useGetData(true)(useGetNewEventsQuery);
  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 8 });

    if (isError) {
      showBoundary(error);
    }
  }, [isError, setPagination, showBoundary, error]);
  const data = newEvents?.data;
  const loadingCards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [currentCat, setCurrentCat] = useState([{ name: "All", id: 0 }]);
  const [catChange, setCatChange] = useState(0);

  const { data: adleftone, isLoading: isAdsLoading1 } = useGetAdsQuery({
    id: "EVENT_PAGE_LEFT_FIRST",
  });
  const { data: adlefttwo, isLoading: isAdsLoading2 } = useGetAdsQuery({
    id: "EVENT_PAGE_LEFT_SECOND",
  });

  const { data: adrightone, isLoading: isAdsLoading3 } = useGetAdsQuery({
    id: "EVENT_PAGE_RIGHT_FIRST",
  });
  const { data: adrighttwo, isLoading: isAdsLoading4 } = useGetAdsQuery({
    id: "EVENT_PAGE_RIGHT_SECOND",
  });
  const { data: logo, isLoading: isLoadingLogo } = useGetSettingsQuery();

  const navigate = useNavigate();
  return (
    <Box sx={{ pb: 4, px: { xs: 2, md: 4 }, minHeight: "80vh" }}>
      <Grid container>
        <Grid px={4} paddingLeft={1} item xs={12} md={4} xl={3}>
          <img
            alt="LogoImage"
            onClick={() => navigate("/")}
            className="mx-[auto] md:mt-5 md:mb-5"
            width={"80rem"}
            src={`${apiUrl}${logo?.logo?.image_small_path}`}
            sx={{ display: { xs: "none", md: "flex" } }}
          />
          <EventsCategoriesHeader
            currentCat={currentCat}
            catChange={catChange}
            setCatChange={setCatChange}
            setCurrentCat={setCurrentCat}
          />
          {hotEvents?.length > 0 && (
            <Grid xs={12} sm={6} md={12} marginX={"auto"} item>
              <FeaturedEvents
                // color={colors.background.golden}
                isLoading={isLoading}
                events={hotEvents}
              />{" "}
            </Grid>
          )}
          {((currentCat[0].id === 0 && newEvents?.data?.length >= 5) ||
            (currentCat[0].id !== 0 && currentCat?.length > 1)) && (
            <Box
              width={"100%"}
              px={{ xs: 0, md: 2 }}
              className="sm:max-md:flex sm:max-md:flex-row sm:max-md:gap-2"
            >
              {adleftone?.length > 0 ? (
                <StyledAdRounded
                  onClick={() => window.open(adleftone[0]?.link)}
                  src={`${apiUrl}${adleftone[0]?.image}`}
                />
              ) : (
                <StyledAdRounded
                  src={defaultAd}
                  onClick={() => window.open("https://owlevents.app/Ticketing%20App")}
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                />
              )}
              {adlefttwo?.length > 0 ? (
                <StyledAdRounded
                  onClick={() => window.open(adlefttwo[0]?.link)}
                  src={`${apiUrl}${adlefttwo[0]?.image}`}
                />
              ) : (
                <StyledAdRounded
                  src={defaultAd2}
                  onClick={() => window.open("https://owlevents.app/Ticketing%20App")}
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                />
              )}
            </Box>
          )}

          {/* {data?.length > 6 && (
            <Box
              width={"100%"}
              px={{ xs: 0, md: 2 }}
              className="sm:max-md:flex sm:max-md:flex-row sm:max-md:gap-2"
            >
              {adleftone?.length > 0 ? (
                <StyledAdRounded
                  onClick={() => window.open(adleftone[0]?.link)}
                  src={`${apiUrl}${adleftone[0]?.image}`}
                />
              ) : (
                <StyledAdRounded
                  src={defaultAd}
                  onClick={() => window.open("https://owlevents.app/Ticketing%20App")}
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                />
              )}
              {adlefttwo?.length > 0 ? (
                <StyledAdRounded
                  onClick={() => window.open(adlefttwo[0]?.link)}
                  src={`${apiUrl}${adlefttwo[0]?.image}`}
                />
              ) : (
                <StyledAdRounded
                  src={defaultAd2}
                  onClick={() => window.open("https://owlevents.app/Ticketing%20App")}
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                />
              )}
            </Box>
          )} */}
        </Grid>
        <Grid
          mt={0}
          height={"auto"}
          pl={{ xs: 2, lg: 3 }}
          pr={{ xs: 2 }}
          item
          xs={12}
          md={8}
          xl={9}
        >
          {data?.length === 0 && !isLoading && <NoEvents />}
          {currentCat[0].id === 0 && (isLoading || data?.length > 0) && (
            <>
              <SnippitHeader
                title={localization.EventsListNew["Here are"][language]}
                title2={
                  " " +
                  localization.EventsListNew["Owl the New Events"][language]
                }
              />
              <Grid
                className="h-[auto]"
                container
                spacing={2}
                sx={{ mt: 0, pr: { xs: 0, md: 4 } }}
              >
                {isLoading ? (
                  loadingCards.map((item) => (
                    <Grid key={item} item xs={12} sm={6} md={12} lg={6} xl={6}>
                      <SquareLoadingEventCard />
                    </Grid>
                  ))
                ) : (
                  <>
                    {data.map((event, index) =>
                      index === 4 ? (
                        <>
                          <Grid item xs={12} key={event.id}>
                            {adrightone?.length > 0 ? (
                              <StyledAdRounded
                                onClick={() => window.open(adrightone[0]?.link)}
                                src={`${apiUrl}${adrightone[0]?.image}`}
                                sx={{
                                  objectFit: "fill",
                                  height: "auto",
                                  width: "100%",
                                }}
                              />
                            ) : (
                              <StyledAdRounded
                                src={defaultAd3}
                                onClick={() =>
                                  window.open("https://owlevents.app/Ticketing%20App")
                                }
                                sx={{
                                  objectFit: "fill",
                                  height: "auto",
                                  width: "100%",
                                }}
                              />
                            )}
                          </Grid>
                          <Grid
                            key={event.name}
                            item
                            xs={12}
                            sm={6}
                            md={12}
                            lg={6}
                            xl={6}
                          >
                            <SquareEventCard event={event} />
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          key={event.name}
                          item
                          xs={12}
                          sm={6}
                          md={12}
                          lg={6}
                          xl={6}
                        >
                          <SquareEventCard event={event} />
                        </Grid>
                      )
                    )}
                  </>
                )}
              </Grid>
            </>
          )}

          {currentCat[0].id !== 0 && (isLoading || data?.length > 0) && (
            <Grid
              className="h-fit"
              container
              spacing={2}
              sx={{ mt: 2, pr: { xs: 0, md: 4 } }}
            >
              {currentCat?.map((item) => {
                return (
                  <CategoryEvents
                    key={item.id}
                    title={localization.EventsListNew["Zoom Through"][language]}
                    title2={
                      " " +
                      localization.EventsListNew.Categories[language] +
                      " " +
                      item.name
                    }
                    category={item.id}
                    // categories={categories}
                    // isLoading={isEventsLoading}
                    seeAll={"/events"}
                  />
                );
              })}
            </Grid>
          )}
          {newEvents?.data?.length <= 6 && (
            <Box
              width={"100%"}
              // px={{ xs: 0, md: 2 }}
              // className="sm:max-md:flex sm:max-md:flex-row sm:max-md:gap-2"
            >
              {adrighttwo?.length > 0 ? (
                <StyledAdSharp
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                  onClick={() => window.open(adrighttwo[0]?.link)}
                  src={`${apiUrl}${adrighttwo[0]?.image}`}
                />
              ) : (
                <StyledAdSharp
                  onClick={() => window.open("https://owlevents.app/Ticketing%20App")}
                  src={defaultAd4}
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      <Box
        style={{
          justifyContent: "center",
          justifyItems: "center",
          display: "flex",
        }}
      >
        <StyledPagination
          page={data?.page_metadata?.page}
          count={Math.ceil(rowCount / pagination.pageSize)}
          // count={data?.page_metadata?.length % data?.page_metadata?.size}
          onChange={(e, page) => {
            setPagination({
              pageIndex: page - 1,
              pageSize: pagination.pageSize,
            });
            refetch();
          }}
        />
      </Box>
    </Box>
  );
};

export default EventListNew;
