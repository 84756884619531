//import img from "../../../assets/images/cardbg.png";
import Icon from "@mui/material/Icon";
import { colors } from "../../../constants/themeConstants";
//import theme from "assets/theme";
const {
  Box,
  Button,
  Chip,
  Typography,
  useTheme,
  createTheme,
  LinearProgress,
  linearProgressClasses,
} = require("@mui/material");
const { styled } = require("@mui/system");

export const StyledDateTextTitle = styled("span")({
  color: colors.background.secondary,
  fontSize: "10px",
  fontFamily: "Comfortaa",
  fontWeight: 400,
  // wordBreak: "break-all",
});
export const StyledServiceType = styled("span")({
  color: colors.background.secondary,
  fontSize: "1.1rem",
  textTransform: "uppercase",
  fontFamily: "Comfortaa",
  fontWeight: 400,
  wordBreak: "break-all",
});
// export const StyledEventSquareCardWrapper = styled(Box)(({ theme }) => ({
//   //background: theme.palette.background.dark,
// //  backgroundImage: `url("https://www.freepik.com/free-photo/fading-blue-background_4100989.htm#query=blue%20smooth%20textured&position=16&from_view=search&track=ais")`,
// backgroundImage:`url(${img})`,
// borderRadius: "10px",
//   // padding: "0.8em 0.8em 0.5em 0.8em",
//   height: "170px",
//   maxWidth: "160px",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   //maxWidth: "360px",262.5/
// }));
// export const StyledEventSquareCardWrapper = styled(Box)(({ theme }) => ({
//   height: "200px",
//   width: "390px",

// }));
export const StyledEventSquareCardWrapper = styled(Box)(({ theme }) => ({
  height: "auto", // Default height for xs breakpoint
  // width: "390px",
  // [theme.breakpoints.only("md")]: {
  //   width: "80%",
  // },
  // [theme.breakpoints.only("sm")]: {
  //   width: "16rem",
  // },
  // [theme.breakpoints.only("lg")]: {
  //   width: "20rem",
  // },
  // [theme.breakpoints.only("dxl")]: {
  //   width: "25rem",
  // },
  // [theme.breakpoints.only("ddxl")]: {
  //   maxWidth: "25rem",
  // },
  // [theme.breakpoints.up('md')]: {
  //   height: "200px", // Height for md and above breakpoints
  // },
}));
//OLD ONE
// export const StyledSquareCardImg = styled("img")({
//   borderRadius: "20px",
//   objectFit: "cover",
//   cursor: "pointer",
//   width: "80px",
//   height: "80px",
//   marginLeft: "25%",
//   marginTop: "20px",
//   transition: "transform 0.25s",
//   "&:hover": {
//     transform: "scale(1.1)",
//   },
// });
export const StyledSquareCardImg = styled("img")({
  borderRadius: "10px",
  objectFit: "cover",
  cursor: "pointer",
  width: "100%",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)", 
  },
  // height: "226.7px", // Heig
  // [theme.breakpoints.up("lg")]: {
  // height: "151px",
  // },
  // [theme.breakpoints.only("lg")]: {
  //   height: "200px",
  // },
  // [theme.breakpoints.only("dxl")]: {
  //   height: "274px",
  // },
});
export const StyledSquareCardIcon = styled(Icon)(({ theme }) => ({
  // borderRadius: "20px",
  // objectFit: "cover",
  cursor: "pointer",
  // width: "fit-content",
  [theme.breakpoints.only("xs")]: {
    width: "80px",
    height: "80px",
  },
  [theme.breakpoints.only("md")]: {
    width: "80px",
    height: "80px",
  },
  width: "130px",
  height: "80px",
  marginLeft: "15%",
  marginTop: "20px",
  fontSize: "70px",
  transition: "transform 0.25s",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

export const StyledDateTextBody = styled("span")({
  color: colors.background.tertiary,
  // fontSize: "10px",
  fontFamily: "Campton",
  fontWeight: 400,
  textAlign: "start",
  overflowWrap: "break-word",
});
export const StyledInfo = styled(Typography)({
  // height: "1rem",
  color: colors.background.tertiary,
  fontSize: "10px",
  fontFamily: "Campton",
  fontWeight: 400,
  textAlign: "start",
  lineHeight: 0.5,
});
export const StyledInfoMore = styled("span")({
  color: colors.background.secondary,
  fontSize: "11px",
  fontFamily: "Campton",
  fontWeight: 400,
  textAlign: "start",
  lineHeight: 1.1,
  cursor: "pointer",
});
export const StyledTitle = styled("span")({
  color: colors.background.tertiary,
  fontSize: "1.2rem",
  fontFamily: "Campton",
  fontWeight: 500,
  textAlign: "start",
  display: "block",
});
export const StyledButton = styled(Button)({
  textTransform: "none",
  border: `0.3px ${colors.background.pureWhite} solid`,
  color: colors.background.pureWhite,
  paddingLeft: "1rem",
  borderRadius: "10px",

  "&:hover": {
    backgroundColor: colors.background.secondary,
    color: colors.background.pureWhite,
    filter: `drop-shadow(5px 5px 10px rgba(222, 164, 73, 0.5))`,
  },

  //  ml:3,
  // padding:'1px 8px 1px 8px'
});
export const StyledCategoryChip = styled(Chip)({
  // display:'inline',
  width: "fit-content",
  backgroundColor: colors.background.secondary,
  color: colors.background.tertiary,
  height: "20px",
  "&:hover": {
    backgroundColor: colors.background.outline,

    // filter: `drop-shadow(5px 5px 10px rgba(222, 164, 73, 0.5))`,
  },
});
export const StyledCategoryChipBig = styled(Chip)({
  // display:'inline',
  width: "fit-content",
  backgroundColor: colors.background.primary,
  color: colors.background.tertiary,
  height: "30px",
  // boxShadow: 10,
});
export const StyledSmallText = styled("span")({
  color: colors.background.tertiary,
  fontSize: "11px",
  fontFamily: "Campton",
});
export const StyledSmallTextBlack = styled("span")({
  color: colors.background.tertiary,
  fontSize: "9px",
  fontFamily: "Campton",
  fontWeight: "medium",
});
export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
    border: "1px solid #fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.background.pureWhite,
  },
}));

// export const StyledGrid = styled(Grid)(({theme})=>{
//   overFlow
// })
