import { apiSlice } from "app/api/apiSlice";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => {
        return {
          url: `/portal/public/event_categories/?page=0&page_size=25`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Events Catagories");
        }
        return response.data;
      },
      providesTags: ["Category"],
    }),
  }),
});
export const { useGetCategoriesQuery } = categoryApiSlice;
