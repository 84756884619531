import { Paper } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "constants/themeConstants";
import React from "react";

const StyledModalItemsContainer = styled(Paper)(({ theme }) => ({
  position: "absolute",
  padding: "3rem",
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  maxHeight: "90vh",
  maxWidth: "90vw",
  background: colors.background.primary,
}));

const ModalWrapper = React.forwardRef(({ children }, ref) => {
  return (
    <StyledModalItemsContainer ref={ref}>{children}</StyledModalItemsContainer>
  );
});

export default ModalWrapper;
