import { Link, useRouteError } from "react-router-dom";
import { colors } from "../../constants/themeConstants";
import { Box, /*Button, Icon*/ } from "@mui/material";
//import { styled } from "@mui/system";
import { ErrorButton, ErrorTextMain } from "./style";
//import owlIcon from "../../assets/images/logos/owlLogo.png";
import BodyWrapper from "components/BodyWrapper";
import ResponsiveAppBar from "pages/Homepage/appBar";
import DynamicFormIcon from "@mui/icons-material/DynamicFormRounded";

export default function LoadingError() {
  const error = useRouteError();
  return (
    <BodyWrapper>
      <ResponsiveAppBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2rem",
          height: "100%",
          py: "4.5rem",
        }}
      >
        <DynamicFormIcon
          style={{ color: colors.background.gray, scale: "5" }}
        />

        <ErrorTextMain>Page couldn't load due to</ErrorTextMain>
        <Link to="/">
          <ErrorButton>Retry</ErrorButton>
        </Link>
      </Box>
    </BodyWrapper>
  );
}
