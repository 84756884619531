import moment from "moment";

const getFieldInitValue = (field) => {
  switch (field?.type) {
  
    case "SelectMultiple":
      return [];
    case "CheckboxField":
      return {};
    case "DateField":
      return null;
    case "TimeField":
      return null;
    case "DateTimeField":
      return null;
    case "Text":
      return "";
    case "Number":
      return 0;
    case "Email":
      return "";
    default:
      return "";
  }
};

const initWithValues = (fields, values) => {
  const init = {};
  Object.keys(fields).forEach((field) => {
    const name = field?.name;
    const value = values[name];
    if (!value) {
      init[name] = getFieldInitValue(field);
      return;
    }
    init[name] = value;

    if (field?.type === "DateField") {
      init[name] = moment(value);
    } else if (field?.type === "DateTimeField") {
      init[name] = moment(value);
    } else if (field?.type === "TimeField") {
      init[name] = moment(value);
    } else if (field?.type === "CheckboxField") {
      init[name] = value.reduce((acc, current) => {
        acc[current] = true;
        return acc;
      }, {});
    }
  });

  return init;
};

const initWithDummy = (fields) => {
  const init = {};
  Object.keys(fields).forEach((field) => {
    init[field?.name] = getFieldInitValue(field);
  });
  return init;
};

export const getInitialValues = (fields, values = null) => {
  if (values) {
    return initWithValues(fields, values);
  }
  return initWithDummy(fields);
};