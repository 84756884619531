import { Box } from "@mui/material";
import img from "../../assets/images/production/registration.jpg";
import { styled } from "@mui/system";
import { colors } from "constants/themeConstants";
export const StyledAuthBackground = styled(Box)(({ theme }) => ({
  // height: "100vh",
  backgroundImage: `url(${img})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    backgroundImage: "none", // Remove the background image
    backgroundColor: `${colors.background.primary}`, // Set  background color
  },
}));
