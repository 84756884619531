import { Box, Hidden } from "@mui/material";
import { useState } from "react";
// import CategoryIcon from "@mui/icons-material/Category";

import BodyWrapper from "components/BodyWrapper";
// import SearchBar from "components/SearchBar";
// import EventsList from "./EventsList";
// import EventsCategoriesHeader from "./EventsCategoriesHeader";
// import { useSearchParams } from "react-router-dom";
import ResponsiveAppBar from "pages/Homepage/appBar";
import EventListNew from "./EventsListnew";
import BookDemo from "components/BookDemo";

const NewEvents = () => {
  const [currentCat, setCurrentCat] = useState({});
  const [query, setQuery] = useState("");
  // const [searchParams] = useSearchParams();
  const [filterValues, setfilterValues] = useState();

  return (
    <>
      <BodyWrapper>
        <Hidden mdDown>
          <BookDemo />
        </Hidden>{" "}
        <ResponsiveAppBar />
        <Box sx={{ mt: 5 }}>
          <Box
            sx={{
              mx: "auto",
              display: "flex",
              position: "relative",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            {/* <SearchBar
              label={""}
              setFilterss={setfilterValues}
              setSearchQuery={setQuery}
            /> */}
          </Box>
        </Box>
        <EventListNew
          filterValues={filterValues}
          query={query}
          title="Owl the New Events"
          category={[currentCat]}
        />
      </BodyWrapper>
    </>
  );
};

export default NewEvents;
