import React, { useState, useEffect } from "react";
import FormStep from "./FormStep";
import MuiCustomStepper from "./MuiCustomStepper";
import * as yup from "yup";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { useNavigate } from "react-router-dom";
import DefaultSnackbar from "components/Snackbar";
import {
  useGetSpecialServiceQuery,
  useTicketCheckoutMutation,
} from "features/ticket/ticketApiSlice";
import { useGetEventScheduleQuery } from "features/events/eventsApiSlice";
import useGetDataPagination from "hooks/useGetDataPagination";

import { Box } from "@mui/material";
import PriceSelect from "./priceSelect";
import moment from "moment";
//import { is } from "date-fns/locale";
import { call, alert, getStorage, setStorage } from "hylid-bridge";

const Checkout = ({
  id,
  price,
  name,
  setModalData,
  eventId,
  tickets,
  isFree,
  bundle,
  currency,
  isRecurring,
}) => {
  const [error, setError] = useState(null);
  const [useEmail, setUseEmail] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  useEffect(() => {
    if (alertOpen) {
      const timer = setTimeout(() => {
        setAlertOpen(false);
        navigate(0);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alertOpen]);
  const validationSchema = yup.object({
    price: isFree
      ? yup.number().nullable().notRequired()
      : yup.number().required("Price is required"),
  });

  const [snackOptions, setSnackOptions] = useState({ open: false, text: "" });
  const navigate = useNavigate();
  const [pricee, setPrice] = useState(0);
  const [bundlee, setBundlee] = useState(false);
  const [currencyy, setCurrency] = useState("ETB");
  const [ticket, setTicket] = useState(id);
  const [scheduleId, setScheduleId] = useState("");
  const [phoneNumber, setphoneNumber] = useState(""); // Ensure this is correctly set
  const [snapshot, setSnapshot] = useState({
    price: price,
    specialService: "",
    quantity: 1,
    pMethod: "",
    buyer_full_name: "",
    date_of_birth: "",
    buyer_email: "",
    event_schedule_id: "",
    buyer_phone: "",
    promocode: "",
  });
  const [ticketCheckout] = useTicketCheckoutMutation();
  const USER_DATA_KEY = "userData";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  const { data: specialService, isLoading } = useGetSpecialServiceQuery({
    id: eventId,
  });

  const {
    isLoading: isLoadingSchedule,
    pagination: paginationSchedule,
    setPagination: setPaginationSchedule,
    rowCount: rowCountSchedule,
    data: schedule,
    setEventId: setEventIdSchedule,
  } = useGetDataPagination()(useGetEventScheduleQuery);

  useEffect(() => {
    setEventIdSchedule(eventId);
  }, []);

  console.log(pricee);

  // Dummy function to simulate fetching user data
  const simulateFetchUserData = () => {
    const dummyResponse = {
      kycAllowed: [
        { key: "USER_NAME", value: "John" },
        { key: "USER_LNAME", value: "Doe" },
        { key: "USER_MOBILE", value: "+123456789" },
      ],
      kycRejected: [],
    };

    extractUserDetails(dummyResponse);
  };

  // Helper function to extract user details from the response
  function extractUserDetails(res) {
    let firstname = "";
    let lastname = "";
    let phonenumber = "";

    res.kycAllowed.forEach((item) => {
      if (item.key === "USER_NAME") {
        firstname = item.value;
      }
      if (item.key === "USER_LNAME") {
        lastname = item.value;
      }
      if (item.key === "USER_MOBILE") {
        phonenumber = item.value;
        setphoneNumber(phonenumber); // Set phone number here
        // alert({
        //   content: `Phone number set: ${phonenumber}`,
        // });
      }
    });

    return { firstname, lastname, phonenumber };
  }

  // Function to make the token scope call
  function makeTokenScopeCall(success, fail) {
    call("userScopes", {
      scopes: [
        "USER_API_ACCESS_TOKEN",
        "USER_NAME",
        "USER_LNAME",
        "USER_MOBILE",
      ],
      success,
      fail,
    });
  }
  const handleSubmit = async (values, setSubmitting) => {
    const ScheduleId = localStorage.getItem("selectedScheduleId");
    const formData = new FormData();

    try {
      // Fetch user data first
      const userData = await getUserData(); // Await the promise returned by getUserData
      // alert({
      //   content: `User data fetched: ${JSON.stringify(userData)}`,
      // });

      let userID, userName, userLastName, userMobile;

      if (!userData) {
        // alert({
        //   content: "No user data found, making token scope call",
        // });
        // If user data is not found in local storage, make a token scope call
        await new Promise((resolve, reject) => {
          // alert({
          //   content: `Token scope call successful:`,
          // });
          makeTokenScopeCall(
            (response) => {
              console.log("Token scope call successful:", response);
              if (response.kycRejected.length === 0) {
                const { firstname, lastname, phonenumber } =
                  extractUserDetails(response);
                userID = response.kycAllowed.find(
                  (item) => item.key === "USER_ID"
                )?.value;
                userName = firstname;
                userLastName = lastname;
                userMobile = phonenumber;

                saveData({
                  fullname: userName + " " + userLastName,
                  phonenumber: userMobile,
                });

                resolve();
              } else {
                reject("KYC data rejected");
              }
            },
            (error) => {
              // alert({
              //   content: `Token scope call failed: ${error}`,
              // });
              reject(error);
            }
          );
        });
      } else {
        // If user data is found in local storage, extract and use it
        const { fullname, phonenumber } = userData;
        [userName, userLastName] = fullname.split(" ");
        userMobile = phonenumber;
      }

      // alert({
      //   content: `Phone number before submission: ${userMobile}`,
      // });
      // Append form data
      formData.append("is_ticket_bundled", bundle || bundlee);
      formData.append(
        bundle || bundlee ? "ticket_bundle_id" : "ticket_id",
        price == -1 ? ticket : id
      );
      formData.append("payment_method_identifier", "M_PESA");
      formData.append("buyer_full_name", userName);
      formData.append("date_of_birth", "2004-01-01");
      formData.append("quantity", "1");
      formData.append("buyer_phone", userMobile); // Append phone number here
      if (specialService && specialService.length !== 0) {
        formData.append("special_service", values.specialService);
      }
      if (isRecurring) {
        formData.append("event_schedule_id", ScheduleId);
      }
      if (values.promocode) {
        formData.append("promo_code", values.promocode);
      }

      // Make the checkout API call
      const res = await ticketCheckout(formData);
      // alert({
      //   content: `Checkout API call response: ${JSON.stringify(res)}`,
      // });
      console.log(res.data);
      console.log(price !== -1);
      if (res.data.success) {
        const url = new URL(res.data.data);
        const fetchedPrice = Number(url.searchParams.get("price")); // Rename and convert to number
        const systemOrderId = url.searchParams.get("system_order_id");
        console.log("Price:", fetchedPrice);
        console.log("System Order ID:", systemOrderId);
        // navigate(0);
        console.log(fetchedPrice === 0);
        // Make payment with the fetched details
        if (fetchedPrice !== 0) {
          makePayment(
            userID,
            userName,
            userLastName,
            userMobile,
            systemOrderId,
            fetchedPrice
          );
        } else {
          alert({
            content: `Ticket Purchased Successfully`,
          });
          setSubmitting(false);

          setAlertOpen(true);
        }
      }

      // Navigate after form data preparation and successful checkout
      navigate("/");
      console.log("Navigate called");
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const makePayment = (
    userID,
    userName,
    userLastName,
    userMobile,
    systemOrderId,
    price
  ) => {
    call("payWithMpesa", {
      businessID: "2666",
      billReference: systemOrderId, // Use order ID as the bill reference
      amount: price,
      currency: "ETB",
      reason: "Ticket Purchase",
      userID: userID,
      userName: userName,
      userLastName: userLastName,
      success: function (res) {
        alert({
          content: "Payment successfully paid!",
        });
        setAlertOpen(true);
      },
      fail: function (res) {
        alert({
          content: JSON.stringify(res),
        });
      },
    });
  };

  const getUserData = () => {
    return new Promise((resolve, reject) => {
      getStorage({
        key: USER_DATA_KEY,
        success: (result) => {
          if (result.data) {
            // alert({
            //   content: `getUserData success: ${JSON.stringify(result.data)}`,
            // });
            resolve(result.data);
          } else {
            // alert({
            //   content: "getUserData: No data found",
            // });
            resolve(null);
          }
        },
        fail: () => {
          // alert({
          //   content: "getUserData failed",
          // });
          resolve(null);
        },
      });
    });
  };

  const saveData = (data) => {
    setStorage({
      key: USER_DATA_KEY,
      data: data,
      success: () => {
        // alert({
        //   content: "User data saved successfully.",
        // });
      },
      fail: (error) => {
        // alert({
        //   content: `Failed to save user data: ${error}`,
        // });
      },
    });
  };

  return (
    <Box px={{ xl: 3, lg: 3, md: 0, xs: 0 }}>
      <MuiCustomStepper
        desc={`${name} Ticket Purchase`}
        title="Checkout"
        onBack={() => setModalData((prev) => ({ ...prev, open: false }))}
        snapshot={snapshot}
        setSnapshot={setSnapshot}
        error={error}
        ticket={ticket}
      >
        <FormStep stepName="Choose Ticket" validationSchema={validationSchema}>
          <PriceSelect
            scheduleId={scheduleId}
            isRecurring={isRecurring}
            tickets={tickets}
            eventId={eventId}
            price={pricee}
            ticket={ticket}
            currencyy={currencyy}
            setPrice={setPrice}
            setBundle={setBundlee}
            setTicket={setTicket}
            setCurrency={setCurrency}
            snapshot={{
              ...snapshot,
            }}
          />
        </FormStep>

        <FormStep validationSchema={validationSchema} stepName="Payment info">
          <PaymentForm
            ticket={ticket}
            eventId={eventId}
            isRecurring={isRecurring}
            bundle={bundle || bundlee}
            specialService={specialService}
            useEmail={useEmail}
            currency={price == -1 ? currencyy : currency}
            setUseEmail={setUseEmail}
            isFree={isFree}
            price={price == -1 ? pricee : price}
            setSnapshot={setSnapshot}
          />
        </FormStep>
        <FormStep
          validationSchema={validationSchema}
          stepName="Review"
          onSubmit={handleSubmit}
        >
          <Review
            snapshot={{
              ...snapshot,
              pricee,
              currency: price == -1 ? currencyy : currency,
              setError,
              id,
              isFree,
              reason: `${name} ticket payment`,
              ticket,
            }}
          />
        </FormStep>
      </MuiCustomStepper>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </Box>
  );
};

export default Checkout;
