import {
  useTheme,
  Box,
  //Typography,
  //LinearProgress,
  Grid,
  Tooltip,
  tooltipClasses,
  //Button,
  //Rating,
} from "@mui/material";
import { apiUrl, /*portalLoginUrl,*/ vendorURl } from "constants/api";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
//import StorefrontIcon from "@mui/icons-material/Storefront";
//import img from "../../../assets/images/EventDetail.png";
import {
  StyledButton,
  //StyledCategoryChip,
  StyledDateTextBody,
  //StyledDateTextTitle,
  StyledEventSquareCardWrapper,
  StyledInfo,
  StyledInfoMore,
  StyledLinearProgress,
  StyledServiceType,
  //StyledSmallText,
  //StyledSmallTextBlack,
  StyledSquareCardImg,
  StyledTitle,
} from "./Styles";
import {
  /*FeaturedBody,
  FeaturedSubTitle,
  FeaturedTitle,*/
} from "../FeaturedEventCard/Styles";
import { colors } from "../../../constants/themeConstants";
//import moment from "moment";
//import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoCart, /*IoPricetagOutline*/ } from "react-icons/io5";
//import { IoStorefront } from "react-icons/io5";
//import { HiMusicNote } from "react-icons/hi";
//import { FaHiking } from "react-icons/fa";
//import { FaRegBuilding } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import { LanguageContext } from "hooks/useLanguage";

const { styled } = require("@mui/system");

const SquareServiceCard = ({ event }) => {
  // const truncate = (input) =>
  //  input?.length > 100 ? `${input.substring(0, 100)}...` : input;
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedValue, setTruncatedValue] = useState();
  function truncate(input) {
    // setTruncated(true);
    return input?.length > 50 ? `${input.substring(0, 50)}...` : input;
  }
  const {language}=useContext(LanguageContext)

  // const info='This event is about Sports and Ahvnhvhgvnvv hvhgvcnfrt. It it open for Children.his event is about Sports and Art. It it open for Children';
  const info = event?.company?.bio?event?.company?.bio[language] :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ';
  useEffect(() => {
    if (info?.length > 90) {
      setIsTruncated(true);
      setTruncatedValue(info.substring(0, 90));
    }
  }, []);

  const theme = useTheme();
  const navigate = useNavigate();
  const normalise = (value) => ((value - 0) * 100) / (300 - 0);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: "none",

      fontSize: theme.typography.pxToRem(12),
    },
  }));
  

  const ref = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const { clientHeight, scrollHeight } = ref.current;

      if (clientHeight < scrollHeight) {
        setHasOverflow(true);
      }
    }
  }, []);

  return (
    <StyledEventSquareCardWrapper
      my={2}
      // width={{ xs: "320px", md: "500px" }}
      // height={{ xs: "350px" }}
    >
      <Grid
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        container
      >
        <Grid xs={12} md={8} item>
          <Box
            sx={{
             
              position: "relative",
            }}
          >
            <StyledSquareCardImg
              className="h-[226px] md:h-[198px] lg:h-[193px] xl:h-[155px] 2xl:h-[155px] 3xl:h-[193px]"
              src={`${apiUrl}${event?.company?.logo?.image_medium_path}`}
              alt="Event Banner"
              onClick={() => navigate(`/services/detail?service_id=${event.id}`)}
            />
          
            <Box
              sx={{
                position: "absolute",
                bottom: 2,
                left: "10px",
                marginRight: "10px", // flexDirection: "row",
                // justifyContent: "center"
              }}
            >
              <StyledLinearProgress
                sx={{
                  borderRadius: 5,
                  boxShadow: 25,
                }}
                className="w-full"
                
                variant="determinate"
                value={
                  event?.available_ticket_rate
                    ? 100 - event?.available_ticket_rate * 100
                    : 0
                }
              />
            </Box>
          </Box>
          <Grid height={"fit-content"} container>
            <Grid
              style={{
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              xs={4}
              md={12}
              xl={4}
              alignSelf={"center"}
              item
            >
              <StyledServiceType fontSize='1.5rem'>
                {event?.service_type?.name}
              </StyledServiceType>
            </Grid>
            <Grid xs={3} md={6} xl={4} item alignSelf={"center"}>
              <Box display="flex" justifyContent="flex-end">
                <StyledButton
                  style={{ width: "97%", border: "none" }}
                  onClick={() => {
                    window.open(vendorURl, "_self");
                  }}
                  startIcon={
                    <IoIosNotifications
                      style={{
                        color: colors.background.tertiary,
                        padding: 3,
                        width: "20px",
                        height: "20px",
                        borderRadius: "50px",
                        border: `1px solid ${colors.background.outline}`,
                        background: colors.background.pureWhite,
                       
                      }}
                    />
                  }
                  variant="text"
                >
                  <StyledDateTextBody
                    style={{ fontSize: "9px", color: colors.background.white }}
                    className="text-[10px]"
                  >
                    Get <br /> Notification
                  </StyledDateTextBody>{" "}
                </StyledButton>
              </Box>
            </Grid>

            <Grid xs={3} md={6} xl={4} item alignSelf={"center"}>
              <Box display="flex" justifyContent="flex-end">
                <StyledButton
                  onClick={() =>
                    navigate(`/events/detail?event_id=${event.id}`)
                  }
                  style={{ width: "97%", border: "none" }}
                  startIcon={
                    <IoCart
                      style={{
                        color: colors.background.tertiary,
                        padding: 3,
                        width: "20px",
                        height: "20px",
                        borderRadius: "50px",
                        border: `1px solid ${colors.background.outline}`,
                        background: colors.background.pureWhite,
                      }}
                    />
                  }
                  variant="outlined"
                >
                  <StyledDateTextBody
                    style={{
                      fontSize: "9px",
                      color: colors.background.tertiary,
                    }}
                  >
                    Book <br /> {"Now   "}
                  </StyledDateTextBody>{" "}
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={4} className="md:pl-2" item>
          <StyledTitle
            ref={ref}
            style={{
              color: colors.background.tertiary,
              lineHeight: "1.1rem",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "hidden",
            }}
          >
            {event?.company?.name[language]}
          </StyledTitle>
         
          <StyledInfo
            style={{
              // display:'inline',
              lineHeight: "0.8rem",
              color: colors.background.tertiary,
            }}
          >
            {isTruncated ? truncatedValue : info}
            {isTruncated && (
              <StyledInfoMore
                style={{
                  color: colors.background.secondary,
                  display: "inline",
                }}
                onClick={() => navigate(`/events/detail?event_id=${event.id}`)}
              >
                {"..."}
              </StyledInfoMore>
            )}
          </StyledInfo>
        </Grid>
      </Grid>
    </StyledEventSquareCardWrapper>
  );
};

export default SquareServiceCard;
{/* <Rating name="read-only" value={3} readOnly />  */}
