import { apiSlice } from "../../app/api/apiSlice";
export const paymentPlanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPaymentPlan: builder.query({
      query: (query) => {
        return {
          url: `/company/payment_plans/pub/`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response;
        return {
          data,
          page_metadata,
        };
      },
    }),
    getPaymentMethods: builder.query({
      query: (query) => {
        return {
          url: `/company/payment_methods/?identifiers=${process.env.REACT_APP_PAYMENT_METHODSS}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    subscribe: builder.mutation({
      query: (body) => ({
        url: "/company/subscribe/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
    }),
    getEventPaymentMethods: builder.query({
      query: (eventId) => {
        return {
          url: `/portal/public/event/payment_methods/?event_id=${eventId}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetAllPaymentPlanQuery,
  useGetPaymentMethodsQuery,
  useSubscribeMutation,
  useGetEventPaymentMethodsQuery,
} = paymentPlanApiSlice;
