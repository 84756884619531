import { useState } from "react";

export const usePagination = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });

  return [pagination, setPagination];
};
