import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  useTheme,
  Modal,
  Typography,
} from "@mui/material";
import "draft-js/dist/Draft.css";
import RichTextViewer from "components/richText/RichTextViewer";
import { useGetPrivacyQuery, useGetTermsQuery } from "features/tos/tosApiSlice";
import ModalWrapper from "components/ModalWrapper";
import { colors } from "constants/themeConstants";

const Terms = ({
  termsAccepted,
  privacyAccepted,
  setTermsAccepted,
  setPrivacyAccepted,
}) => {
  const { data: termsText } = useGetTermsQuery();
  const { data: privacyText } = useGetPrivacyQuery();
  const theme = useTheme();
  const [modalPayload, setModalPayload] = useState({ open: false, text: "" });

  return (
    <>
      <FormGroup>
        <FormControlLabel
          sx={{}}
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              sx={{ color: colors.background.lightGray }}
            />
          }
          label={
            <Typography
              fontFamily={"Campton"}
              fontSize={"14px"}
              color={colors.background.tertiary}
            >
              I Accept{" "}
              <span
                onClick={() =>
                  setModalPayload({
                    text: termsText
                      ? termsText?.data?.terms_of_service
                      : "Loading...",
                    open: true,
                    toggleAccepted: () => setTermsAccepted((prev) => !prev),
                    accepted: termsAccepted,
                  })
                }
                style={{
                  textDecoration: "underline",
                  color: colors.background.tertiary,
                  fontFamily: "Campton",
                  fontSize: "14px",
                }}
              >
                Terms and Conditions
              </span>
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              // color="textPrimary"
              sx={{ color: colors.background.lightGray }}
              checked={privacyAccepted}
              onChange={(e) => setPrivacyAccepted(e.target.checked)}
            />
          } 
          label={
            <Typography
              fontFamily={"Campton"}
              fontSize={"14px"}
              color={colors.background.tertiary}
            >
              I Accept{" "}
              <span
                onClick={() =>
                  setModalPayload({
                    text: privacyText
                      ? privacyText?.data?.privacy_policy
                      : "Loading...",
                    open: true,
                    toggleAccepted: () => setPrivacyAccepted((prev) => !prev),
                    accepted: privacyAccepted,
                  })
                }
                style={{
                  textDecoration: "underline",
                  fontSize: "14px",
                  color: colors.background.tertiary,
                  fontFamily: "Campton",
                }}
              >
                Privacy Policy
              </span>
            </Typography>
          }
        />
      </FormGroup>
      <Modal
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={modalPayload.open}
        onClose={() =>
          setModalPayload({
            open: false,
            text: "",
          })
        }
      >
        <ModalWrapper>
          <Box>
            <RichTextViewer
              readOnly={true}
              value={modalPayload.text}
              setFieldValue={() => {}}
              placeholder=""
            />
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default Terms;
