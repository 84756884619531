import {
  Chip,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import { colors } from "constants/themeConstants";
const { styled } = require("@mui/system");

export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
    border: `1px solid ${colors.background.outline} `,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.background.secondary,
  },
}));
export const StyledCategoryChipDetail = styled(Chip)({
  // display:'inline',
  width: "auto",
  backgroundColor: colors.background.primary,

  color: colors.background.tertiary,
  height: "30px",
  "&:hover": {
    backgroundColor: colors.background.secondary,
  },
  borderRadius: 10,
  color: colors.background.tertiary,
  // boxShadow: 10,
});
export const StyledTitle = styled(Typography)({
  color: colors.background.secondary,
  fontSize: "32px",
  fontFamily: "Campton",
  fontWeight: 400,
  textAlign: "end",
});
export const StyledInfoMore = styled("span")({
  color: colors.background.secondary,
  fontSize: "18px",
  fontFamily: "Campton",
  fontWeight: 400,
  textAlign: "start",
  lineHeight: 1.1,
  cursor: "pointer",
});
