import { apiSlice } from "app/api/apiSlice";

export const settingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
  
    getSettings: builder.query({
      query: () => {
        return {
          url: `/setting/public_web/`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    //   providesTags: ["Vendors"],
    }),
    getGoogleMapsKey: builder.query({
      query: () => {
        return {
          url: `/setting/api_key`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    //   providesTags: ["Vendors"],
    }),
    sendCode: builder.mutation({
      query: (body) => ({
        url: "/users/user/sendRemoveAccountOtp/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      // invalidatesTags: ["Vendor"],
    }),
    removeData: builder.mutation({
      query: (body) => ({
        url: "/users/user/RemoveAccountWithOtp/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      // invalidatesTags: ["Vendor"],
    }),
    
   
  
  }),
});

export const {useGetGoogleMapsKeyQuery, useGetSettingsQuery,useRemoveDataMutation,useSendCodeMutation } = settingApiSlice;
