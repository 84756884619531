import { Box } from "@mui/material";
import Footer from "components/Footer";
import { colors } from "../../constants/themeConstants";
const BodyWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: colors.background.pureWhite,
        border: `3px solid ${colors.background.outline}`,
      }}
    >
      {children}
      <Footer />
    </Box>
  );
};

export default BodyWrapper;
