import { apiSlice } from "app/api/apiSlice";

export const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerService: builder.mutation({
      query: (body) => ({
        url: "/service_provider/register/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Vendor"],
    }),
    getService: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/service_provider/service/?service_id=${id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Service Details");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getServiceProvider: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/service_provider/detail/?service_provider_id=${id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Service Details");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    requestService: builder.mutation({
      query: (body) => ({
        url: `/portal/public/service_provider/service/request/`,
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: [],
    }),
    getServiceProviderRequestForm: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/service_provider/request/form/?service_provider_id=${id}`,
        };
      },
      transformResponse: (response) => {
        // if (!response.success) {
        //   throw new Error("Error Loading Form ");
        // }
        return response;
      },
      providesTags: ["Event"],
    }),
    getServiceProvidersbyCategory: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `portal/public/service_provider/?service_type_id=${id}&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Services by Category");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getSimilarServiceProviders: builder.query({
      query: (query) => {
        const { pagination, id } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `/portal/public/serivce_provider/similar/?service_provider_id=${id}&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error Loading Similar Service providers");
        }
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getServices: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/vendors/?event_id=${id}&page=0&page_size=20`,
        };
      },
      transformResponse: (response) => {
        return response.data.data;
      },
      providesTags: ["Vendors"],
    }),
    getServicesbyProvider: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/service_provider/service/?service_provider_id=${id}&page=0&page_size=20`,
        };
      },
      transformResponse: (response) => {
        return response.data.data;
      },
      providesTags: ["Vendors"],
    }),
    getAccessories: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/service_provider/accessories/?service_provider_id=${id}&page=0&page_size=20`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Vendors"],
    }),

    getServiceProviders: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters, literalFilter } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/public/service_provider/?page=${pageIndex}&page_size=${pageSize}&${literalFilter}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Vendors"],
    }),
    getAllServices: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters, literalFilter } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `portal/public/service_provider/service/?page=${pageIndex}&page_size=${pageSize}&${literalFilter}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response;
        return response.data;
      },
    }),
    getServiceType: builder.query({
      query: () => {
        return {
          url: `portal/public/service_provider/service/type/?page=0&page_size=10`,
        };
      },
      transformResponse: (response) => {
        return response.data.data;
      },
      providesTags: ["Vendors"],
    }),
  }),
});

export const {
  useGetServiceTypeQuery,
  useGetServiceProvidersbyCategoryQuery,
  useGetServicesQuery,
  useGetAllServicesQuery,
  useGetSimilarServiceProvidersQuery,
  useGetServiceProvidersQuery,
  useGetServiceProviderQuery,
  useGetAccessoriesQuery,
  useGetServiceQuery,
  useGetServicesbyProviderQuery,
  useGetServiceProviderRequestFormQuery,
  useRequestServiceMutation,
  useRegisterServiceMutation,
} = servicesApiSlice;
