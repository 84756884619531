import { CalendlyURL } from "constants/api";
import { localization } from "constants/localalization";
import { colors } from "constants/themeConstants";
import { LanguageContext } from "hooks/useLanguage";
import React, { useContext } from "react";
import { PopupWidget } from "react-calendly";

function BookDemo() {
  const { language } = useContext(LanguageContext);

  return (
    <PopupWidget
    url={CalendlyURL}      /*
       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
       */
      rootElement={document.getElementById("root")}
      text={localization.Titles.bookdemo[language]}
    
      textColor={colors.background.pureWhite}
      color={colors.background.secondary}
    />
  );
}

export default BookDemo;
