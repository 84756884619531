import { format } from "date-fns";

export const getProperDate = (date) => {
  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf());
  return format(dtDateOnly, "yyyy-MM-dd");
};

export const getProperDateTime = (date) => {
  const dt = new Date(date);
  console.log('dt',dt)
  return format(dt, "dd/MM/yy HH:MM:SS");
};

export const getProperTime = (date) => {
  return format(date, "HH:mm:ss");
};

export const getParsedDate = (date) => {
  try {
    return format(date, "PP");
  } catch (error) {
    return "error";
  }
};

export const getParsedTime = (date) => {
  try {
    return format(date, "pp");
  } catch (error) {
    return "error";
  }
};