import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

export const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("ETB");
  const [ip, setIP] = useState("");

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://ipinfo.io/${ip}?token=0d8f300b333f13`
      );
      const newCurrency = response?.data?.country === "ET" ? "ETB" : "USD";
      setCurrency(newCurrency);
    } catch (error) {
      console.error("Error fetching Geolocation:", error);
    }
  };
  useEffect(() => {
    // getData();
    // fetchData();
    if (localStorage.getItem("preferredCurrency") != null) {
      setCurrency(localStorage.getItem("preferredCurrency"));
    } else setCurrency("ETB");
  }, []);

  const changeCurrency = (newCurrency) => {
    setCurrency(newCurrency);
    localStorage.setItem("preferredCurrency", newCurrency);
  };

  return (
    <CurrencyContext.Provider value={{ currency, changeCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
