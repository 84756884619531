import React, { useContext, useEffect, useState } from "react";
//import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { colors } from "constants/themeConstants";
import { useVerifyPromoCodeMutation } from "features/ticket/ticketApiSlice";
import { useGetPaymentMethodsQuery } from "features/paymentPlan/paymentPlanApiSlice";
import { Data } from "@react-google-maps/api";
import { CurrencyContext } from "hooks/useCurrency";
function Review({ snapshot }) {
  const [promoCodeCheck] = useVerifyPromoCodeMutation();
  const {
    price,
    reason,
    currency,
    isFree,
    quantity,
    promocode,
    id,
    setError,
    pricee,
    ticket,
  } = snapshot;

  const [discount, setDiscount] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const formData = new FormData();
      try {
        setError(null);
        formData.append("ticket_id", ticket);
        promocode && formData.append("promo_code", promocode);

        const res = await promoCodeCheck(formData);
        if (res) {
          setDiscount(res?.data?.data?.promo_code_discount_rate * price);
        }

        if (res?.error) {
          if (res.error.originalStatus === 401) {
            throw new Error("Please login first to make a payment.");
          }
          throw new Error(res.error.data.message);
        }

        // Handle successful response
        // setSnackOptions({
        //   open: true,
        //   text: "Successfully placed an order. Page will redirect.",
        // });
        // setTimeout(() => window.location.replace(res.data.data), 2000);
      } catch (e) {
        console.log(e.message);
        setDiscount(e.message);
      }
    };

    fetchData();
  }, []);
  // const { currency } = useContext(CurrencyContext);

  const calculateDiscount = () => {
    if (
      typeof discount === "number" &&
      typeof snapshot?.selectedPaymentMethod?.discountRate === "number"
    ) {
      const discountRate =
        parseFloat(-snapshot?.selectedPaymentMethod?.discountRate) +
        parseFloat(discount);
      if (discountRate <= -1) {
        return -1;
      }

      return discountRate.toFixed(1);
    } else if (typeof discount === "number") {
      return discount;
    } else if (
      typeof snapshot?.selectedPaymentMethod?.discountRate === "number"
    ) {
      return -snapshot?.selectedPaymentMethod?.discountRate;
    } else {
      return 0;
    }
  };
  const priceDesc =
    promocode || snapshot?.selectedPaymentMethod?.discountRate
      ? [
          { name: "Reason", amount: reason },
          {
            name: "Price",
            amount: isFree ? "FREE" : price == -1 ? pricee : price,
          },
          { name: "Quantity", amount: quantity },
          {
            name: "Sub total",
            amount: isFree
              ? "FREE"
              : price == -1
              ? pricee * quantity
              : price * quantity,
          },
          // { name: "Promo Code Discount", amount: discount },
          {
            name: "Promo Code Discount",
            amount: calculateDiscount(),
          },
        ]
      : [
          { name: "Reason", amount: reason },
          {
            name: "Price",
            amount: isFree ? "FREE" : price == -1 ? pricee : price,
          },
          { name: "Quantity", amount: quantity },
          {
            name: "Sub total",
            amount: isFree
              ? "FREE"
              : price == -1
              ? pricee * quantity
              : price * quantity,
          },
        ];

  const getTotal = (price, tax) => {
    return price;
  };

  const { data } = useGetPaymentMethodsQuery();

  const selectedPaymentMethod = data.find(
    (item) => item.identifier === snapshot.pMethod
  );
  return (
    <React.Fragment>
      <Box sx={{ px: { xl: 3, md: 3, lg: 3, xs: 1 } }}>
        <Typography
          color={colors.background.tertiary}
          fontFamily={"Comfortaa"}
          fontSize={"22px"}
          fontWeight={"700"}
          gutterBottom
        >
          Summary
        </Typography>
        <List disablePadding sx={{ mb: 4 }}>
          {priceDesc.map((p) => (
            <ListItem sx={{ padding: "8px 0" }} key={p.name}>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontFamily: "Comfortaa",
                    fontWeight: "bold",
                    color: colors.background.tertiary,
                    paddingRight: "8rem",
                  },
                }}
                primary={p.name}
              />
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Campton"}
              >
                {p.amount != "" ? p.amount : "None"}
              </Typography>
            </ListItem>
          ))}
          <ListItem sx={{ padding: "8px 0" }}>
            <ListItemText
              primaryTypographyProps={{
                style: {
                  fontFamily: "Comfortaa",
                  fontWeight: "bold",
                  color: colors.background.tertiary,
                },
              }}
              primary="Total"
            />
            <Typography
              color={colors.background.tertiary}
              fontFamily={"Campton"}
            >
              {isFree || calculateDiscount() === -1
                ? "FREE"
                : `${currency} ${
                    price == -1
                      ? (
                          (pricee + pricee * calculateDiscount()) *
                          quantity
                        )?.toFixed(2)
                      : (
                          (price + price * calculateDiscount()) *
                          quantity
                        )?.toFixed(2)
                  }`}{" "}
            </Typography>
          </ListItem>
        </List>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Typography
              color={colors.background.tertiary}
              fontFamily={"Comfortaa"}
              fontSize={"20px"}
              fontWeight={"700"}
              gutterBottom
            >
              Item
            </Typography>
            <Typography
              paddingRight={5}
              color={colors.background.tertiary}
              fontFamily={"Campton"}
              gutterBottom
            >
              {reason}
            </Typography>
          </Grid>
          {!isFree && (
            <Grid item container direction="column" xs={12} sm={6}>
              <Typography
                color={colors.background.tertiary}
                fontFamily={"Comfortaa"}
                fontSize={"20px"}
                fontWeight={"700"}
                gutterBottom
              >
                Payment details
              </Typography>
              <Grid container>
                <React.Fragment>
                  <Grid item xs={6} lg={6}>
                    <Typography
                      color={colors.background.tertiary}
                      fontFamily={"Comfortaa"}
                      fontSize={"14px"}
                      fontWeight="bold"
                      gutterBottom
                    >
                      Payment method
                    </Typography>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={500}
                      textAlign={"end"}
                      color={colors.background.tertiary}
                      gutterBottom
                      fontFamily={"Campton"}
                    >
                      {/* {selectedPaymentMethod?.name}  */}
                      {discount === -1 ? "Free" : selectedPaymentMethod?.name}
                    </Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Review;
