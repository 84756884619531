import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  useTheme,
} from "@mui/material";
import { MobileDatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { colors } from "constants/themeConstants";
import { StyledSelect, StyledMenuItem } from "../SearchBar/Styles";
import Autocomplete from "@mui/material/Autocomplete";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
export const FormikTextField = ({ type, label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <TextField
      sx={{
        marginBottom: 2.5,
        height: "45px",
        width: "90%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.outline, // Customize the original white border color
          },
          "&:active fieldset": {
            borderColor: colors.background.outline, // Customize the hover border color
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "& input": {
            color: "black", // Set the text color to black
          },
        },
      }}
      label={label}
      type={type}
      {...props}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikPasswordField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      sx={{
        marginBottom: 2.5,
        mr: 3,
        height: "45px",
        width: "90%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.outline, // Customize the original white border color
          },
          "&:active fieldset": {
            borderColor: colors.background.outline, // Customize the hover border color
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          color: colors.background.black,
        },
      }}
      label={label}
      {...props}
      {...field}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ border: "0pc solid transparent" }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <Visibility sx={{ color: colors.background.lightGray }} />
              ) : (
                <VisibilityOff sx={{ color: colors.background.lightGray }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikSelectFieldVendorForm = ({
  label,
  options,
  required = false,
  multiple = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  console.log(props);
  const handleChange = (_, newValue) => {
    helpers.setValue(newValue);
  };
  const [limit, setLimit] = useState(3);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1920) {
        setLimit(3); // Extra large screens
      } else if (window.innerWidth >= 1280) {
        setLimit(3); // Large screens
      } else if (window.innerWidth >= 768) {
        setLimit(3); // Medium screens
      } else {
        setLimit(1); // Small screens and below
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Autocomplete
      size="large"
      sx={{
        width: "90%",
        "& .MuiAutocomplete-clearIndicator": {
          color: colors.background.error,
        },
        "& .MuiChip-deleteIcon": {
          color: colors.background.lightGray,
        },
        "&:hover .MuiChip-deleteIcon": {
          color: colors.background.lightGray,
        },
        "& .MuiInputBase-input": {
          color: "black",
        },
        "& .MuiAutocomplete-tag": {
          backgroundColor: colors.background.primary,
          fontFamily: "Campton",
          color: colors.background.tertiary,
          margin: 0,
          padding: 0,
        },
      }}
      {...field}
      {...props}
      options={options}
      limitTags={limit}
      multiple={multiple}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={handleChange}
      ListboxProps={{
        style: {
          color: "black",
        },
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            my: 1,
          }}
          {...params}
          required={required}
          label={label}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
};

export const FormikSelectField = ({
  label,
  options,
  multiple = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{
        my: 1,

        width: "90%",
        mr: 3,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.outline, // Customize the original white border color
          },
          "&:active fieldset": {
            borderColor: colors.background.outline, // Customize the hover border color
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          color: "black",
        },
      }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <StyledSelect
        multiple={multiple}
        label={label}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        MenuProps={MenuProps}
        //   inputProps={{
        //     MenuProps: {
        //         MenuListProps: {
        //             sx: {
        //                 backgroundColor: colors.background.outline,
        //                 color:"black"
        //             }
        //         }
        //     }
        // }}
      >
        {options.map((item) => (
          <StyledMenuItem
            value={item?.id || item?.value || item}
            sx={{
              fontFamily: "Campton",
              marginTop: "-0.5rem",
              marginBottom: "-0.5rem",
              "&:hover": {
                bgcolor: colors.background.secondary,
                color: "black",
              },
              "&:focus": {
                bgcolor: colors.background.outline,
                color: "black",
                fontWeight: 400,
              },
            }}
          >
            {item?.name || item?.label || item}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};
export const FormikSelectFieldForCategories = ({
  label,
  options,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1, width: "300px" }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <Select
        label={label}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options.map((item) => (
          <MenuItem value={item?.id}>{item?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FormikSelectFieldForVendors = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1, width: "300px" }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <Select
        label={label}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options?.map((item) => (
          <MenuItem value={item?.id}>{item?.company?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export const FormikSelectFieldForServices = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  console.log("options", options);
  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{
        my: 1,
        width: "90%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.outline, // Customize the original white border color
          },
          "&:active fieldset": {
            borderColor: colors.background.outline, // Customize the hover border color
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
        },
      }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <Select
        label={label}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options?.map((item) => (
          <MenuItem value={item?.id}>{item?.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FormikPhoneField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const isInternational = field.value?.startsWith("+251");
  const empty = field.value === "";

  return (
    <MuiTelInput
      sx={{
        my: 1,
        width: "90%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.outline, // Customize the original white border color
          },
          "&:active fieldset": {
            borderColor: colors.background.outline, // Customize the hover border color
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          color: colors.background.lightGray,
        },
      }}
      label={label}
      defaultCountry="ET"
      value={field.value}
      {...field}
      {...props}
      onChange={(value) => helpers.setValue(value)}
      error={meta.touched && Boolean(meta.error)}
      helperText={
        <span>
          {meta.touched && meta.error && <span>{meta.error} </span>}
          {!isInternational && !empty && (
            <span style={{ fontWeight: "bold" }}>
              <WhatsAppIcon fontSize="small" /> code will be sent via your
              WhatsApp account.
            </span>
          )}
        </span>
      }
    />
  );
};

export const FormikDateField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        sx={{ my: 1, width: "90%" }}
        label={label}
        {...props}
        {...field}
        onChange={(value) => helpers.setValue(value)}
        renderInput={(params) => (
          <TextField
            name={name}
            label={label}
            {...props}
            {...params}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const FormikDateTimeField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        sx={{ my: 1, width: "300px" }}
        label={label}
        {...props}
        {...field}
        onChange={(value) => helpers.setValue(value)}
        renderInput={(params) => (
          <TextField
            name={name}
            label={label}
            {...props}
            {...params}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const FormikFileField = ({ label, accept, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (field.value) {
      setValue(field.value.name);
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Button
          variant="outlined"
          component="label"
          {...props}
          sx={{
            my: 1,
            width: "90%",
            border: `1px solid ${colors.background.outline}`,
            color: colors.background.tertiary,
            textTransform: "none",
            fontFamily: "Campton",
          }}
        >
          <AttachFileIcon
            sx={{
              width: "18px",
              height: "18px",
              color: colors.background.tertiary,
            }}
          />{" "}
          &nbsp;&nbsp;
          <Typography variant="p" color={colors.background.tertiary}>
            {label}
          </Typography>
          <input
            accept={accept}
            type="file"
            hidden
            required={props.required}
            onChange={(e) => {
              helpers.setValue(e.target.files[0]);
              console.log("gg", e.target.files[0]);
              setValue(e.target.files[0].name);
            }}
          />
        </Button>
        {value && <Typography sx={{ ml: 2 }}>{value}</Typography>}
      </Box>
      {Boolean(meta.error) && (
        <Typography sx={{ color: "error.main" }}>{meta.error}</Typography>
      )}
    </Box>
  );
};

export const FormikSelectWithImageField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();

  return (
    <Box sx={{ my: 1, width: "300px" }}>
      <Typography variant="body" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {options.map((item, index) => (
          <Grid key={item.value} item xs={12} sm={4} md={3} lg={2} xl={2}>
            <Box
              onClick={() => {
                helpers.setValue(item.value);
              }}
              sx={{
                cursor: "pointer",
                border: `${field.value === item.value ? "2px" : "0px"} solid ${
                  theme.palette.primary.main
                }`,
                borderRadius: "3px",
                p: 0.15,
              }}
            >
              <img
                src={item.label}
                alt={item.value}
                style={{
                  objectFit: "fill",
                  width: "100%",
                  height: "50px",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      {meta.error && meta.touched ? (
        <Typography variant="p" color="error.main" sx={{ mt: 0.5 }}>
          {meta.error}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};
export const FormikTextAreaField = (props) => {
  return (
    <FormikTextField
      sx={{
        my: 1,
        mr: 3,
        width: "95%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: colors.background.outline, // Customize the original white border color
          },
          "&:active fieldset": {
            borderColor: colors.background.outline, // Customize the hover border color
          },
          "&:hover fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },
          "&:focus fieldset": {
            borderColor: colors.background.secondary, // Customize the hover border color
          },

          color: colors.background.black,
        },
      }}
      {...props}
      multiline
      rows={4}
      maxRows={4}
    />
  );
};
