import { Grid, Skeleton } from "@mui/material";

const LoadingFeaturedEventCard = () => {
  return (
    <>
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={'270px'}
        sx={{ mt: 2 }}
      />
      <Skeleton variant="text" width={100} sx={{ mt: 4 }} />
      <Skeleton variant="text" width={250} sx={{ mt: 4 }} />
      <Skeleton variant="text" width={250} sx={{ mt: 2 }}/>
      <Skeleton variant="text" width={250} sx={{ mt: 2 }} />
      <Grid spacing={1} container sx={{ mt: 4 }}>
        
          <Grid marginRight={'3px'} item >
          <Skeleton variant="rounded" width="120px" height={'50px'} />
          </Grid>
          <Grid item>
          <Skeleton variant="rounded" width="120px" height={'50px'} />
          </Grid>
        </Grid>
      {/* <Skeleton variant="text" width={100} sx={{ mt: 2 }} />
      <Skeleton variant="text" width={250} />
      <Skeleton variant="text" width={100} sx={{ mt: 2 }} />
      <Skeleton variant="text" width={250} /> */}
    </>
  );
};

export default LoadingFeaturedEventCard;
