import React, { /*useEffect,*/ useState } from "react";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Box, IconButton, useTheme } from "@mui/material";
import { /*Formik, Form */} from "formik";
import StepperNavigation from "./StepperNavigation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "constants/themeConstants";

const MuiCustomStepper = (props) => {
  const { title, desc, children, snapshot, setSnapshot, error } = props;
  const theme = useTheme();
  const steps = React.Children.toArray(children);
  const [stepNumber, setStepNumber] = useState(0);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;
console.log(totalSteps)
  const handleNext = (values) => {
    setSnapshot((s) => ({
      ...s,
      ...values,
    }));
    setStepNumber((s) => s + 1);
  };

  const handlePrev = (values) => {
    setSnapshot((s) => ({
      ...s,
      ...values,
    }));
    setStepNumber((s) => s - 1);
  };

  const handleSubmit = async (values, { setTouched, setSubmitting }) => {
    console.log('check')

    if (step.props.onSubmit) {
      console.log('check')

      step.props.onSubmit(values, setSubmitting);
      return;
    }

    if (isLastStep) {
      console.log('check')
      return;
    } else {
      console.log('right')

      setTouched({});
      handleNext(values);
    }
  };

  return (
    <React.Fragment>
      <Box
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            mt: 2,
            mb: 2,

            padding: 2,
            background: colors.background.tertiary,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {props.onBack && (
              <IconButton onClick={stepNumber > 0 ? handlePrev : props.onBack}>
                <ArrowBackIcon sx={{ color: colors.background.tertiary }} />
              </IconButton>
            )}
            <Typography
              component="h1"
              color={colors.background.tertiary}
              fontFamily={"Campton"}
              fontWeight={"500"}
              fontSize={"1.6rem"}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            textAlign={"center"}
            color={colors.background.tertiary}
            fontFamily={"Campton"}
            fontSize={"22px"}
            fontWeight={"500"}
            sx={{ mt: 1 }}
          >
            {desc}
          </Typography>

          {/* <Formik
            initialValues={snapshot}
            onSubmit={handleSubmit}
            validationSchema={step.props.validationSchema}
          >
            {(formik) => (
              <Form> */}
                <Stepper activeStep={stepNumber} sx={{ py: 5, px: 3 }}>
                  {steps.map((currenStep) => {
                    const label = currenStep.props.stepName;
                    return (
                      <Step key={label}>
                        <StepLabel>
                          <Typography
                            color={colors.background.tertiary}
                            fontFamily={"Comfortaa"}
                            fontSize={"14px"}
                            fontWeight={"500"}
                          >
                            {label}
                          </Typography>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {step}

                <StepperNavigation
                  hasPrevious={stepNumber > 0}
                  isLastStep={isLastStep}
                  handlePrev={handlePrev}
                  handleSubmit={handleSubmit}
                  // isSubmitting={formik.isSubmitting}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    align="center"
                    variant="p"
                    fontFamily={"Comfortaa"}
                    sx={{ fontSize: 20, color: colors.background.error }}
                  >
                    {error}
                  </Typography>
                </Box>
              {/* </Form>
            )}
          </Formik> */}
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default MuiCustomStepper;
