// Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BookDemo from "components/BookDemo";
import { colors } from "constants/themeConstants";
import { LanguageProvider } from "hooks/useLanguage";
import { useEffect, useMemo, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import React, { lazy, Suspense } from "react";

// import NetworkStatus from "./NetworkStatus";
import Routes from "./routes";
import { GlobalDebug } from "./utils/RemoveConsole";
import ReactGA from "react-ga4";
import { CurrencyProvider } from "hooks/useCurrency";
const NetworkStatus = lazy(() => import("./NetworkStatus"));

function App() {
  const [themeMode, setThemeMode] = useState();
  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";
  //initializing GA(google analytics)
  const GA_KEY = process.env.REACT_APP_GA_KEY;
  useEffect(() => {
    if (GA_KEY && !isLocalhost) {
      ReactGA.initialize(GA_KEY);
    }
  }, []);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home Page",
    });
  }, []);

  useEffect(() => {
    function loadGoogleFonts() {
      const link = document.createElement("link");
      link.href =
        "https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap";
      link.rel = "stylesheet";

      document.head.appendChild(link);
    }
    // Load Google Fonts after component has rendered
    loadGoogleFonts();
  }, []);
  useEffect(() => {
    const isProduction =
      process.env.REACT_APP_PRODUCTION === "production-debug-disabling-key";
    // const isStaging = process.env.REACT_APP_STAGE === "staging"
    // Conditionally enable or disable debugging based on environment
    // if (isLocalhost) {
    if (isProduction && !isLocalhost) {
      GlobalDebug(false);
    } else {
      // Debugging is enabled for other environments and localhost
      GlobalDebug(true);
    }
  }, []);
  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1150,
            xl: 1350,
            dxl: 1441,
            ddxl: 1650,
          },
        },
        palette: {
          mode: "light",
          primary: {
            main: colors.background.secondary,
          },
          danger: {
            main: "#EE5D5D",
          },
          success: {
            main: "#8EDF87",
          },
          white: {
            main: "#FFFFFF",
          },
          background: {
            primary: colors.background.main,
            secondary: colors.background.yellow,
            tertiary: colors.background.white,
            success: colors.background.green,
            info: colors.background.almostBlack,
            error: colors.background.red,
          },
          text: {
            primary: "#dbdbdb",
            white: "#fff",
            grey: "#88959D",
          },
          typography: {
            allVariants: {
              color: "#fff",
              fontFamily: "Comfortaa",
            },
          },
        },
      }),
    [themeMode]
  );

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <LanguageProvider>
          <CurrencyProvider>
            <Suspense fallback={<div></div>}>
              <NetworkStatus />
            </Suspense>
            <Routes />
          </CurrencyProvider>
        </LanguageProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
