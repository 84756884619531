import { Box, Grid, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
//import { Swiper, SwiperSlide } from "swiper/react";
//import { Navigation, Pagination } from "swiper";
//import { useGetCategoriesQuery } from "features/category/categorySlice";
import { colors } from "constants/themeConstants";
import { LanguageContext } from "hooks/useLanguage";
import { localization } from "constants/localalization";
import { useGetServiceTypeQuery } from "features/services/servicesApiSlice";

// const categories = ["All","Entertainment", "Music", "Hiking", "Night Life", "Foods"];
const LoadingCard = () => {
  return <Skeleton variant="rounded" width="60px" height={"30px"} />;
};
const ServicesHeader = ({
  setCurrentCat,
  setCatChange,
  category,
  catChange,
  currentCat,
}) => {
  const { language } = useContext(LanguageContext);

  const { data, isLoading } = useGetServiceTypeQuery();
  const categories = data;
  const loadingCards = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  function checkCurrentCategory(id) {
    if (currentCat.some((e) => e.id === id)) {
      return true;
    } else return false;
  }
  const theme = useTheme();
  useEffect(() => {
    if (currentCat.length === 0 || (category && category[0] === "none")) {
      setCurrentCat([{ name: "All", id: 0 }]);
    } else if (category && category[0] !== "none") {
      setCurrentCat(category);
    }
  }, [category, currentCat, setCurrentCat]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "end",
        justifyItems: "end",
      }}
    >
      <Grid justifyContent="space-between" justifyItems="end" container>
        {isLoading ? (
          loadingCards?.map((item) => (
            <Grid mt={1.5} item xs={"auto"} key={item}>
              <LoadingCard />
            </Grid>
          ))
        ) : (
          <>
            <Grid mt={1.5} item xs={"auto"}>
              {" "}
              <Typography
                variant="p"
                fontSize={"12px"}
                fontWeight={400}
                sx={{
                  "&:hover": {
                    backgroundColor: colors.background.secondary,
                  },
                  px: 2,
                  py: 1,
                  // mx: 2,
                  mb: 5,
                  mt: 2,
                  border: checkCurrentCategory(0)
                    ? `1px solid ${colors.background.outline}`
                    : `0.5px solid ${colors.background.outline}`,
                  color: checkCurrentCategory(0)
                    ? colors.background.secondary
                    : colors.background.tertiary,
                  // background: currentCat.id===0?theme.palette.background.dark : theme.palette.background.dark,
                  borderRadius: "16px",
                  cursor: "pointer",
                }}
                onClick={() => setCurrentCat([{ name: "All", id: 0 }])}
              >
                {localization.Labels.all[language]}
              </Typography>
            </Grid>
            {categories?.map((item) => (
              <Grid mt={1.5} item xs={"auto"} key={item.id}>
                <Typography
                  variant="p"
                  fontSize={"12px"}
                  fontWeight={400}
                  sx={{
                    "&:hover": {
                      backgroundColor: colors.background.secondary,
                      // color: colors.background.secondary,
                    },
                    px: 2,
                    py: 1,
                    //  mx: 2,
                    mb: 5,

                    mt: 2,
                    border: checkCurrentCategory(item.id)
                      ? `1px solid ${colors.background.outline}`
                      : `0.5px solid ${colors.background.outline}`,
                    color: checkCurrentCategory(item.id)
                      ? colors.background.secondary
                      : colors.background.tertiary,
                    //  background: currentCat.id===item.id?theme.palette.background.dark : theme.palette.background.dark,
                    borderRadius: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCatChange(item.id);
                    var cur = [];

                    if (currentCat[0].id !== 0) {
                      cur = currentCat;
                    }
                    // for (let i = 0; i < cur.length; i++) {
                    // if(cur[i].id==item.id){
                    const objWithIdIndex = cur.findIndex(
                      (obj) => item.id === obj.id
                    );

                    if (objWithIdIndex > -1) {
                      cur.splice(objWithIdIndex, 1);
                      setCurrentCat(cur);
                    } else {
                      cur.push({ name: item.name, id: item.id });
                      setCurrentCat(cur);
                    }
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ServicesHeader;
