import React from "react";
//import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./assets/fonts/AliandoRocky/AliandoRocky.ttf";
import "./assets/fonts/FuturaBT/Futura-Book.ttf";
import "./assets/fonts/FuturaBT/Futura-Heavy.ttf";
import "./assets/fonts/FuturaBT/Futura-Light.ttf";
import "./assets/fonts/FuturaBT/FuturaBold.ttf";
import "./assets/fonts/FuturaBT/FuturaMedium.ttf";
import "./assets/fonts/WorkSans/WorkSans-ExtraBold.ttf";
import "./assets/fonts/WorkSans/WorkSans-Medium.ttf";
import "./assets/fonts/WorkSans/WorkSans-Light.ttf";
import "./assets/fonts/Campton/CamptonBlack.otf";
import "./assets/fonts/Campton/CamptonBlackItalic.otf";
import "./assets/fonts/Campton/CamptonBold.otf";
import "./assets/fonts/Campton/CamptonBoldItalic.otf";
import "./assets/fonts/Campton/CamptonBook.otf";
import "./assets/fonts/Campton/CamptonBookItalic.otf";
import "./assets/fonts/Campton/CamptonExtraBold.otf";
import "./assets/fonts/Campton/CamptonExtraBoldItalic.otf";
import "./assets/fonts/Campton/CamptonExtraLight.otf";
import "./assets/fonts/Campton/CamptonExtraLightItalic.otf";
import "./assets/fonts/Campton/CamptonLight.otf";
import "./assets/fonts/Campton/CamptonLightItalic.otf";
import "./assets/fonts/Figerona/Figerona.ttf";
import "./assets/fonts/Campton/CamptonMedium.otf";
import "./assets/fonts/Campton/CamptonMediumItalic.otf";
import "./assets/fonts/Campton/CamptonThin.otf";
import "./assets/fonts/Campton/CamptonThinItalic.otf";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import "./styles/iconfont/material-icons.css";
import "./styles/iconfont/material-icons-outlined.css";
import "./styles/iconfont/material-icons-round.css";
import "./styles/iconfont/material-icons-sharp.css";
import "./styles/iconfont/material-icons-twotone.css";
// State
import { Provider } from "react-redux";
import { store } from "./app/store";
import { GlobalStyles } from "@mui/material";
import { colors } from "constants/themeConstants";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
//import StandardErrorBoundary from "pages/Error/boundary";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "pages/Error/errorPage";
import { hydrate, render } from "react-dom";
export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
const APP = (
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: colors.background.main },
        }}
      />
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={() => console.log("error happened")}
      >
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>
);
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

// export default withRouter(ScrollToTop);
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>

//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
