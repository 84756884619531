import { Box, Grid} from "@mui/material";
//import {Typography } from "@mui/material";
//import SquareEventCard from "components/Cards/SquareEventCard";
import SquareLoadingEventCard from "components/Cards/SquareEventCard/SquareLoadingEventCard";
import SnippitHeader from "../Snippit";
//import { Navigation } from "swiper";
//import { Swiper, SwiperSlide } from "swiper/react";
import { StyledMoreButtonText} from "../Styles";
//import {StyledTitle } from "../Styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//import { useNavigate } from "react-router-dom";
import {
  //ArrowDownward,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
//import { useGetEventbyCompanyQuery } from "features/company/companyApiSlice";
//import { useGetEventsbyCategoryQuery } from "features/events/eventsApiSlice";
import { useContext, useEffect, useState } from "react";
import { colors } from "constants/themeConstants";
import SquareServiceCard from "components/Cards/SquareEventCard/variant";
import {
  useGetServiceProvidersbyCategoryQuery
} from "features/services/servicesApiSlice";
//import {useGetServiceTypeQuery, useGetServicesbyCategoryQuery } from "features/services/servicesApiSlice";

import useGetDataPagination from "hooks/useGetDataPagination";
import { localization } from "constants/localalization";
import { LanguageContext } from "hooks/useLanguage";
import { StyledPagination } from "pages/Events/styles";


const CardsWrapper = ({ children }) => {
  return <Box sx={{ mr: 2 }}>{children}</Box>;
}; 

const Service = ({ title, title2, category, seeAll }) => {
  //const navigate = useNavigate();
  const {
    isLoading,
    pagination,
    setPagination,
    rowCount,
    data: event,
    setEventId,
    refetch,
  } = useGetDataPagination()(useGetServiceProvidersbyCategoryQuery);
  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 6 });
    setEventId(category);
  }, [category, setEventId, setPagination]);

  //const [dimensions, setDimensions] = useState(window.innerWidth);

  const [minCount, setMinCount] = useState(4);
  const [displayCount, setDisplayCount] = useState(4);

  const { language } = useContext(LanguageContext);

  const [displayMore, setDisplayMore] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  function handleShowMore(len) {
    setDisplayCount(6);
    setDisplayMore(!displayMore);
    setIsOpen(true);
  }
  function handleShowLess(len) {
    setDisplayCount(len);
    setDisplayMore(!displayMore);
    setIsOpen(false);
  }
  



  const loadingCards = [1, 2, 3, 4];
  return (
    <Box sx={{ pb: 4, mt: 0, mx: { xs: 2, md: 6, lg: 8 }, width: "auto" }}>
      <Grid container justifyContent='center' alignItems='center' spacing={0}>
        <Grid item xs={0}></Grid>
        <Grid item xs={12}>
          {" "}
          <SnippitHeader
            handleClick={() =>
              !isOpen ? handleShowMore(event?.length) : handleShowLess(minCount)
            }
            title={title}
            title2={title2}
            seeAll={seeAll}
          />{" "}
        </Grid>
        <Grid item xs={0}></Grid>
      </Grid>
        
      <Grid container justifyContent='center' alignItems='center' spacing={0}>
        <Grid item xs={0}>
          <i className='icon-arrow-right review-swiper-button-next'>
            <ArrowBackIosIcon sx={{ color: "text.white", ml: 2 }} />
          </i>
        </Grid>
        <Grid item xs={12}>
          <Grid container 
          // spacing={{ xs: 6, lg: 12, xl: 6 }}
          >
          {isLoading
              ? loadingCards?.map((item) => (
                  <Grid
                    key={item}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    dxl={6}
                    ddxl={6}
                  >
                    <SquareLoadingEventCard />
                  </Grid>
                ))
              : event?.map((item, index) => {
                  if (index < 4) {
                    return (
                      <Grid
                        key={item.id}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        dxl={6}
                        ddxl={6}
                      >
                        <SquareServiceCard event={item} />
                      </Grid>
                    );
                  }
                  return null;
                })}
         </Grid>
        </Grid>
        <Grid item xs={0}>
          <i className='icon-arrow-left review-swiper-button-prev'>
            <ArrowForwardIosIcon sx={{ color: "text.white", ml: 2 }} />
          </i>
        </Grid>
      </Grid>
    
        {event?.length > displayCount && (
        <Grid
          container
          width={{ xs: "100%", md: "40%", lg: "30%", dxl: "20%" }}
          mx='auto'
          borderRadius={"30px"}
          backgroundColor={colors.background.primary}
          mt={3}
          py='5px'
          justifyContent={"center"}
          alignContent='center'
          alignItems={"center"}
          justifyItems='center'
          onClick={() => handleShowMore(event?.length)}

        >
          <Grid item>
            <KeyboardDoubleArrowDown
              sx={{ color: colors.background.tertiary }}
            />
            <StyledMoreButtonText>
              {localization.Titles.showmore[language]}
            </StyledMoreButtonText>
          </Grid>
        </Grid>
      )}
      {displayMore && (
        <>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <StyledPagination
              style={{ color: colors.background.tertiary }}
              page={event?.page_metadata?.page}
              count={Math.ceil(rowCount / pagination.pageSize)}
              // count={data?.page_metadata?.length % data?.page_metadata?.size}
              onChange={(e, page) => {
                setPagination({
                  pageIndex: page - 1,
                  pageSize: pagination.pageSize,
                });
                refetch();
              }}
            />
          </Box>
          <Grid
            container
            // border='solid white 0.5px'
            width={{ xs: "100%", md: "40%", lg: "30%", dxl: "20%" }}
            mx='auto'
            borderRadius={"20px"}
            backgroundColor={colors.background.primary}
            mt={3}
            py='5px'
            justifyContent={"center"}
            alignContent='center'
            alignItems={"center"}
            onClick={() => handleShowLess(minCount)}

            justifyItems='center'
          >
            <Grid item>
              <KeyboardDoubleArrowUp
                sx={{ color: colors.background.tertiary }}
              />
               <StyledMoreButtonText>
                {localization.Titles.showless[language]}
              </StyledMoreButtonText>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
export default Service;
