import { Typography } from "@mui/material";
import { colors } from "constants/themeConstants";
const { styled } = require("@mui/system");

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: colors.background.tertiary,
  fontSize: "48px",
  fontWeight: 400,
  fontFamily: "Campton",
  textAlign: "start",
  lineHeight: 1.1,
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
}));

export const StyledBody = styled(Typography)({
  color: colors.background.tertiary,
  fontSize: "14px",
  fontFamily: "Campton",
  fontWeight: 400,

  textAlign: "start",
  lineHeight: 1.1,
});

export const StyledButton = styled("button")(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Campton",
  fontWeight: "400",
  // border: `0.5px ${colors.background.yellow} solid`,
  backgroundColor: colors.background.tertiary,
  color: colors.background.primary,
  transition: "background-color 0.3s ease-in-out", // add transition for smooth effect
  // Add hover effect
  "&:hover": {
    backgroundColor: colors.background.secondary,
    color: colors.background.primary,
  },
  borderRadius: "15px",
  padding: "5px 20px 5px 20px",
}));

export const StyledBlogs = styled("button")(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Campton",
  fontWeight: "400",
  minWidth: "15rem",
  maxWidth: "20rem",
  border: `dashed 1px ${colors.background.primary}`,

  // border: `0.5px ${colors.background.secondary} solid`,
  backgroundColor: colors.background.tertiary,
  color: colors.background.primary,
  borderRadius: "1rem",
  padding: "0.8rem 4rem",
  transition: "background-color 0.3s ease-in-out", // add transition for smooth effect
  // Add hover effect
  "&:hover": {
    filter: `drop-shadow(5px 5px 10px rgba(222, 164, 73, 0.5))`,

    backgroundColor: colors.background.secondary,
    color: colors.background.primary,
  },
}));
