import { colors } from "constants/themeConstants";

const { Typography } = require("@mui/material");
const { styled } = require("@mui/system");

export const FeaturedTitle = styled(Typography)(({ theme }) => ({
  color: colors.background.tertiary,
  fontSize: "12px",
  fontFamily: "Campton",
  // width: "10px" ,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

  /* Set the desired width */
  // white-space: nowrap; /* Prevent line breaks */
  // overflow: auto; /* Allow scrolling */
  // text-overflow: ellipsis; /* Add an ellipsis to indicate truncated text */
  // scroll-behavior: auto;
}));
export const FeaturedEventsTitle = styled(Typography)(({ theme }) => ({
  color: colors.background.tertiary,
  fontWeight: 500,
  fontSize: "24px",
  fontFamily: "Campton",
}));

export const FeaturedSubTitle = styled(Typography)(({ theme }) => ({
  color: colors.background.tertiary,
  fontSize: "12px",
  fontFamily: "Campton",
  fontWeight: 300,
}));
export const FeaturedSubTitle2 = styled(Typography)(({ theme }) => ({
  color: colors.background.tertiary,
  fontSize: "14px",
  fontFamily: "Campton",
  fontWeight: 600,
}));

export const FeaturedBody = styled(Typography)(({ theme }) => ({
  color: colors.background.tertiary,
  fontSize: "9px",
  fontFamily: "Campton",
}));

export const FeaturedCardImage = styled("img")(({ theme }) => ({
  borderRadius: "10px",
  objectFit: "cover",
  marginTop: "20px",
  [theme.breakpoints.only("xs")]: {
    width: "35rem",
    height: "10rem",
  },
  [theme.breakpoints.only("sm")]: {
    width: "40rem",
    height: "10rem",
  },
  [theme.breakpoints.only("md")]: {
    width: "55rem",
    height: "12rem",
  },
  [theme.breakpoints.only("lg")]: {
    width: "280px",
    height: "270px",
  },
  [theme.breakpoints.only("xl")]: {
    width: "280px",
    height: "270px",
  },
}));
