import { colors } from "constants/themeConstants";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { createGlobalStyle } from "styled-components";
import Slider from "rc-slider";
const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

export const StyledSearchRow = styled(Box)(({ theme }) => ({
  margin: "1em 0 1em 0",
}));

export const GlobalSliderStyles = createGlobalStyle`
  .rc-slider-track {
    background-color: ${colors.background.secondary};
  }

  .rc-slider-handle {
    border-color: ${colors.background.secondary};
    background-color: ${colors.background.secondary};
  }
  
  .rc-slider-handle:hover,
  .rc-slider-handle:active {
    border-color: ${colors.background.secondary};
    background-color: ${colors.background.secondary};
    box-shadow: 0 0 5px ${colors.background.secondary};
  }
`;

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.background.secondary, // Customize the original white border color
    },
    "&:active fieldset": {
      borderColor: colors.background.secondary, // Customize the hover border color
    },
    "&:hover fieldset": {
      borderColor: colors.background.secondary, // Customize the hover border color
    },
    "&:focus fieldset": {
      borderColor: colors.background.secondary, // Customize the hover border color
    },
    "& input": {
      color: "black", // Set the text color to black
    },
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.background.secondary,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.background.secondary, // Customize the hover border color
  },
  // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //   borderColor: colors.background.secondary, // Customize the focused border color
  // },
  "& .MuiSelect-select": {
    color: "black", // Set the text color of the selected item
  },
  // "&.MuiSelect-select.MuiSelect-select": {
  //   backgroundColor: "transparent", // Remove the default focused background color
  // },
  // "&:active .MuiOutlinedInput-notchedOutline": {
  //   borderColor: colors.background.secondary, // Customize the active border color
  // },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: colors.background.pureWhite,
  color: colors.background.tertiary,
  fontFamily: "Campton",
}));
