// export const colors = {

//import { apiUrl } from "./api";

//   background: {

//     main: "#11253E",
//   //  main:'#E0E0E0',
//     mainTransparent: "#11253E66",
//     dark: "#0b1c26",
//     grey: "#88959D",
//     darkBlueGreen: "#101E27",
//     white: "#FFFFFF",
//     green: "#8EDF87",
//     blue: "#0071BC",
//     golden: "#B39734",
//     lightGrey: "#E6E6E6",

//     yellow: "#F7C036",
//     // yellow: '#FF565D',
//    red:"#EE5D5D",
//     almostBlack: "#13233E",
//     blueGreen: "#15384B",
//     vendorBg: "#0D2D45",
//     darkLayerAppBar: "#0B0A0D88",
//   },
// };
export const colors = {
  background: {
    primary:  "#F2F2F2",
    secondary:  "#DEA449",
    tertiary: "#333333",
    outline: "#BBBDBF",
    gray:'#717D8A',
    lightGray:'#5D6A78',
    warning:  "#0071BC",
    error: "#EE5D5D",
    green:  "#8EDF87",
    black:'#000000',
    darkLayerAppBar: "#EAEAEA",
    pureWhite: "#ffffff",
  },
};

// fetch(apiUrl + "/setting/public_web/")
//   .then((response) => response.json())
//   .then((data) => {
//     console.log("data67", data?.data?.setting);
//     colors.background = {
//       //COLORS TO USE(UP TO DATE)
//       primary: data?.data?.setting?.primary_color_hex || "#F2F2F2",
//       secondary: data?.data?.setting?.secondary_color_hex || "#DEA449",
//       tertiary: data?.data?.setting?.tertiary_color_hex || "#333333",
//       outline: data?.data?.setting?.outline_color_hex || "#CCCCCC",
//       warning: data?.data?.setting?.warning_color_hex || "#0071BC",
//       error: data?.data?.setting?.error_color_hex || "#EE5D5D",
//       green: data?.data?.setting?.success_color_hex || "#8EDF87",
//       black: "#000000",
//       //COLORS OUTDATED
//       // mainTransparent: data?.setting + "66" || "#11253E66",
//       main: data?.data?.setting?.primary_color_hex || "#EAEAEA",
//       dark: "#0b1c26",
//       grey: "#88959D",
//       darkBlueGreen: "#101E27",
//       pureWhite: "#ffffff",
//       white: data?.data?.setting?.tertiary_color_hex || "#26282B",
//       green: data?.data?.setting?.success_color_hex || "#8EDF87",
//       blue: data?.data?.setting?.warning_color_hex || "#0071BC",
//       golden: "#B39734",
//       lightGrey: "#E6E6E6",
//       yellow: data?.data?.setting?.secondary_color_hex,
//       red: data?.data?.setting?.error_color_hex || "#EE5D5D",
//       almostBlack: data?.data?.setting?.info_color_hex || "#13233E",
//       blueGreen: "#15384B",
//       vendorBg: "#0D2D45",
//       darkLayerAppBar: "#EAEAEA",
//     };
//   })
//   .catch((error) => console.error(error));
